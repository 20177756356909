import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import TextField from 'components/CustomInputs/TextField';

import GoogleIcon from 'assets/images/GoogleIcon.svg';

import { GoogleLogin } from 'react-google-login';

import Auth from 'containers/Auth';
import { CircularProgress } from '@material-ui/core';
import Checkbox from 'components/CustomInputs/Checkbox';
import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';

const content = {
	en: {
		title: 'Save your progress',
		subTitle: 'Please sign up to save your progress.',
		googleButtonLabel: 'Continue with Google',
		orLabel: 'or',
		emailFieldLabel: 'Email',
		passwordFieldLabel: 'Password',
		passwordMessage: 'Password must be between 8 and 16 characters long and must contain at least 1 uppercase, lowercase, and numeric character.',
		showPasswordCheckboxLabel: 'Show Password',
		continueButtonLabel: 'Continue',
		alreadyHaveAccount: 'Already have an account?',
		login: 'Login',
		somethingWentWrong: 'Something went wrong. Please try again later.',
		duplicateEmail: `Your email has been registered. Please login.`
	},
	id: {
		title: 'Simpan progres Anda',
		subTitle: 'Silakan daftar untuk menyimpan progres.',
		googleButtonLabel: 'Daftar dengan Google',
		orLabel: 'atau',
		emailFieldLabel: 'Email',
		passwordFieldLabel: 'Kata Sandi',
		passwordMessage: 'Kata sandi setidaknya memiliki 8 sampai 16 karakter yang mengandung setidaknya 1 huruf kapital, huruf kecil, dan angka.',
		showPasswordCheckboxLabel: 'Tampilkan Kata Sandi',
		continueButtonLabel: 'Daftar',
		alreadyHaveAccount: 'Sudah punya akun?',
		login: 'Masuk',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.',
		duplicateEmail: `Email Anda telah terdaftar. Silakan masuk.`
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object,
	width: PropTypes.number
};

const Signup = props => {
	const { showToast, validationSchema, toNextPage } = props;

	const [isShowPassword, setIsShowPassword] = useState(false);

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state, signupUserPreapproval, signupGooglePreapproval, resetUserLogin } = Auth.useContainer();
	const { state: preapproval } = Preapproval.useContainer();

	useEffect(() => {
		if (state.auth.token) toNextPage();
	}, []);

	useEffect(() => {
		if (state.userLogin.error) {
			resetUserLogin();
			if (state.userLogin.error === 'DuplicateEmail') {
				showToast(translation.duplicateEmail);
			} else {
				showToast(translation.somethingWentWrong);
			}
		} else if (state.userLogin.success && state.auth.token) {
			resetUserLogin();
			toNextPage();
		}
	}, [state.userLogin]);

	const onSubmit = data => {
		signupUserPreapproval(data.email, data.password, preapproval);
	};

	const onSignIn = async googleUser => {
		const idToken = await googleUser.getAuthResponse().id_token;
		signupGooglePreapproval(idToken, preapproval);
	};

	return (
		<div className="signup-preapproval">
			<div className="title">{translation.title}</div>
			<div className="sub-title">{translation.subTitle}</div>
			<GoogleLogin
				clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
				cookiePolicy={'single_host_origin'}
				onSuccess={onSignIn}
				render={renderProps => (
					<Button
						className="google-button"
						disabled={renderProps.disabled}
						onClick={renderProps.onClick}
						variant="outlined"
					>
						<img className="google-icon" src={GoogleIcon} />
						{translation.googleButtonLabel}
					</Button>
				)}
			/>
			<div className="line-container">
				<div className="line"></div>
				<div>{translation.orLabel}</div>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="input-container">
					<TextField
						error={errors.email ? true : false}
						label={translation.emailFieldLabel}
						message={validationTranslation[errors.email?.message]}
						name='email'
						ref={register}
					/>
				</div>
				<div className="input-container">
					<TextField
						error={errors.password ? true : false}
						label={translation.passwordFieldLabel}
						message={translation.passwordMessage}
						name='password'
						ref={register}
						type={isShowPassword ? 'text' : 'password'}
					/>
				</div>
				<div className="checkbox-container">
					<Checkbox
						checked={isShowPassword}
						id="signup-preapproval-show-pass"
						label={translation.showPasswordCheckboxLabel}
						onChange={() => {
							setIsShowPassword(!isShowPassword);
						}} />
				</div>
				<div className="button-container buttons">
					<Button className="signup-button" disabled={state.userLogin.loading} type="submit" variant="contained">
						{state.userLogin.loading ? (
							<CircularProgress />
						) : (
							translation.continueButtonLabel
						)}
					</Button>
				</div>
				<div className="small-text" onClick={() => window.location.href=`/login?redirect=${encodeURIComponent('/preapproval')}`}>
					{translation.alreadyHaveAccount} <span>{translation.login}</span>
				</div>
			</form>
		</div>
	);
};

Signup.propTypes = propTypes;
export default StepContainer(Signup);
