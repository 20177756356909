import React, { useState, useEffect } from 'react';
import Preapproval from 'containers/Preapproval';

import PropTypes from 'prop-types';
import StepContainer from './StepContainer';
import TextField from 'components/CustomInputs/TextField';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import GrayMarker from 'assets/images/GrayMarker.svg';

import { GOOGLE_MAP_API_KEY } from 'constants/Api';
import Button from 'components/buttons/Button';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'Tell us the address of the property',
		addressFieldLabel: 'Address',
		addressNotFound: 'Address not found',
		enterAddressManually: 'Enter address manually',
		previous: 'Previous',
		next: 'Next',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Tuliskan alamat properti Anda',
		addressFieldLabel: 'Alamat',
		addressNotFound: 'Alamat tidak ditemukan',
		enterAddressManually: 'Masukkan alamat secara manual',
		previous: 'Kembali',
		next: 'Lanjut',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	showToast: PropTypes.func
};

const Search = props => {
	const { showToast, toNextPage, toPrevPage } = props;

	const { state, setAddressAutocompleteData, setAddressDetails } = Preapproval.useContainer();
	const { state: { languageCode, isGoogleMapScriptLoaded }, setIsGoogleMapScriptLoaded } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const [address, setAddress] = useState(state.addressAutocompleteData?.address || '');
	const [isNotFound, setIsNotFound] = useState(false);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

	useEffect(() => {
		if (!isGoogleMapScriptLoaded) {
			const googleMapScript = document.createElement('script');
			googleMapScript.setAttribute('id', 'GoogleMapApiLink');
			googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places&region=ID&language=id`;
			window.document.body.appendChild(googleMapScript);
			googleMapScript.addEventListener('load', () => {
				setIsGoogleMapScriptLoaded(true);
			});
		}
	}, []);

	useEffect(() => {
		if (address !== state.addressAutocompleteData?.address) {
			setAddressAutocompleteData(null);
		};
	}, [address]);

	useEffect(() => {
		if (!state.addressAutocompleteData) {
			return setIsNextButtonDisabled(true);
		}
		setIsNextButtonDisabled(false);
	}, [state.addressAutocompleteData]);

	const handlePrev = () => {
		toPrevPage();
		setAddressAutocompleteData(null);
	};

	const handleChange = address => {
		setIsNotFound(false);
		setAddress(address);
	};

	const handleSelect = async (address, placeId, suggestion) => {
		if (!placeId) {
			setAddressAutocompleteData({});
			setAddressDetails(null);
			return toNextPage();
		};

		try {
			const results = await geocodeByPlaceId(placeId);
			const latLng = await getLatLng(results[0]);
			setAddressAutocompleteData({
				address: address,
				address_components: results[0].address_components,
				description: results[0].types.includes('establishment') ? suggestion.formattedSuggestion.mainText : '',
				types: results[0].types,
				lat: latLng.lat,
				lng: latLng.lng
			});
			setAddressDetails(null);
			toNextPage();
		} catch {
			showToast(translation.somethingWentWrong);
		}
	};

	const onError = (status, clearSuggestions) => {
		setIsNotFound(true);
		clearSuggestions();
	};

	const convertText = (text, search) => {
		const textArray = text.split('');
		return textArray.map((word, index) => {
			if (search.length <= index || word.toLowerCase() !== search[index].toLowerCase()) {
				return <span className="text-gray" key={index}>{word}</span>;
			}
			return <span className="text-black" key={index}>{word}</span>;
		});
	};

	const addressFieldAutoComplete = () => {
		const searchOptions = {
			componentRestrictions: { country: 'id' }
		};

		if (!isGoogleMapScriptLoaded) return <div></div>;

		return (
			<PlacesAutocomplete
				onChange={handleChange}
				onError={onError}
				onSelect={handleSelect}
				searchOptions={searchOptions}
				value={address}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps }) => (
					<div className="move-up-container">
						<TextField
							{...getInputProps()}
							label={translation.addressFieldLabel}
						/>
						{true && (
							<div className="dropdown-container">
								{!isNotFound && suggestions.map((suggestion, index) => {
									if (index > 3) return undefined;
									const className = 'suggestion-item';
									return (
										<div
											key={index}
											{...getSuggestionItemProps(suggestion, { className })}
										>
											{convertText(suggestion.description, address)}
										</div>
									);
								})}
							</div>
						)}
						{(isNotFound && address) ? (
							<div className="dropdown-container">
								<div className="suggestion-item not-found" onClick={() => handleSelect()}>
									<img alt="" className="gray-marker" src={GrayMarker} />
									<div className="item-text-container">
										<div className="text-top">{translation.addressNotFound}</div>
										<div className="text-bottom">{translation.enterAddressManually}</div>
									</div>
								</div>
							</div>
						) : ''}
					</div>
				)}
			</PlacesAutocomplete>
		);
	};

	return (
		<div className="search-preapproval">
			<div className="title">{translation.title}</div>
			{addressFieldAutoComplete()}
			<div className="buttons">
				<Button onClick={handlePrev} type="button" variant="outlined">
					{translation.previous}
				</Button>
				<Button disabled={isNextButtonDisabled} onClick={toNextPage} type="submit" variant="contained">
					{translation.next}
				</Button>
			</div>
		</div>
	);
};

Search.propTypes = propTypes;

export default StepContainer(Search);
