import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioSwitch from 'components/CustomInputs/RadioSwitch';
import SelectField from 'components/CustomInputs/SelectField';

import range from 'lodash/range';
import { findFieldByFieldKey } from 'utils';
import Preapproval from 'containers/Preapproval';
import { CircularProgress } from '@material-ui/core';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';

const content = {
	en: {
		title: 'Have you ever applied for mortgage?',
		yesLabel: 'Yes',
		noLabel: 'No',
		dateTitle: 'When did you apply for mortgage?',
		monthLabel: 'Month',
		yearLabel: 'Year',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Apakah Anda sudah pernah mengajukan KPR?',
		yesLabel: 'Ya',
		noLabel: 'Tidak',
		dateTitle: 'Kapan KPR diajukan?',
		monthLabel: 'Bulan',
		yearLabel: 'Tahun',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const currentYear = new Date().getFullYear();

const yearOptions = range(currentYear - 30, currentYear + 1).map(year => {
	return { value: String(year), label: String(year) };
});

const monthOptions = {
	en: [
		{ value: '01', label: 'January' },
		{ value: '02', label: 'February' },
		{ value: '03', label: 'March' },
		{ value: '04', label: 'April' },
		{ value: '05', label: 'May' },
		{ value: '06', label: 'June' },
		{ value: '07', label: 'July' },
		{ value: '08', label: 'August' },
		{ value: '09', label: 'September' },
		{ value: '10', label: 'October' },
		{ value: '11', label: 'November' },
		{ value: '12', label: 'December' }
	],
	id: [
		{ value: '01', label: 'Januari' },
		{ value: '02', label: 'Februari' },
		{ value: '03', label: 'Maret' },
		{ value: '04', label: 'April' },
		{ value: '05', label: 'Mei' },
		{ value: '06', label: 'Juni' },
		{ value: '07', label: 'Juli' },
		{ value: '08', label: 'Agustus' },
		{ value: '09', label: 'September' },
		{ value: '10', label: 'Oktober' },
		{ value: '11', label: 'November' },
		{ value: '12', label: 'Desember' }
	]
};

const Mortgage = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const [isFinished, setIsFinished] = useState(false);

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;
	const monthOptionsTranslation = languageCode === 'en' ? monthOptions.en : monthOptions.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const mortgage = watch('mortgage');
	const month = watch('month');
	const year = watch('year');

	useEffect(() => {
		const mortgage = findFieldByFieldKey(fields, 'mortgage')?.fieldValue;
		const mortgageDate = findFieldByFieldKey(fields, 'mortgageDate')?.fieldValue;

		setValue('mortgage', mortgage || 'no');
		register({ name: 'year' });
		register({ name: 'month' });

		if (mortgage === 'yes') {
			const monthYear = mortgageDate.split('-');
			setValue('year', monthYear[0]);
			setValue('month', monthYear[1]);
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			setIsFinished(true);
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	useEffect(() => {
		if (isFinished) toNextPage();
	}, [isFinished]);

	const onSubmit = data => {
		const mortgageStatus = findFieldByFieldKey(fields, 'mortgageStatus')?.fieldValue;
		const mortgageReason = findFieldByFieldKey(fields, 'mortgageReason')?.fieldValue;
		upsertFields(form.id, [
			{
				fieldKey: 'mortgage',
				fieldValue: data.mortgage
			},
			{
				fieldKey: 'mortgageDate',
				fieldValue: data.mortgage === 'yes' ? `${data.year}-${data.month}` : 'N/A'
			},
			{
				fieldKey: 'mortgageStatus',
				fieldValue: data.mortgage !== 'yes' ? 'N/A' : !mortgageStatus ? '' : mortgageStatus !== 'N/A' ? mortgageStatus : ''
			},
			{
				fieldKey: 'mortgageReason',
				fieldValue: data.mortgage !== 'yes' ? 'N/A' : !mortgageReason ? '' : mortgageReason !== 'N/A' ? mortgageReason : ''
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="mortgage-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<RadioSwitch
					name="mortgage"
					options={[
						{ value: 'no', label: translation.noLabel },
						{ value: 'yes', label: translation.yesLabel }
					]}
					ref={register}
				/>
				{mortgage === 'yes' ? (
					<div className="date-container">
						<div className="title">{translation.dateTitle}</div>
						<div className="col-2">
							<SelectField
								error={errors.month ? true : false}
								label={translation.monthLabel}
								message={validationTranslation[errors.month?.message]}
								onChange={val => {
									setValue('month', val);
									if (val) clearErrors('month');
								}}
								options={monthOptionsTranslation}
								value={month}
							/>
							<SelectField
								error={errors.year ? true : false}
								label={translation.yearLabel}
								message={validationTranslation[errors.year?.message]}
								onChange={val => {
									setValue('year', val);
									if (val) clearErrors('year');
								}}
								options={yearOptions}
								value={year}
							/>
						</div>
					</div>
				) : ''}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || (mortgage === 'yes' && !month && !year)}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Mortgage.propTypes = propTypes;
export default StepContainer(Mortgage);
