import React from 'react';
import Loader from 'assets/images/Loader.svg';

const index = () => {
	return (
		<div className="house-loader">
			<object
				className="loader"
				data={Loader}
				type="image/svg+xml"
			>
			</object>
		</div>
	);
};

export default index;
