import { useReducer } from 'react';
import { createContainer } from 'unstated-next';
import taskReducers, { initialTaskState } from './reducers';
import taskActions from './actions';

const useTask = () => {
	const [state, dispatch] = useReducer(taskReducers, initialTaskState);

	const saveTasks = async tasks => {
		taskActions.saveTasks(dispatch, { tasks });
	};

	const resetTasksSave = () => {
		taskActions.resetTasksSave(dispatch);
	};

	const loadPersonalDocuments = userId => {
		taskActions.loadPersonalDocuments(dispatch, { userId });
	};

	const resetPersonalDocumentsLoad = () => {
		taskActions.resetPersonalDocumentsLoad(dispatch);
	};

	const savePropertyImages = async (propertyId, tasks) => {
		taskActions.savePropertyImages(dispatch, { propertyId, tasks });
	};

	const resetPropertyImagesSave = () => {
		taskActions.resetPropertyImagesSave(dispatch);
	};

	const loadPropertyImages = async propertyId => {
		taskActions.loadPropertyImages(dispatch, { propertyId });
	};

	const resetPropertyImagesLoad = async () => {
		taskActions.resetPropertyImagesLoad(dispatch);
	};

	const resetTask = () => {
		taskActions.resetTask(dispatch);
	};

	return {
		state,
		saveTasks,
		resetTasksSave,
		loadPersonalDocuments,
		resetPersonalDocumentsLoad,
		savePropertyImages,
		resetPropertyImagesSave,
		loadPropertyImages,
		resetPropertyImagesLoad,
		resetTask
	};
};

export default createContainer(useTask);
