import produce from 'immer';
import { combineFields, joinFullName } from 'utils';

import {
	IDENTIFIER_SET,
	SERVICE_SET,
	ADDRESS_AUTOCOMPLETE_SET,
	ADDRESS_DETAILS_SET,
	IS_PARKING_AVAILABLE_SET,
	PARKING_CAPACITY_SET,
	PROPERTY_SET,
	PROPERTY_DETAILS_SET,
	IS_OWNER_SET,
	REFERRAL_SET,
	CITY_REQUEST_START,
	CITY_REQUEST_SUCCESS,
	CITY_REQUEST_FAIL,
	CITY_REQUEST_RESET,
	LATEST_FORM_DATA_LOAD_START,
	LATEST_FORM_DATA_LOAD_SUCCESS,
	LATEST_FORM_DATA_LOAD_FAIL,
	LATEST_FORM_DATA_LOAD_RESET,
	USER_PROFILE_UPDATE_START,
	USER_PROFILE_UPDATE_SUCCESS,
	USER_PROFILE_UPDATE_FAIL,
	USER_PROFILE_UPDATE_RESET,
	FIELDS_UPSERT_START,
	FIELDS_UPSERT_SUCCESS,
	FIELDS_UPSERT_FAIL,
	FIELDS_UPSERT_RESET,
	PROPERTIES_LOAD_START,
	PROPERTIES_LOAD_SUCCESS,
	PROPERTIES_LOAD_FAIL,
	PROPERTIES_LOAD_RESET,
	FORM_SUBMIT_START,
	FORM_SUBMIT_SUCCESS,
	FORM_SUBMIT_FAIL,
	FORM_SUBMIT_RESET,
	PREAPPROVAL_RESET,
	RENT_TO_OWN_REQUEST_START,
	RENT_TO_OWN_REQUEST_SUCCESS,
	RENT_TO_OWN_REQUEST_FAIL,
	RENT_TO_OWN_REQUEST_RESET,
	FORM_STATUS_UPDATE_START,
	FORM_STATUS_UPDATE_SUCCESS,
	FORM_STATUS_UPDATE_FAIL,
	FORM_STATUS_UPDATE_RESET,
	PROCESS_SET,
	LOOKING_TIMELINE_SET,
	LOOKING_SET,
	LOOKING_LOCATION_SET,
	PROPERTY_TYPE_SET,
	IS_PROPERTY_COMPLETE_SET
} from './types';

export const initialPreapprovalState = {
	identifier: '',
	service: '',
	addressAutocompleteData: null,
	addressDetails: null,
	property: null,
	propertyDetails: null,
	isParkingAvailable: null,
	parkingCapacity: {
		garageCapacity: 0,
		carportCapacity: 0
	},
	isOwner: null,
	referral: null,
	cityRequest: {
		success: false,
		loading: false,
		error: null,
		email: ''
	},
	latestFormDataLoad: {
		success: false,
		loading: false,
		error: null
	},
	form: {
		id: null,
		type: null,
		version: null,
		status: null,
		identifier: null
	},
	user: {
		id: null,
		name: null,
		email: null,
		phone: null
	},
	fields: [],
	properties: [],
	userProfileUpdate: {
		success: false,
		loading: false,
		error: null
	},
	fieldsUpsert: {
		success: false,
		loading: false,
		error: null
	},
	propertiesLoad: {
		success: false,
		loading: false,
		error: null
	},
	formSubmit: {
		success: false,
		loading: false,
		error: null
	},
	rentToOwnRequest: {
		success: false,
		loading: false,
		error: null
	},
	formStatusUpdate: {
		success: false,
		loading: false,
		error: null
	},
	process: null,
	looking: null,
	lookingTimeline: null,
	lookingLocation: null,
	propertyType: null,
	isPropertyComplete: null
};

const preapprovalReducers = (state, action) => {
	switch (action.type) {
	case IDENTIFIER_SET:
		return produce(state, draft => {
			draft.identifier = action.payload;
		});
	case SERVICE_SET:
		return produce(state, draft => {
			draft.service = action.payload;
		});
	case ADDRESS_AUTOCOMPLETE_SET:
		return produce(state, draft => {
			draft.addressAutocompleteData = action.payload;
		});

	case ADDRESS_DETAILS_SET:
		return produce(state, draft => {
			draft.addressDetails = action.payload;
		});
	case IS_PARKING_AVAILABLE_SET:
		return produce(state, draft => {
			draft.isParkingAvailable = action.payload;
		});
	case PARKING_CAPACITY_SET:
		return produce(state, draft => {
			draft.parkingCapacity.garageCapacity = action.payload.garageCapacity;
			draft.parkingCapacity.carportCapacity = action.payload.carportCapacity;
		});
	case PROPERTY_SET:
		return produce(state, draft => {
			draft.property = action.payload;
		});
	case PROPERTY_DETAILS_SET:
		return produce(state, draft => {
			draft.propertyDetails = action.payload;
		});
	case IS_OWNER_SET:
		return produce(state, draft => {
			draft.isOwner = action.payload;
		});
	case REFERRAL_SET:
		return produce(state, draft => {
			draft.referral = action.payload;
		});
	case CITY_REQUEST_START:
		return produce(state, draft => {
			draft.cityRequest.error = null;
			draft.cityRequest.loading = true;
			draft.cityRequest.success = false;
			draft.cityRequest.email = '';
		});
	case CITY_REQUEST_SUCCESS:
		return produce(state, draft => {
			draft.cityRequest.error = null;
			draft.cityRequest.loading = false;
			draft.cityRequest.success = true;
			draft.cityRequest.email = action.payload;
		});
	case CITY_REQUEST_FAIL:
		return produce(state, draft => {
			draft.cityRequest.error = action.payload;
			draft.cityRequest.loading = false;
			draft.cityRequest.success = false;
			draft.cityRequest.email = '';
		});
	case CITY_REQUEST_RESET:
		return produce(state, draft => {
			draft.cityRequest.error = null;
			draft.cityRequest.loading = false;
			draft.cityRequest.success = false;
			draft.cityRequest.email = '';
		});
	case LATEST_FORM_DATA_LOAD_START:
		return produce(state, draft => {
			draft.latestFormDataLoad.error = null;
			draft.latestFormDataLoad.loading = true;
			draft.latestFormDataLoad.success = false;
		});
	case LATEST_FORM_DATA_LOAD_SUCCESS:
		return produce(state, draft => {
			draft.latestFormDataLoad.error = null;
			draft.latestFormDataLoad.loading = false;
			draft.latestFormDataLoad.success = true;
			draft.form.id = action.payload.form.id;
			draft.form.version = action.payload.form.version;
			draft.form.status = action.payload.form.status;
			draft.form.identifier = action.payload.form.identifier;
			draft.identifier = action.payload.form.identifier;
			draft.user.id = action.payload.form.User?.id;
			draft.user.name = joinFullName(action.payload.form.User?.firstName, action.payload.form.User?.lastName);
			draft.user.email = action.payload.form.User?.email;
			draft.user.phone = action.payload.form.User?.phone;
			draft.fields = action.payload.form.Fields;
			draft.properties = action.payload.form.Properties;
			draft.service = action.payload.form.type;
		});
	case LATEST_FORM_DATA_LOAD_FAIL:
		return produce(state, draft => {
			draft.latestFormDataLoad.error = action.payload;
			draft.latestFormDataLoad.loading = false;
			draft.latestFormDataLoad.success = false;
		});
	case LATEST_FORM_DATA_LOAD_RESET:
		return produce(state, draft => {
			draft.latestFormDataLoad.error = null;
			draft.latestFormDataLoad.loading = false;
			draft.latestFormDataLoad.success = false;
		});
	case USER_PROFILE_UPDATE_START:
		return produce(state, draft => {
			draft.userProfileUpdate.error = null;
			draft.userProfileUpdate.loading = true;
			draft.userProfileUpdate.success = false;
		});
	case USER_PROFILE_UPDATE_SUCCESS:
		return produce(state, draft => {
			draft.userProfileUpdate.error = null;
			draft.userProfileUpdate.loading = false;
			draft.userProfileUpdate.success = true;
			draft.user.name = action.payload.fullName;
			draft.user.phone = action.payload.phone;
		});
	case USER_PROFILE_UPDATE_FAIL:
		return produce(state, draft => {
			draft.userProfileUpdate.error = action.payload;
			draft.userProfileUpdate.loading = false;
			draft.userProfileUpdate.success = false;
		});
	case USER_PROFILE_UPDATE_RESET:
		return produce(state, draft => {
			draft.userProfileUpdate.error = null;
			draft.userProfileUpdate.loading = false;
			draft.userProfileUpdate.success= false;
		});
	case FIELDS_UPSERT_START:
		return produce(state, draft => {
			draft.fieldsUpsert.error = null;
			draft.fieldsUpsert.loading = true;
			draft.fieldsUpsert.success = false;
		});
	case FIELDS_UPSERT_SUCCESS:
		return produce(state, draft => {
			draft.fieldsUpsert.error = null;
			draft.fieldsUpsert.loading = false;
			draft.fieldsUpsert.success = true;
			draft.fields = combineFields(state.fields, action.payload.fields);
		});
	case FIELDS_UPSERT_FAIL:
		return produce(state, draft => {
			draft.fieldsUpsert.error = action.payload;
			draft.fieldsUpsert.loading = false;
			draft.fieldsUpsert.success = false;
		});
	case FIELDS_UPSERT_RESET:
		return produce(state, draft => {
			draft.fieldsUpsert.error = null;
			draft.fieldsUpsert.loading = false;
			draft.fieldsUpsert.success= false;
		});
	case PROPERTIES_LOAD_START:
		return produce(state, draft => {
			draft.propertiesLoad.error = null;
			draft.propertiesLoad.loading = true;
			draft.propertiesLoad.success = false;
		});
	case PROPERTIES_LOAD_SUCCESS:
		return produce(state, draft => {
			draft.propertiesLoad.error = null;
			draft.propertiesLoad.loading = false;
			draft.propertiesLoad.success = true;
			draft.properties = action.payload.form.Properties;
		});
	case PROPERTIES_LOAD_FAIL:
		return produce(state, draft => {
			draft.propertiesLoad.error = action.payload;
			draft.propertiesLoad.loading = false;
			draft.propertiesLoad.success = false;
		});
	case PROPERTIES_LOAD_RESET:
		return produce(state, draft => {
			draft.propertiesLoad.error = null;
			draft.propertiesLoad.loading = false;
			draft.propertiesLoad.success= false;
		});
	case FORM_SUBMIT_START:
		return produce(state, draft => {
			draft.formSubmit.error = null;
			draft.formSubmit.loading = true;
			draft.formSubmit.success = false;
		});
	case FORM_SUBMIT_SUCCESS:
		return produce(state, draft => {
			draft.formSubmit.error = null;
			draft.formSubmit.loading = false;
			draft.formSubmit.success = true;
		});
	case FORM_SUBMIT_FAIL:
		return produce(state, draft => {
			draft.formSubmit.error = action.payload;
			draft.formSubmit.loading = false;
			draft.formSubmit.success = false;
		});
	case FORM_SUBMIT_RESET:
		return produce(state, draft => {
			draft.formSubmit.error = null;
			draft.formSubmit.loading = false;
			draft.formSubmit.success= false;
		});
	case RENT_TO_OWN_REQUEST_START:
		return produce(state, draft => {
			draft.rentToOwnRequest.error = null;
			draft.rentToOwnRequest.loading = true;
			draft.rentToOwnRequest.success = false;
			draft.rentToOwnRequest.email = '';
		});
	case RENT_TO_OWN_REQUEST_SUCCESS:
		return produce(state, draft => {
			draft.rentToOwnRequest.error = null;
			draft.rentToOwnRequest.loading = false;
			draft.rentToOwnRequest.success = true;
			draft.rentToOwnRequest.email = action.payload;
		});
	case RENT_TO_OWN_REQUEST_FAIL:
		return produce(state, draft => {
			draft.rentToOwnRequest.error = action.payload;
			draft.rentToOwnRequest.loading = false;
			draft.rentToOwnRequest.success = false;
			draft.rentToOwnRequest.email = '';
		});
	case RENT_TO_OWN_REQUEST_RESET:
		return produce(state, draft => {
			draft.rentToOwnRequest.error = null;
			draft.rentToOwnRequest.loading = false;
			draft.rentToOwnRequest.success = false;
			draft.rentToOwnRequest.email = '';
		});

	case FORM_STATUS_UPDATE_START:
		return produce(state, draft => {
			draft.formStatusUpdate.error = null;
			draft.formStatusUpdate.loading = true;
			draft.formStatusUpdate.success = false;
		});
	case FORM_STATUS_UPDATE_SUCCESS:
		return produce(state, draft => {
			draft.formStatusUpdate.error = null;
			draft.formStatusUpdate.loading = false;
			draft.formStatusUpdate.success = true;
			draft.form.status = action.payload;
		});
	case FORM_STATUS_UPDATE_FAIL:
		return produce(state, draft => {
			draft.formStatusUpdate.error = action.payload;
			draft.formStatusUpdate.loading = false;
			draft.formStatusUpdate.success = false;
		});
	case FORM_STATUS_UPDATE_RESET:
		return produce(state, draft => {
			draft.formStatusUpdate.error = null;
			draft.formStatusUpdate.loading = false;
			draft.formStatusUpdate.success = false;
		});
	case PROCESS_SET:
		return produce(state, draft => {
			draft.process = action.payload;
		});
	case LOOKING_SET:
		return produce(state, draft => {
			draft.looking = action.payload;
		});
	case LOOKING_TIMELINE_SET:
		return produce(state, draft => {
			draft.lookingTimeline = action.payload;
		});
	case LOOKING_LOCATION_SET:
		return produce(state, draft => {
			draft.lookingLocation = action.payload;
		});
	case PROPERTY_TYPE_SET:
		return produce(state, draft => {
			draft.propertyType = action.payload;
		});
	case IS_PROPERTY_COMPLETE_SET:
		return produce(state, draft => {
			draft.isPropertyComplete = action.payload;
		});
	case PREAPPROVAL_RESET:
		return initialPreapprovalState;
	default:
		return state;
	};
};

export default preapprovalReducers;
