import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	error: PropTypes.bool,
	inputMode: PropTypes.string,
	message: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};

const TextField = forwardRef((props, ref) => {
	const [isFocused, setIsFocused] = useState(false);
	const [isFilled, setIsFilled] = useState(false);

	const {
		label,
		defaultValue,
		disabled,
		error,
		inputMode,
		message,
		name,
		onChange,
		onFocus,
		type,
		value
	} = props;

	useEffect(() => {
		if (defaultValue || defaultValue === 0) setIsFilled(true);
	}, [defaultValue]);

	useEffect(() => {
		if (onFocus) onFocus(isFocused);
	}, [isFocused]);

	useEffect(() => {
		if (value || value === 0) setIsFilled(true);
	}, [value]);

	const focusHandler = () => {
		setIsFocused(true);
	};

	const blurHandler = () => {
		setIsFocused(false);
	};

	const changeHandler = e => {
		if (onChange) onChange(e);
		if (e.target.value) setIsFilled(true);
		else setIsFilled(false);
	};

	return (
		<div className="text-field">
			<div className="text-field-container">
				<input
					className={classNames('form-input', {
						focus: isFocused && !disabled,
						disabled: disabled,
						error: error
					})
					}
					disabled={disabled}
					inputMode={inputMode}
					name={name}
					onBlur={blurHandler}
					onChange={changeHandler}
					onFocus={focusHandler}
					onWheel={e => e.target.blur()}
					type={type || 'text'}
					{...(defaultValue || defaultValue === 0 ? { defaultValue: defaultValue } : {})}
					{...(ref ? { ref: ref } : {})}
					{...(value !== undefined ? { value: value } : {})}
				/>
				{label ? (
					<label
						className={classNames('form-input-label', {
							focus: isFocused && !disabled,
							shrink: isFilled || (isFocused && !disabled),
							disabled: disabled,
							error: error
						})}
					>
						{label}
					</label>
				) : null}
			</div>
			{message ? (
				<label
					className={classNames('message-label', {
						error: error
					})}
				>
					{message}
				</label>
			) : null}
		</div>
	);
});

TextField.propTypes = propTypes;
TextField.displayName = 'TextField';

export default TextField;
