import { findFieldByFieldKey, parseCookies } from 'utils';

export const StepsOrder = [
	'begin',
	'search',
	'address',
	'property',
	'property-details',
	'parking',
	'parking-details',
	'owner',
	'referral',
	'signup',
	'contact',
	'income',
	'monthly',
	'reason',
	'slik-status',
	'cash',
	'financial',
	'timeline',
	'estimate',
	'mortgage',
	'result',
	'result-reason',
	'listing',
	'purchase',
	'price',
	'buyback'
];

export const RentbackTemplate = [
	{
		page: 'referral',
		fields: ['referral']
	},
	{
		page: 'income',
		fields: ['income']
	},
	{
		page: 'monthly',
		fields: ['monthlyPayments', 'monthlyPaymentsFee']
	},
	{
		page: 'reason',
		fields: ['refinanceReason']
	},
	{
		page: 'slik-status',
		fields: ['slikOjkStatus']
	},
	{
		page: 'cash',
		fields: ['cashTakeOutExpectation']
	},
	{
		page: 'financial',
		fields: [
			'financialCash',
			'financialPension',
			'financialInvestment',
			'financialBusiness',
			'financialOtherKey',
			'financialOther'
		]
	},
	{
		page: 'timeline',
		fields: ['saleTimelineWeeks']
	},
	{
		page: 'estimate',
		fields: ['propertyPriceExpectation']
	},
	{
		page: 'mortgage',
		fields: ['mortgage', 'mortgageDate']
	},
	{
		page: 'result',
		fields: ['mortgageStatus']
	},
	{
		page: 'result-reason',
		fields: ['mortgageReason']
	},
	{
		page: 'listing',
		fields: ['listedProperty', 'listedPropertyPrice']
	},
	{
		page: 'purchase',
		fields: ['purchasePropertyDate']
	},
	{
		page: 'price',
		fields: ['purchasePropertyPrice']
	},
	{
		page: 'buyback',
		fields: ['buyback']
	}
];

export const getRentbackTemplate = fields => {
	let steps = [...RentbackTemplate];

	if (!fields) {
		return steps;
	}

	const mortgage = findFieldByFieldKey(fields, 'mortgage')?.fieldValue;
	if (mortgage === 'no') {
		const valuesToRemove = ['result', 'result-reason'];
		steps = steps.filter(step => !valuesToRemove.includes(step.page));
	}

	const mortgageStatus = findFieldByFieldKey(fields, 'mortgageStatus')?.fieldValue;
	if (mortgageStatus === 'approved') {
		const valuesToRemove = ['result-reason'];
		steps = steps.filter(step => !valuesToRemove.includes(step.page));
	}

	return steps;
};

export const getRentbackStepsOrder = state => {
	let steps = [...StepsOrder];

	const cookies = parseCookies();
	const auth = cookies.token;

	if (state.isParkingAvailable === 'no') {
		const valuesToRemove = ['parking-details'];
		steps = steps.filter(step => !valuesToRemove.includes(step));
	}

	if (auth) {
		const valuesToRemove = ['signup'];
		steps = steps.filter(step => !valuesToRemove.includes(step));
	}

	if (!state.fields) {
		return steps;
	}

	const mortgage = findFieldByFieldKey(state.fields, 'mortgage')?.fieldValue;
	if (mortgage === 'no') {
		const valuesToRemove = ['result', 'result-reason'];
		steps = steps.filter(step => !valuesToRemove.includes(step));
	}

	const mortgageStatus = findFieldByFieldKey(state.fields, 'mortgageStatus')?.fieldValue;
	if (mortgageStatus === 'approved') {
		const valuesToRemove = ['result-reason'];
		steps = steps.filter(step => !valuesToRemove.includes(step));
	}

	return steps;
};
