import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';

import RadioButton from 'components/CustomInputs/RadioButton';
import Button from 'components/buttons/Button';
import TextField from 'components/CustomInputs/TextField';
import SelectField from 'components/CustomInputs/SelectField';

import GreenMarker from 'assets/images/GreenMarker.svg';

const content = {
	en: {
		title: 'Tell us about the property',
		fieldTitle: 'Type of property',
		houseRadioLabel: 'House',
		apartmenRadioLabel: 'Apartment',
		certificateFieldLabel: 'Certificate',
		yearBuiltLabel: 'Year Built',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Rincian properti Anda',
		fieldTitle: 'Tipe properti',
		houseRadioLabel: 'Rumah',
		apartmenRadioLabel: 'Apartemen',
		certificateFieldLabel: 'Sertifikat',
		yearBuiltLabel: 'Tahun Dibangun',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const options = [
	{ value: 'SHM', label: 'Sertifikat Hak Milik (SHM)' },
	{ value: 'SHGB', label: 'Sertifikat Hak Guna Bangunan (SHGB)' },
	{ value: 'Girik', label: 'Girik' },
	{ value: 'PPJB', label: 'Perjanjian Pengikatan Jual Beli (PPJB)' },
	{ value: 'Pra-PPJB', label: 'Pra Perjanian Pengikatan Jual Beli (Pra-PPJB)' },
	{ value: 'SHMSRS', label: 'Sertifikat Hak Milik Atas Satuan Rumah Susun (SHMSRS)' }
];

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Property = props => {
	const { toNextPage, toPrevPage, validationSchema } = props;
	const { register, handleSubmit, setValue, formState, clearErrors, watch } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const { state: { property, addressDetails }, setProperty } = Preapproval.useContainer();
	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	useEffect(() => {
		register({ name: 'certificate' });

		if (property) {
			setValue('property_type', property.property_type);
			setValue('certificate', property.certificate);
		} else {
			setValue('property_type', 'rumah');
		}
	}, []);

	const onSubmit = data => {
		setProperty(data);
		toNextPage();
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const certificate = watch('certificate');
	const { errors } = formState;

	return (
		<div className="property-preapproval">
			<div className="address-summary">
				<img alt="" className="green-marker" src={GreenMarker} />
				<div className="address-summary-text">
					{
						`${addressDetails.address}`
							+ (addressDetails?.building_name ? `, ${addressDetails.building_name}` : '')
							+ (addressDetails?.unit_number ? ` ${addressDetails.unit_number}, ` : `, `)
							+ (`${addressDetails.district}, ${addressDetails.city}, ${addressDetails.province}`)
							+ (addressDetails?.postal_code ? `, ${addressDetails.postal_code}` : '')
					}
				</div>
			</div>
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="field-title">{translation.fieldTitle}</div>
				<div className="custom-radio-container col-2">
					<RadioButton id="house" label={translation.houseRadioLabel} name="property_type" ref={register} type="radio" value="rumah" />
					<RadioButton id="apartment" label={translation.apartmenRadioLabel} name="property_type" ref={register} type="radio" value="apartemen" />
				</div>
				<div className="fields-container">
					<div className="input-container">
						<SelectField
							error={errors.certificate ? true : false}
							label={translation.certificateFieldLabel}
							message={validationTranslation[errors.certificate?.message]}
							onChange={val => {
								setValue('certificate', val);
								if (val) clearErrors('certificate');
							}}
							options={options}
							value={certificate}
						/>
					</div>
					<div className="input-container">
						<TextField
							defaultValue={property?.year_built}
							error={errors.year_built ? true : false}
							label={translation.yearBuiltLabel}
							message={validationTranslation[errors.year_built?.message]}
							name='year_built'
							ref={register}
							type="number"
						/>
					</div>
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

Property.propTypes = propTypes;

export default StepContainer(Property);

