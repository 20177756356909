import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

const propTypes = {
	error: PropTypes.bool,
	message: PropTypes.string
};

const PriceField = forwardRef((props, ref) => {
	const { error, message, ...otherProps } = props;
	return (
		<div className={classNames('price-field', { error: error })}>
			<NumberFormat
				className="calc-field"
				ref={ref}
				{...otherProps}
			/>
			<label className="adornment">Rp</label>
			{message && (
				<label className="message">{message}</label>
			)}
		</div>
	);
});

PriceField.propTypes = propTypes;
PriceField.displayName = 'PriceField';

export default PriceField;
