import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import Div100vh from 'react-div-100vh';
import { useHistory } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from 'components/buttons/Button';

const content = {
	en: {
		title: 'Your email has been verified!',
		description: 'Now you can continue to get your pre-approval',
		submit: 'Continue'
	},
	id: {
		title: 'Email Anda telah terverifikasi!',
		description: 'Now you can continue to get your pre-approval',
		submit: 'Continue'
	}
};

const propTypes = {
	isOpen: PropTypes.bool.isRequired,
	languageCode: PropTypes.string.isRequired
};

const VerificationModal = props => {
	const { isOpen, languageCode } = props;
	const { submit, title, description } = content[languageCode];
	const history = useHistory();

	return (
		<Modal
			className="verification-modal"
			contentLabel="Success Modal"
			isOpen={isOpen}
			overlayClassName="modal-overlay"
			parentSelector={() => document.querySelector('.verification')}>
			<Div100vh className="verification-modal-container">
				<CheckCircleIcon className="check-icon" />
				<div className="title">{title}</div>
				<div className="description">{description}</div>
				<Button className="continue" onClick={() => history.push('/overview')} variant="contained">
					{submit}
				</Button>
			</Div100vh>
		</Modal>
	);
};

VerificationModal.propTypes = propTypes;

export default VerificationModal;
