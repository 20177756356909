import PropTypes from 'prop-types';
import React from 'react';
import Mailbox from 'assets/images/Mailbox.svg';

const content = {
	en: { title: 'Verify your email', description: 'Please enter the 6 digit code sent to ' },
	id: { title: 'Verifikasi email Anda', description: 'Silakan masukan 6 digit kode yang dikirim ke ' }
};

const propTypes = {
	languageCode: PropTypes.string.isRequired,
	auth: PropTypes.object.isRequired
};

const VerificationHeader = props => {
	const { languageCode, auth } = props;
	const { title, description } = content[languageCode];
	const { email } = auth;

	return (
		<div className="verification-header">
			<div className="profile-pic">
				<img alt="Mailbox" className="mailbox" src={Mailbox} />
			</div>
			<div className="title">{title}</div>
			<div className="description">{description}</div>
			<div className="email">{email}</div>
		</div>
	);
};

VerificationHeader.propTypes = propTypes;

export default VerificationHeader;
