import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import SelectField from 'components/CustomInputs/SelectField';

import range from 'lodash/range';
import Preapproval from 'containers/Preapproval';
import { CircularProgress } from '@material-ui/core';
import { findFieldByFieldKey } from 'utils';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'When did you purchase this property?',
		monthLabel: 'Month',
		yearLabel: 'Year',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Kapan properti dibeli?',
		monthLabel: 'Bulan',
		yearLabel: 'Tahun',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silahkan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const currentYear = new Date().getFullYear();

const yearOptions = range(1900, currentYear + 1).reverse().map(year => {
	return { value: String(year), label: String(year) };
});

const monthOptions = {
	en: [
		{ value: '01', label: 'January' },
		{ value: '02', label: 'February' },
		{ value: '03', label: 'March' },
		{ value: '04', label: 'April' },
		{ value: '05', label: 'May' },
		{ value: '06', label: 'June' },
		{ value: '07', label: 'July' },
		{ value: '08', label: 'August' },
		{ value: '09', label: 'September' },
		{ value: '10', label: 'October' },
		{ value: '11', label: 'November' },
		{ value: '12', label: 'December' }
	],
	id: [
		{ value: '01', label: 'Januari' },
		{ value: '02', label: 'Februari' },
		{ value: '03', label: 'Maret' },
		{ value: '04', label: 'April' },
		{ value: '05', label: 'Mei' },
		{ value: '06', label: 'Juni' },
		{ value: '07', label: 'Juli' },
		{ value: '08', label: 'Agustus' },
		{ value: '09', label: 'September' },
		{ value: '10', label: 'Oktober' },
		{ value: '11', label: 'November' },
		{ value: '12', label: 'Desember' }
	]
};

const Purchase = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;
	const monthOptionsTranslation = languageCode === 'en' ? monthOptions.en : monthOptions.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const month = watch('month');
	const year = watch('year');

	useEffect(() => {
		const purchasePropertyDate = findFieldByFieldKey(fields, 'purchasePropertyDate')?.fieldValue;

		register({ name: 'year' });
		register({ name: 'month' });

		if (purchasePropertyDate) {
			const monthYear = purchasePropertyDate.split('-');
			setValue('year', monthYear[0]);
			setValue('month', monthYear[1]);
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'purchasePropertyDate',
				fieldValue: `${data.year}-${data.month}`
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="purchase-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="date-container">
					<SelectField
						error={errors.month ? true : false}
						label={translation.monthLabel}
						message={validationTranslation[errors.month?.message]}
						onChange={val => {
							setValue('month', val);
							if (val) clearErrors('month');
						}}
						options={monthOptionsTranslation}
						value={month}
					/>
					<SelectField
						error={errors.year ? true : false}
						label={translation.yearLabel}
						message={validationTranslation[errors.year?.message]}
						onChange={val => {
							setValue('year', val);
							if (val) clearErrors('year');
						}}
						options={yearOptions}
						value={year}
					/>
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || (!month && !year)}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Purchase.propTypes = propTypes;
export default StepContainer(Purchase);
