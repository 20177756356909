import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import { CircularProgress } from '@material-ui/core';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'Status for application',
		approvedLabel: 'Approved',
		deniedLabel: 'Denied',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Status pengajuan KPR',
		approvedLabel: 'Diterima',
		deniedLabel: 'Ditolak',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Result = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const [isFinished, setIsFinished] = useState(false);

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue } = useForm({
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		const mortgageStatus = findFieldByFieldKey(fields, 'mortgageStatus')?.fieldValue;
		setValue('result', mortgageStatus || 'approved');
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			setIsFinished(true);
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast('Something went wrong. Please try again later');
		}
	}, [fieldsUpsert]);

	useEffect(() => {
		if (isFinished) toNextPage();
	}, [isFinished]);

	const onSubmit = data => {
		const mortgageReason = findFieldByFieldKey(fields, 'mortgageReason')?.fieldValue;

		upsertFields(form.id, [
			{
				fieldKey: 'mortgageStatus',
				fieldValue: data.result
			},
			{
				fieldKey: 'mortgageReason',
				fieldValue: data.result !== 'denied' ? 'N/A' : !mortgageReason ? '' : mortgageReason !== 'N/A' ? mortgageReason : ''
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="result-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton id="one" label={translation.approvedLabel} name="result" ref={register} type="radio" value="approved" />
					<RadioButton id="two" label={translation.deniedLabel} name="result" ref={register} type="radio" value="denied" />
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Result.propTypes = propTypes;
export default StepContainer(Result);
