import produce from 'immer';
import uniqBy from 'lodash/uniqBy';

import {
	TASKS_SAVE_START,
	TASKS_SAVE_SUCCESS,
	TASKS_SAVE_FAIL,
	TASKS_SAVE_RESET,
	PERSONAL_DOCUMENTS_LOAD_START,
	PERSONAL_DOCUMENTS_LOAD_SUCCESS,
	PERSONAL_DOCUMENTS_LOAD_FAIL,
	PERSONAL_DOCUMENTS_LOAD_RESET,
	PROPERTY_IMAGES_SAVE_START,
	PROPERTY_IMAGES_SAVE_SUCCESS,
	PROPERTY_IMAGES_SAVE_FAIL,
	PROPERTY_IMAGES_SAVE_RESET,
	PROPERTY_IMAGES_LOAD_START,
	PROPERTY_IMAGES_LOAD_SUCCESS,
	PROPERTY_IMAGES_LOAD_FAIL,
	PROPERTY_IMAGES_LOAD_RESET,
	TASK_RESET
} from './types';

export const initialTaskState = {
	tasksSave: {
		success: false,
		loading: false,
		error: null
	},
	personalDocumentsLoad: {
		success: false,
		loading: false,
		error: null
	},
	documents: [],
	propertyImagesSave: {
		success: false,
		loading: false,
		error: null
	},
	propertyImagesLoad: {
		success: false,
		loading: false,
		error: null
	},
	propertyImages: []
};

const taskReducers = (state, action) => {
	switch (action.type) {
	case TASKS_SAVE_START:
		return produce(state, draft => {
			draft.tasksSave.error = null;
			draft.tasksSave.loading = true;
			draft.tasksSave.success = false;
		});
	case TASKS_SAVE_SUCCESS:
		return produce(state, draft => {
			draft.tasksSave.error = null;
			draft.tasksSave.loading = false;
			draft.tasksSave.success = true;
			draft.documents = uniqBy(state.documents.concat(action.payload.documents), 'type');
		});
	case TASKS_SAVE_FAIL:
		return produce(state, draft => {
			draft.tasksSave.error = action.payload.error;
			draft.tasksSave.loading = false;
			draft.tasksSave.success = false;
			draft.documents = uniqBy(state.documents.concat(action.payload.documents), 'type');
		});
	case TASKS_SAVE_RESET:
		return produce(state, draft => {
			draft.tasksSave.error = null;
			draft.tasksSave.loading = false;
			draft.tasksSave.success= false;
		});
	case PERSONAL_DOCUMENTS_LOAD_START:
		return produce(state, draft => {
			draft.personalDocumentsLoad.error = null;
			draft.personalDocumentsLoad.loading = true;
			draft.personalDocumentsLoad.success = false;
		});
	case PERSONAL_DOCUMENTS_LOAD_SUCCESS:
		return produce(state, draft => {
			draft.personalDocumentsLoad.error = null;
			draft.personalDocumentsLoad.loading = false;
			draft.personalDocumentsLoad.success = true;
			draft.documents = uniqBy(state.documents.concat(action.payload.documents), 'type');
		});
	case PERSONAL_DOCUMENTS_LOAD_FAIL:
		return produce(state, draft => {
			draft.personalDocumentsLoad.error = action.payload;
			draft.personalDocumentsLoad.loading = false;
			draft.personalDocumentsLoad.success = false;
		});
	case PERSONAL_DOCUMENTS_LOAD_RESET:
		return produce(state, draft => {
			draft.personalDocumentsLoad.error = null;
			draft.personalDocumentsLoad.loading = false;
			draft.personalDocumentsLoad.success= false;
		});
	case PROPERTY_IMAGES_SAVE_START:
		return produce(state, draft => {
			draft.propertyImagesSave.error = null;
			draft.propertyImagesSave.loading = true;
			draft.propertyImagesSave.success = false;
		});
	case PROPERTY_IMAGES_SAVE_SUCCESS:
		return produce(state, draft => {
			draft.propertyImagesSave.error = null;
			draft.propertyImagesSave.loading = false;
			draft.propertyImagesSave.success = true;
			draft.propertyImages = uniqBy(state.propertyImages.concat(action.payload.images), 'id');
		});
	case PROPERTY_IMAGES_SAVE_FAIL:
		return produce(state, draft => {
			draft.propertyImagesSave.error = action.payload.error;
			draft.propertyImagesSave.loading = false;
			draft.propertyImagesSave.success = false;
			draft.propertyImages = uniqBy(state.propertyImages.concat(action.payload.images), 'id');
		});
	case PROPERTY_IMAGES_SAVE_RESET:
		return produce(state, draft => {
			draft.propertyImagesSave.error = null;
			draft.propertyImagesSave.loading = false;
			draft.propertyImagesSave.success= false;
		});
	case PROPERTY_IMAGES_LOAD_START:
		return produce(state, draft => {
			draft.propertyImagesLoad.error = null;
			draft.propertyImagesLoad.loading = true;
			draft.propertyImagesLoad.success = false;
		});
	case PROPERTY_IMAGES_LOAD_SUCCESS:
		return produce(state, draft => {
			draft.propertyImagesLoad.error = null;
			draft.propertyImagesLoad.loading = false;
			draft.propertyImagesLoad.success = true;
			draft.propertyImages = uniqBy(state.propertyImages.concat(action.payload.images), 'id');
		});
	case PROPERTY_IMAGES_LOAD_FAIL:
		return produce(state, draft => {
			draft.propertyImagesLoad.error = action.payload;
			draft.propertyImagesLoad.loading = false;
			draft.propertyImagesLoad.success = false;
		});
	case PROPERTY_IMAGES_LOAD_RESET:
		return produce(state, draft => {
			draft.propertyImagesLoad.error = null;
			draft.propertyImagesLoad.loading = false;
			draft.propertyImagesLoad.success= false;
		});
	case TASK_RESET:
		return initialTaskState;
	default:
		return state;
	};
};

export default taskReducers;
