import PropTypes from 'prop-types';
import React from 'react';
import ButtonComponent from '@material-ui/core/Button';
import classNames from 'classnames';

const propTypes = {
	children: PropTypes.node.isRequired,
	variant: PropTypes.string.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.string
};

const Button = props => {
	const { children, className, variant, onClick, type = 'button', ...otherProps } = props;
	return (
		<ButtonComponent
			className={classNames('button', className)}
			disableElevation
			onClick={onClick}
			type={type ? type : ''}
			variant={variant}
			{...otherProps}
		>
			{children}
		</ButtonComponent>
	);
};

Button.propTypes = propTypes;

export default Button;
