import React, { Suspense, lazy, useEffect } from 'react';
import Dashboard from 'layouts/Dashboard';
import Preapproval from 'containers/Preapproval';
import AuthContainer from 'containers/Auth';
// import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';

const SummaryHeader = lazy(() => import('pages/Summary/SummaryHeader'));
const IsPrivate = lazy(() => import('components/Alerts/IsPrivate'));
const SummaryApplicant = lazy(() => import('pages/Summary/SummaryApplicant'));
const SummaryFinancial = lazy(() => import('pages/Summary/SummaryFinancial'));
const SummaryProperty = lazy(() => import('pages/Summary/SummaryProperty'));
const SummaryAdditional = lazy(() => import('pages/Summary/SummaryAdditional'));
const SummaryTargetedProperty = lazy(() => import('pages/Summary/SummaryTargetedProperty'));

const Summary = props => {
	const {
		state: { auth }
	} = AuthContainer.useContainer();

	const {
		state: { identifier, service },
		loadLatestFormData
	} = Preapproval.useContainer();

	useEffect(() => {
		if (auth.loading || !auth.id) return;
		loadLatestFormData(auth.id);
	}, [auth]);

	if (!identifier) return null;
	return (
		<Dashboard className="summary">
			<Suspense fallback={<div></div>}>
				<div className="summary-container">
					<SummaryHeader />
					<IsPrivate />
					<SummaryApplicant />
					<SummaryFinancial />
					{service === 'rentback' && (
						<>
							<SummaryProperty />
							<SummaryAdditional />
						</>
					)}
					{service === 'rentown' && (
						<>
							<SummaryTargetedProperty />
						</>
					)}

				</div>
			</Suspense>
		</Dashboard>
	);
};

export default Summary;
