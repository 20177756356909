import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import TextareaCustom from 'components/CustomInputs/TextareaCustom';

import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const content = {
	en: {
		title: 'When do you think you will buy it back?',
		subTitle: 'Please explain it in detail.',
		answerLabel: 'Type your text here',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Kapan Anda sanggup membelinya kembali?',
		subTitle: 'Silakan jelaskan lebih lanjut',
		answerLabel: 'Uraikan di sini',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Buyback = props => {
	const { showToast, toPrevPage, validationSchema } = props;

	const history = useHistory();

	const {
		state: { languageCode }
	} = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation
		= languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const {
		state: { form, fields, fieldsUpsert, formSubmit },
		upsertFields,
		resetFieldsUpsert,
		submitForm,
		resetFormSubmit
	} = Preapproval.useContainer();
	const { register, handleSubmit, formState, setValue, watch } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const buyback = watch('buyback');

	useEffect(() => {
		const buyback = findFieldByFieldKey(fields, 'buyback')?.fieldValue;

		register({ name: 'buyback' });

		if (buyback) setValue('buyback', buyback);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			submitForm();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	useEffect(() => {
		if (formSubmit.success) {
			resetFormSubmit();
			history.push('/preapproval-success', { from: 'preapproval' });
		} else if (formSubmit.error) {
			resetFormSubmit();
			showToast(translation.somethingWentWrong);
		}
	}, [formSubmit]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'buyback',
				fieldValue: `${data.buyback}`
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const { errors } = formState;

	return (
		<div className="buyback-preapproval">
			<div className="title">{translation.title}</div>
			<div className="sub-title">{translation.subTitle}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextareaCustom
					error={errors.buyback ? true : false}
					message={validationTranslation[errors.buyback?.message]}
					name="buyback"
					placeholder={translation.answerLabel}
					ref={register}
					rows="3"></TextareaCustom>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || formSubmit.loading || !buyback} type="submit" variant="contained">
						{fieldsUpsert.loading || formSubmit.loading ? <CircularProgress /> : translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

Buyback.propTypes = propTypes;

export default StepContainer(Buyback);
