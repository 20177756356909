import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import PriceField from 'components/CustomInputs/PriceField';
import SimpleListGroup from 'components/List/SimpleListGroup';
import Checkbox from 'components/CustomInputs/Checkbox';
import TextareaCustom from 'components/CustomInputs/TextareaCustom';
import NumberFormat from 'react-number-format';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';
import { findFieldByFieldKey } from 'utils';
import Preapproval from 'containers/Preapproval';
import { CircularProgress } from '@material-ui/core';

const content = {
	en: {
		title: 'Walk us through your financial situation',
		subTitle: 'Please enter the amount of asset you own to let us know about your financial situation.',
		cashLabel: 'Cash',
		pensionLabel: 'Pension',
		investmentLabel: 'Investment',
		businessAccount: 'Business Account',
		otherLabel: 'Other',
		otherAnswerLabel: 'Example: grant, inheritance, etc.',
		totalLabel: 'Total Rp.',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Jelaskan kondisi finansial Anda lebih lanjut',
		subTitle: 'Silakan masukkan jumlah aset yang Anda miliki untuk membantu kami memahaminya.',
		cashLabel: 'Uang Tunai',
		pensionLabel: 'Pensiun',
		investmentLabel: 'Investasi',
		businessAccount: 'Rekening Bisnis',
		otherLabel: 'Lainnya',
		otherAnswerLabel: 'Contoh: hibah, warisan, dan lainnya',
		totalLabel: 'Total Rp.',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Financial = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();

	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		register({ name: 'cash' });
		register({ name: 'pension' });
		register({ name: 'investment' });
		register({ name: 'business_account' });
		register({ name: 'answer' });
		register({ name: 'other' });

		const financialCash = findFieldByFieldKey(fields, 'financialCash')?.fieldValue;
		const financialPension = findFieldByFieldKey(fields, 'financialPension')?.fieldValue;
		const financialInvestment = findFieldByFieldKey(fields, 'financialInvestment')?.fieldValue;
		const financialBusiness = findFieldByFieldKey(fields, 'financialBusiness')?.fieldValue;
		const financialOtherKey = findFieldByFieldKey(fields, 'financialOtherKey')?.fieldValue;
		const financialOther = findFieldByFieldKey(fields, 'financialOther')?.fieldValue;

		if (financialCash) setValue('cash', financialCash);
		if (financialPension && financialPension !== 'N/A') {
			setValue('is_pension_checked', true);
			setValue('pension', financialPension);
		}
		if (financialInvestment && financialInvestment !== 'N/A') {
			setValue('is_investment_checked', true);
			setValue('investment', financialInvestment);
		}
		if (financialBusiness && financialBusiness !== 'N/A') {
			setValue('is_business_account_checked', true);
			setValue('business_account', financialBusiness);
		}
		if (financialOtherKey && financialOtherKey !== 'N/A') {
			setValue('answer', financialOtherKey);
			setValue('other', financialOther);
			setValue('is_other_checked', true);
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'financialCash',
				fieldValue: data.cash
			},
			{
				fieldKey: 'financialPension',
				fieldValue: data.is_pension_checked ? data.pension : 'N/A'
			},
			{
				fieldKey: 'financialInvestment',
				fieldValue: data.is_investment_checked ? data.investment : 'N/A'
			},
			{
				fieldKey: 'financialBusiness',
				fieldValue: data.is_business_account_checked ? data.business_account : 'N/A'
			},
			{
				fieldKey: 'financialOtherKey',
				fieldValue: data.is_other_checked ? data.answer : 'N/A'
			},
			{
				fieldKey: 'financialOther',
				fieldValue: data.is_other_checked ? data.other : 'N/A'
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const cash = watch('cash');
	const isPensionChecked = watch('is_pension_checked');
	const pension = watch('pension');
	const isInvestmentChecked = watch('is_investment_checked');
	const investment = watch('investment');
	const isBusinessAccountChecked = watch('is_business_account_checked');
	const businessAccount = watch('business_account');
	const isOtherChecked = watch('is_other_checked');
	const answer = watch('answer');
	const other = watch('other');

	const total = useMemo(() => {
		return (cash ? Number(cash) : 0)
		+ (pension ? Number(pension) : 0)
		+ (investment ? Number(investment) : 0)
		+ (businessAccount ? Number(businessAccount) : 0)
		+ (other ? Number(other) : 0);
	});

	return (
		<div className="financial-preapproval">
			<div className="title">{translation.title}</div>
			<div className="sub-title">{translation.subTitle}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<SimpleListGroup>
					<div>
						<Checkbox
							checked={true}
							id="cash"
							label={translation.cashLabel}
							readOnly={true}
						/>
						<PriceField
							decimalSeparator={false}
							error={errors.cash ? true : false}
							message={validationTranslation[errors.cash?.message]}
							onValueChange={value => {
								setValue('cash', value.value);
								if (value.value) clearErrors('cash');
								else setValue('cash', undefined);
							}}
							thousandSeparator={'.'}
							value={cash}
						/>
					</div>
					<div>
						<Checkbox
							id="pension"
							label={translation.pensionLabel}
							name="is_pension_checked"
							ref={register}
						/>
						{isPensionChecked && (
							<PriceField
								decimalSeparator={false}
								error={errors.pension ? true : false}
								message={validationTranslation[errors.pension?.message]}
								onValueChange={value => {
									setValue('pension', value.value);
									if (value.value) clearErrors('pension');
									else setValue('pension', undefined);
								}}
								thousandSeparator={'.'}
								value={pension}
							/>
						)}
					</div>
					<div>
						<Checkbox
							id="investment"
							label={translation.investmentLabel}
							name="is_investment_checked"
							ref={register}
						/>
						{isInvestmentChecked && (
							<PriceField
								decimalSeparator={false}
								error={errors.investment ? true : false}
								message={validationTranslation[errors.investment?.message]}
								onValueChange={value => {
									setValue('investment', value.value);
									if (value.value) clearErrors('investment');
									else setValue('investment', undefined);
								}}
								thousandSeparator={'.'}
								value={investment}
							/>
						)}
					</div>
					<div>
						<Checkbox
							id="business_account"
							label={translation.businessAccount}
							name="is_business_account_checked"
							ref={register}
						/>
						{isBusinessAccountChecked && (
							<PriceField
								decimalSeparator={false}
								error={errors.business_account ? true : false}
								message={validationTranslation[errors.business_account?.message]}
								onValueChange={value => {
									setValue('business_account', value.value);
									if (value.value) clearErrors('business_account');
									else setValue('business_account', undefined);
								}}
								thousandSeparator={'.'}
								value={businessAccount}
							/>
						)}
					</div>
					<div>
						<Checkbox
							id="other"
							label={translation.otherLabel}
							name="is_other_checked"
							ref={register}
						/>
						{isOtherChecked && (
							<>
								<TextareaCustom
									error={errors.answer ? true : false}
									message={validationTranslation[errors.answer?.message]}
									onChange={e => setValue('answer', e.target.value)}
									placeholder={translation.otherAnswerLabel}
									rows="1"
									value={answer}
								>
								</TextareaCustom>
								<PriceField
									decimalSeparator={false}
									error={errors.other ? true : false}
									message={validationTranslation[errors.other?.message]}
									onValueChange={value => {
										setValue('other', value.value);
										if (value.value) clearErrors('other');
										else setValue('other', undefined);
									}}
									thousandSeparator={'.'}
									value={other}
								/>
							</>
						)}
					</div>
				</SimpleListGroup>
				<div className="total-label">
					{translation.totalLabel} <NumberFormat decimalSeparator={false} displayType={'text'} thousandSeparator={'.'} value={total} />
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={
						fieldsUpsert.loading
							|| (!cash)
							|| (isPensionChecked && !pension)
							|| (isInvestmentChecked && !investment)
							|| (isBusinessAccountChecked && !businessAccount)
							|| (isOtherChecked && (!other || !answer))
					}
					type="submit"
					variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Financial.propTypes = propTypes;
export default StepContainer(Financial);
