import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import SlideRoutes from 'react-slide-routes';
import {
	// Switch,
	Route,
	useRouteMatch,
	useLocation,
	useHistory
} from 'react-router-dom';
import _ from 'lodash';
import Div100vh from 'react-div-100vh';
import PreapprovalBar from './PreapprovalBar';
import HouseLoader from 'components/HouseLoader';
import Error from './Steps/Error';
import Steps from './Steps';
import { withResizeDetector } from 'react-resize-detector';
import PreapprovalContainer from 'containers/Preapproval';
import AuthContainer from 'containers/Auth';
import { getLastPage } from 'utils';

import {
	getTemplate,
	getStepsOrder
} from 'pages/Preapproval/Templates/Template';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';

const propTypes = {
	width: PropTypes.number,
	height: PropTypes.number
};

const Preapproval = props => {
	const { width, height } = props;

	const {
		state: preapprovalState,
		loadLatestFormData
	} = PreapprovalContainer.useContainer();

	const {
		state: { auth, userLogin },
		resetUserLogin,
		authUser
	} = AuthContainer.useContainer();

	const match = useRouteMatch();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (auth.id) return;
		authUser(auth.token);
	}, []);

	useEffect(() => {
		if (userLogin.success) {
			resetUserLogin();
		}
	}, [userLogin]);

	useDidUpdateEffect(() => {
		if (auth.loading) return;
		loadLatestFormData(auth.id);
	}, [auth]);

	useEffect(() => {
		if (
			!preapprovalState.identifier
			|| preapprovalState.latestFormDataLoad.error
		) {
			return;
		}
		if (!preapprovalState.form.status || !auth.token) {
			return history.replace(
				`${match.path}/${preapprovalState.identifier}/begin`
			);
		}
		if (preapprovalState.form.status !== 'draft') {
			return history.replace('/overview');
		}

		const path = getLastPage(
			preapprovalState.fields,
			getTemplate(preapprovalState)
		);

		if (preapprovalState.user.name && preapprovalState.user.phone) {
			history.replace(`${match.path}/${preapprovalState.identifier}/${path}`);
		} else {
			history.replace(`${match.path}/${preapprovalState.identifier}/contact`);
		}
	}, [preapprovalState.form]);

	const stepsOrder = useMemo(() => {
		return getStepsOrder(preapprovalState);
	}, [preapprovalState]);

	const pathList = useMemo(() => {
		return stepsOrder.map(step => {
			return `${match.path}/${preapprovalState.identifier}/${step}`;
		});
	}, [preapprovalState]);

	const routes = useMemo(() => {
		return stepsOrder.map((step, index) => {
			const stepName = step
				.split('-')
				.reduce((acc, name) => acc + _.capitalize(name), '');
			const ChildComponent = Steps[stepName];

			return (
				<Route
					key={index}
					path={`${match.path}/:identifier/${step}`}
					render={props => (
						<ChildComponent {...props} height={height} width={width} />
					)}
				/>
			);
		});
	}, [preapprovalState]);

	const currentStep = () => {
		const step = location.pathname.split('/').reverse()[0];
		return stepsOrder.indexOf(step) + 1;
	};

	return (
		<Div100vh className="preapproval">
			<Div100vh className="preapproval-container">
				<PreapprovalBar
					menu={preapprovalState.service || preapprovalState.form?.type}
					width={(currentStep() * 100) / stepsOrder.length}
				/>
				{!preapprovalState.latestFormDataLoad.error
				&& !preapprovalState.latestFormDataLoad.loading ? (
						<SlideRoutes location={location} pathList={pathList}>
							{routes}
						</SlideRoutes>
					) : null}
				{preapprovalState.latestFormDataLoad.error && <Error />}
				{preapprovalState.latestFormDataLoad.loading ? (
					<HouseLoader />
				) : null}
			</Div100vh>
		</Div100vh>
	);
};

Preapproval.propTypes = propTypes;

export default withResizeDetector(Preapproval);
