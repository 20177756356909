import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'What are you looking for?',
		house: 'House',
		apartment: 'Apartment',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Jenis properti apa yang dicari?',
		house: 'Rumah',
		apartment: 'Apartemen',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Looking = props => {
	const { toNextPage, toPrevPage, validationSchema } = props;

	const [isFinished, setIsFinished] = useState(false);

	const { register, handleSubmit, setValue } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const { state: { looking }, setLooking } = Preapproval.useContainer();

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	useEffect(() => {
		if (looking) {
			setValue('looking', looking);
		} else {
			setValue('looking', 'rumah');
		}
	}, []);

	useEffect(() => {
		if (isFinished) {
			toNextPage();
		}
	}, [isFinished]);

	const onSubmit = data => {
		setLooking(data.looking);
		setIsFinished(true);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="looking-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton id="one" label={translation.house} name="looking" ref={register} type="radio" value="rumah" />
					<RadioButton id="two" label={translation.apartment} name="looking" ref={register} type="radio" value="apartemen" />
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

Looking.propTypes = propTypes;
export default StepContainer(Looking);
