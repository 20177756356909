import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import { CircularProgress } from '@material-ui/core';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';
import TextField from 'components/CustomInputs/TextField';

const content = {
	en: {
		title: 'What is your marital status?',
		singleLabel: 'Single',
		marriedLabel: 'Married',
		divorcedLabel: 'Divorced',
		widowLabel: 'Widow or widower',
		childrenFieldTitle: 'How many children do you have?',
		childrenLabel: 'Type your answer here',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Apakah status perkawinan Anda?',
		singleLabel: 'Lajang',
		marriedLabel: 'Menikah',
		divorcedLabel: 'Cerai Hidup',
		widowLabel: 'Cerai Mati',
		childrenFieldTitle: 'Berapa jumlah anak Anda?',
		childrenLabel: 'Uraikan di sini',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Marital = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { register, handleSubmit, setValue, watch, errors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const {
		state: { form, fieldsUpsert, fields },
		upsertFields,
		resetFieldsUpsert
	} = Preapproval.useContainer();

	const maritalStatus = watch('marital_status');
	const children = watch('children');

	useEffect(() => {
		const maritalStatus = findFieldByFieldKey(fields, 'maritalStatus')?.fieldValue || 'Single';
		let children = findFieldByFieldKey(fields, 'children')?.fieldValue || '';
		children = children === 'N/A' ? '' : children;

		setValue('marital_status', maritalStatus);
		setValue('children', children);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		const maritalStatus = data.marital_status;
		const children = data.children;

		upsertFields(form.id, [
			{
				fieldKey: 'maritalStatus',
				fieldValue: maritalStatus
			},
			{
				fieldKey: 'children',
				fieldValue: maritalStatus !== 'Single' ? children : 'N/A'
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="marital-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton id="single" label={translation.singleLabel} name="marital_status" ref={register} type="radio" value="Single" />
					<RadioButton id="married" label={translation.marriedLabel} name="marital_status" ref={register} type="radio" value="Married" />
					<RadioButton id="divorced" label={translation.divorcedLabel} name="marital_status" ref={register} type="radio" value="Divorced" />
					<RadioButton id="widow" label={translation.widowLabel} name="marital_status" ref={register} type="radio" value="Widow or widower" />
				</div>
				{maritalStatus !== 'Single' ? (
					<div className="children-field-container">
						<div className="title">{translation.childrenFieldTitle}</div>
						<div className="input-container">
							<TextField
								defaultValue={children}
								error={errors.children ? true : false}
								label={translation.childrenLabel}
								message={validationTranslation[errors.children?.message]}
								name="children"
								ref={register}
								type="number"
							/>
						</div>
					</div>
				) : ''}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || (maritalStatus !== 'Single' && !children && children !== 0)}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Marital.propTypes = propTypes;
export default StepContainer(Marital);
