import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioSwitch from 'components/CustomInputs/RadioSwitch';

import { findFieldByFieldKey } from 'utils';
import Preapproval from 'containers/Preapproval';
import { CircularProgress } from '@material-ui/core';
import Preference from 'containers/Preference';
import RadioButton from 'components/CustomInputs/RadioButton';

const content = {
	en: {
		title: 'Do you need a car park?',
		yesLabel: 'Yes',
		noLabel: 'No',
		parkingTitle: 'What type of car park do you need?',
		garageLabel: 'Garage',
		carportLabel: 'Carport',
		bothLabel: 'Both',
		anythingLabel: 'Anything',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Apakah Anda butuh tempat parkir?',
		yesLabel: 'Ya',
		noLabel: 'Tidak',
		parkingTitle: 'Tempat parkir seperti apa yang Anda butuhkan?',
		garageLabel: 'Garasi',
		carportLabel: 'Carport',
		bothLabel: 'Keduanya',
		anythingLabel: 'Apa saja',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const ParkingDescribe = props => {
	const { toNextPage, showToast, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const {
		state: { form, fields, fieldsUpsert },
		upsertFields,
		resetFieldsUpsert
	} = Preapproval.useContainer();

	const { register, handleSubmit, setValue, watch } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const parking = watch('parking');
	const answer = watch('answer');

	useEffect(() => {
		const parking = findFieldByFieldKey(fields, 'parking')?.fieldValue;

		register({ name: 'answer' });

		setValue('parking', 'no');
		if (parking) {
			if (parking !== 'no') {
				setValue('parking', 'yes');
				setValue('answer', parking);
			}
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		const answer = data.parking === 'no' ? data.parking : data.answer;

		upsertFields(form.id, [{
			fieldKey: 'parking',
			fieldValue: answer
		}]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="parking-describe-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<RadioSwitch
					name="parking"
					options={[
						{ value: 'no', label: translation.noLabel },
						{ value: 'yes', label: translation.yesLabel }
					]}
					ref={register}
				/>
				{parking === 'yes' ? (
					<div className="parking-container">
						<div className="title">{translation.parkingTitle}</div>
						<div className="custom-radio-container">
							<RadioButton defaultChecked={answer === 'garage'} id="garage" label={translation.garageLabel} name="answer" ref={register} type="radio" value="garage"/>
							<RadioButton defaultChecked={answer === 'carport'} id="carport" label={translation.carportLabel} name="answer" ref={register} type="radio" value="carport" />
							<RadioButton defaultChecked={answer === 'garage and carport'} id="both" label={translation.bothLabel} name="answer" ref={register} type="radio" value="garage and carport" />
							<RadioButton defaultChecked={answer === 'anything'} id="anything" label={translation.anythingLabel} name="answer" ref={register} type="radio" value="anything" />
						</div>
					</div>
				) : ''}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || (parking === 'yes' && !answer)}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

ParkingDescribe.propTypes = propTypes;
export default StepContainer(ParkingDescribe);
