import React from 'react';
import StepContainer from './StepContainer';

import ErrorConnection from 'assets/images/ErrorConnection.svg';
import Preference from 'containers/Preference';
import Button from 'components/buttons/Button';
import Preapproval from 'containers/Preapproval';

const content = {
	en: {
		title: 'Something went wrong',
		description: 'No worries. We working on it. Please try again in a few minutes.',
		tryAgainButtonLabel: 'Try Again'
	},
	id: {
		title: 'Terjadi kesalahan.',
		description: 'Jangan khawatir. Sedang kami tangani. Harap coba lagi dalam beberapa menit.',
		tryAgainButtonLabel: 'Coba lagi'
	}
};

const Error = () => {
	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const { loadLatestFormData } = Preapproval.useContainer();
	return (
		<div className="error-preapproval">
			<div className="error-container">
				<img alt="error" className="error-image" src={ErrorConnection} />
				<div className="title">{translation.title}</div>
				<div className="description">{translation.description}</div>
				<Button onClick={loadLatestFormData} type="submit" variant="contained">
					{translation.tryAgainButtonLabel}
				</Button>
			</div>

		</div>
	);
};

export default StepContainer(Error);
