import {
	USER_LOGIN_START,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGIN_RESET,
	USER_AUTH_START,
	USER_AUTH_SUCCESS,
	AUTH_RESET,
	OTP_VERIFY_START,
	OTP_VERIFY_SUCCESS,
	OTP_VERIFY_FAIL,
	OTP_VERIFY_RESET
} from './types';

import { APIV2 } from 'constants/Api';
import { callApi, makePreapprovalBody, parseCookies } from 'utils';

const signupUserPreapproval = async (dispatch, { email, password, preapproval }) => {
	dispatch({ type: USER_LOGIN_START });

	const url = {
		rentback: `${APIV2}/onboarding/rentback`,
		rentown: `${APIV2}/onboarding/rentown`
	};

	const { json } = await callApi(url[preapproval.service], {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			...makePreapprovalBody(preapproval),
			user: {
				email,
				password
			}
		})
	});

	return json.success
		? dispatch({ type: USER_LOGIN_SUCCESS, payload: json })
		: dispatch({ type: USER_LOGIN_FAIL, payload: json.code });
};

const signupGooglePreapproval = async (dispatch, { idToken, preapproval }) => {
	dispatch({ type: USER_LOGIN_START });

	const url = {
		rentback: `${APIV2}/onboarding/rentback`,
		rentown: `${APIV2}/onboarding/rentown`
	};

	const { json } = await callApi(url[preapproval.service], {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			...makePreapprovalBody(preapproval),
			idToken
		})
	});

	return json.success
		? dispatch({ type: USER_LOGIN_SUCCESS, payload: json })
		: dispatch({ type: USER_LOGIN_FAIL, payload: json.code });
};

const resetUserLogin = dispatch => {
	return dispatch({ type: USER_LOGIN_RESET });
};

const verifyOtp = async (dispatch, { email, otp }) => {
	dispatch({ type: OTP_VERIFY_START });
	const { json } = await callApi(`${APIV2}/verify`, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ email, token: otp })
	});
	return json.success
		? dispatch({ type: OTP_VERIFY_SUCCESS, payload: json })
		: dispatch({ type: OTP_VERIFY_FAIL, payload: json.code });
};

const authUser = async (dispatch, token) => {
	dispatch({ type: USER_AUTH_START });
	const { json } = await callApi(`${APIV2}/users/current`, {
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		}
	});

	return json.success
		? dispatch({ type: USER_AUTH_SUCCESS, payload: json })
		: dispatch({ type: AUTH_RESET });
};

const getOtp = async token => {
	await callApi(`${APIV2}/security_code?lang=${parseCookies().languageCode || 'en'}`, {
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		}
	});
};

const resetOtpVerify = dispatch => {
	return dispatch({ type: OTP_VERIFY_RESET });
};

const resetAuth = dispatch => {
	return dispatch({ type: AUTH_RESET });
};

export default {
	signupUserPreapproval,
	signupGooglePreapproval,
	resetUserLogin,
	verifyOtp,
	resetOtpVerify,
	authUser,
	resetAuth,
	getOtp
};
