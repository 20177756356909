import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	children: PropTypes.node
};

const SimpleListGroup = props => {
	const { children, ...otherProps } = props;
	return (
		<div className="simple-list-group" {...otherProps}>
			{children}
		</div>
	);
};

SimpleListGroup.propTypes = propTypes;

export default SimpleListGroup;
