import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Auth from 'containers/Auth';

const propTypes = {
	width: PropTypes.number
};

export default ChildComponent => {
	const withAuth = props => {
		const { ...rest } = props;
		const {
			state: { auth },
			authUser
		} = Auth.useContainer();

		useEffect(() => {
			if (!auth.token) window.location.replace(`/login`);
			if (auth.token && (!auth.email || !auth.phone)) authUser(auth.token);
		}, [auth]);

		return <ChildComponent {...rest} />;
	};
	withAuth.propTypes = propTypes;

	return withAuth;
};
