import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Navigation from 'components/Navigation';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const propTypes = {
	menuItem: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

const AppBarMenuList = props => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleListKeyDown = event => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const { menuItem } = props;
	return (
		<div className="app-bar-menu-list">
			<div className="dropdown" onClick={handleToggle} ref={anchorRef}>
				{menuItem.name}
				<KeyboardArrowDownIcon className={classNames('menu-list-arrow', { open: open })} />
			</div>
			<Popper
				anchorEl={anchorRef.current}
				className="popper"
				disablePortal
				open={open}
				role={undefined}
				transition>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}>
						<Paper className="pop-up-menu">
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList autoFocusItem={false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
									{menuItem.sub.map((submenu, index) => {
										return (
											<MenuItem className="menu-item" key={index} onClick={handleClose}>
												<div>
													<Navigation
														className="menu-item-path"
														name={submenu.name}
														path={submenu.path}
													/>
												</div>
											</MenuItem>
										);
									})}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};

AppBarMenuList.propTypes = propTypes;

export default AppBarMenuList;
