import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'When are you looking to move in?',
		zeroToThree: '0 - 3 months',
		threeToSix: '3 - 6 months',
		moreThanSix: 'More than 6 months',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Kapan Anda mau pindah?',
		zeroToThree: '0 - 3 bulan',
		threeToSix: '3 - 6 bulan',
		moreThanSix: 'Lebih dari 6 bulan',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const LookingTimeline = props => {
	const { toNextPage, toPrevPage, validationSchema } = props;

	const [isFinished, setIsFinished] = useState(false);

	const { register, handleSubmit, setValue } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const { state: { lookingTimeline }, setLookingTimeline } = Preapproval.useContainer();

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	useEffect(() => {
		if (lookingTimeline) {
			setValue('looking_timeline', lookingTimeline);
		} else {
			setValue('looking_timeline', '0 - 3');
		}
	}, []);

	useEffect(() => {
		if (isFinished) {
			toNextPage();
		}
	}, [isFinished]);

	const onSubmit = data => {
		setLookingTimeline(data.looking_timeline);
		setIsFinished(true);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="looking-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton id="one" label={translation.zeroToThree} name="looking_timeline" ref={register} type="radio" value="0 - 3" />
					<RadioButton id="two" label={translation.threeToSix} name="looking_timeline" ref={register} type="radio" value="3 - 6" />
					<RadioButton id="three" label={translation.moreThanSix} name="looking_timeline" ref={register} type="radio" value="6+" />
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

LookingTimeline.propTypes = propTypes;
export default StepContainer(LookingTimeline);
