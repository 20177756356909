import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array.isRequired
};

const RadioSwitch = forwardRef((props, ref) => {
	const { id, name, onChange, value, options } = props;
	return (
		<div className="radio-switch" id={id} value={value}>
			{options.map((option, idx) => {
				const inputId = `radio_switch_${Math.random()}_${idx}`;
				return (
					<div className="radio-switch-container" key={option.value}>
						<input
							{...(value ? { checked: value==='no' } : {})}
							id={inputId}
							name={name}
							onChange={onChange}
							ref={ref}
							type="radio"
							value={option.value}
						/>
						<label htmlFor={inputId}>{option.label}</label>
					</div>
				);
			})}
		</div>
	);
});

RadioSwitch.propTypes = propTypes;
RadioSwitch.displayName = 'RadioSwitch';
export default RadioSwitch;
