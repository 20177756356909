import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import PriceField from 'components/CustomInputs/PriceField';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';
import { CircularProgress } from '@material-ui/core';
import { findFieldByFieldKey } from 'utils';
import Preapproval from 'containers/Preapproval';

const content = {
	en: {
		title: 'How much cash do you want to take out?',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Berapa jumlah dana yang ingin Anda terima?',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Cash = props => {
	const { toNextPage, toPrevPage, validationSchema, showToast } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const cash = watch('cash');

	useEffect(() => {
		const cashTakeOutExpectation = findFieldByFieldKey(fields, 'cashTakeOutExpectation')?.fieldValue;
		register({ name: 'cash' });
		setValue('cash', cashTakeOutExpectation);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [{
			fieldKey: 'cashTakeOutExpectation',
			fieldValue: String(data.cash)
		}]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="cash-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<PriceField
					decimalSeparator={false}
					error={errors.cash ? true : false}
					message={validationTranslation[errors.cash?.message] || translation.cashMessage}
					onValueChange={value => {
						setValue('cash', value.value);
						if (value.value) clearErrors('cash');
						else setValue('cash', undefined);
					}}
					thousandSeparator={'.'}
					value={cash}
				/>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || !cash || cash < 1}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Cash.propTypes = propTypes;
export default StepContainer(Cash);
