import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import TextField from 'components/CustomInputs/TextField';

import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';

const content = {
	en: {
		title: 'Tell us more details about the property',
		landSizeFieldLabel: 'Land Size (m²)',
		buildingSizeFieldLabel: 'Building Size (m²)',
		bedroomFieldLabel: 'Bedroom',
		bathroomFieldLabel: 'Bathroom',
		floorFieldLabel: 'Floor',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Detail tambahan tentang properti Anda',
		landSizeFieldLabel: 'Luas Tanah (m²)',
		buildingSizeFieldLabel: 'Luas Bangunan (m²)',
		bedroomFieldLabel: 'Kamar Tidur',
		bathroomFieldLabel: 'Kamar Mandi',
		floorFieldLabel: 'Lantai',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const PropertyDetails = props => {
	const { toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state: { propertyDetails, property }, setPropertyDetails } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, formState } = useForm({
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		register({ name: 'is_land_size_disabled' });

		if (property?.property_type === 'apartemen') {
			setValue('is_land_size_disabled', true);
		} else {
			setValue('is_land_size_disabled', false);
		}
	}, []);

	const onSubmit = data => {
		setPropertyDetails(data);
		toNextPage();
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const { errors } = formState;

	return (
		<div className="property-details-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="fields-container">
					<div
						className={classNames('input-container', {
							'col-2': property?.property_type !== 'apartemen'
						})}
					>
						{property?.property_type !== 'apartemen' && (
							<TextField
								defaultValue={propertyDetails?.land_size}
								error={errors.land_size ? true : false}
								label={translation.landSizeFieldLabel}
								message={validationTranslation[errors.land_size?.message]}
								name='land_size'
								ref={register}
								type="number"
							/>
						)}
						<TextField
							defaultValue={propertyDetails?.building_size}
							error={errors.building_size ? true : false}
							label={translation.buildingSizeFieldLabel}
							message={validationTranslation[errors.building_size?.message]}
							name='building_size'
							ref={register}
							type="number"
						/>
					</div>
					<div className="input-container col-2">
						<TextField
							defaultValue={propertyDetails?.bedroom}
							error={errors.bedroom ? true : false}
							label={translation.bedroomFieldLabel}
							message={validationTranslation[errors.bedroom?.message]}
							name='bedroom'
							ref={register}
							type="number"
						/>
						<TextField
							defaultValue={propertyDetails?.bathroom}
							error={errors.bathroom ? true : false}
							label={translation.bathroomFieldLabel}
							message={validationTranslation[errors.bathroom?.message]}
							name='bathroom'
							ref={register}
							type="number"
						/>
					</div>
					<div className="input-container">
						<TextField
							defaultValue={propertyDetails?.floor}
							error={errors.floor ? true : false}
							label={translation.floorFieldLabel}
							message={validationTranslation[errors.floor?.message]}
							name='floor'
							ref={register}
							type="number"
						/>
					</div>
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

PropertyDetails.propTypes = propTypes;

export default StepContainer(PropertyDetails);
