import React from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import Button from 'components/buttons/Button';
import SimpleListGroup from 'components/List/SimpleListGroup';
import SimpleParkingTypeItem from 'components/List/SimpleParkingTypeItem';
import Preapproval from 'containers/Preapproval';
import Checkbox from 'components/CustomInputs/Checkbox';
import Preference from 'containers/Preference';
import parse from 'html-react-parser';

import Garage from 'assets/images/Garage.svg';
import Carport from 'assets/images/Carport.svg';

const content = {
	en: {
		title: 'Which picture describes your car park the best?',
		garageLabel: 'Garage',
		carportLabel: 'Carport',
		capacityLabel: 'Capacity',
		car: 'car',
		notBothLabel: 'None of the above ',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Gambar mana yang paling mendeskripsikan tempat parkir Anda?',
		garageLabel: 'Garasi',
		carportLabel: '<em>Carport</em>',
		capacityLabel: 'Kapasitas',
		car: 'mobil',
		notBothLabel: 'Tidak ada',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const ParkingDetails = props => {
	const { toNextPage, toPrevPage } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const {
		state: {
			parkingCapacity: { garageCapacity, carportCapacity }
		},
		setParkingCapacity
	} = Preapproval.useContainer();

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="parking-details-preapproval">
			<div className="title">{translation.title}</div>
			<SimpleListGroup>
				<SimpleParkingTypeItem
					capacity={garageCapacity}
					checked={garageCapacity > 0 ? true : false}
					id="garage"
					image={Garage}
					itemName={translation.car}
					label={translation.capacityLabel}
					onChangeCapacity={capacity => setParkingCapacity(capacity, carportCapacity)}
					onChangeCheckbox={value => {
						if (value) setParkingCapacity(1, carportCapacity);
						else setParkingCapacity(0, carportCapacity);
					}}
					title={translation.garageLabel}
				/>
				<SimpleParkingTypeItem
					capacity={carportCapacity}
					checked={carportCapacity > 0 ? true : false}
					id="carport"
					image={Carport}
					itemName={translation.car}
					label={translation.capacityLabel}
					onChangeCapacity={capacity => setParkingCapacity(garageCapacity, capacity)}
					onChangeCheckbox={value => {
						if (value) setParkingCapacity(garageCapacity, 1);
						else setParkingCapacity(garageCapacity, 0);
					}}
					title={parse(translation.carportLabel)}
				/>
			</SimpleListGroup>
			<div className="checkbox-container">
				<Checkbox
					checked={!carportCapacity && !garageCapacity}
					id="notboth"
					label={translation.notBothLabel}
					onChange={() => {
						setParkingCapacity(0, 0);
					}}
				/>
			</div>
			<div className="buttons">
				<Button onClick={handlePrev} type="button" variant="outlined">
					{translation.previous}
				</Button>
				<Button onClick={toNextPage} type="submit" variant="contained">
					{translation.next}
				</Button>
			</div>
		</div>
	);
};

ParkingDetails.propTypes = propTypes;
export default StepContainer(ParkingDetails);
