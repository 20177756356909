import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import TextField from 'components/CustomInputs/TextField';

import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import { CircularProgress } from '@material-ui/core';

const content = {
	en: {
		title: 'Please describe your current employment status',
		companyNameLabel: 'Company’s Name',
		companyAddressLabel: 'Company’s Address',
		companyPhoneLabel: 'Company’s Phone Number',
		yearsLabel: 'Years at Company',
		yearsInfo: 'How long have you worked here?',
		previous: 'Previous',
		next: 'Next',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Silakan jelaskan status pekerjaan Anda',
		companyNameLabel: 'Nama Perusahaan',
		companyAddressLabel: 'Alamat Perusahaan',
		companyPhoneLabel: 'Nomor Telepon Perusahaan',
		yearsLabel: 'Masa Kerja',
		yearsInfo: 'Sudah berapa tahun Anda dipekerjakan?',
		previous: 'Kembali',
		next: 'Lanjut',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const EmploymentDetails = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { register, handleSubmit, setValue, formState, watch } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const {
		state: { form, fieldsUpsert, fields },
		upsertFields,
		resetFieldsUpsert
	} = Preapproval.useContainer();

	const companyName = watch('company_name');
	const companyAddress = watch('company_address');
	const companyPhone = watch('company_phone');
	const years = watch('years');

	useEffect(() => {
		const companyName = findFieldByFieldKey(fields, 'companyName')?.fieldValue;
		const companyAddress = findFieldByFieldKey(fields, 'companyAddress')?.fieldValue;
		const companyPhone = findFieldByFieldKey(fields, 'companyPhone')?.fieldValue;
		const years = findFieldByFieldKey(fields, 'years')?.fieldValue;

		setValue('company_name', companyName);
		setValue('company_address', companyAddress);
		setValue('company_phone', companyPhone);
		setValue('years', years);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'companyName',
				fieldValue: data.company_name
			},
			{
				fieldKey: 'companyAddress',
				fieldValue: data.company_address
			},
			{
				fieldKey: 'companyPhone',
				fieldValue: data.company_phone
			},
			{
				fieldKey: 'years',
				fieldValue: data.years
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const { errors } = formState;

	return (
		<div className="employment-details-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="input-container">
					<TextField
						defaultValue={companyName}
						error={errors.company_name ? true : false}
						label={translation.companyNameLabel}
						message={validationTranslation[errors.company_name?.message]}
						name='company_name'
						ref={register}
						type="text"
					/>
				</div>
				<div className="input-container">
					<TextField
						defaultValue={companyAddress}
						error={errors.company_address ? true : false}
						label={translation.companyAddressLabel}
						message={validationTranslation[errors.company_address?.message]}
						name='company_address'
						ref={register}
						type="text"
					/>
				</div>
				<div className="input-container col-2">
					<TextField
						defaultValue={companyPhone}
						error={errors.company_phone ? true : false}
						label={translation.companyPhoneLabel}
						message={validationTranslation[errors.company_phone?.message]}
						name='company_phone'
						ref={register}
						type="number"
					/>
					<TextField
						defaultValue={years}
						error={errors.years ? true : false}
						label={translation.yearsLabel}
						message={validationTranslation[errors.years?.message] || translation.yearsInfo}
						name='years'
						ref={register}
						type="number"
					/>
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

EmploymentDetails.propTypes = propTypes;

export default StepContainer(EmploymentDetails);
