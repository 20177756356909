import PropTypes from 'prop-types';
import React from 'react';
import MobileMenu from 'components/MobileMenu';
import Logo from 'assets/images/Logo.svg';
import Preference from 'containers/Preference';
import { useHistory } from 'react-router-dom';

const propTypes = {
	width: PropTypes.number.isRequired,
	menu: PropTypes.string
};

const content = {
	en: {
		rentback: 'Rentback',
		rentToOwn: 'Rent-to-Own'
	},
	id: {
		rentback: 'Rentback',
		rentToOwn: 'Rent-to-Own'
	}
};

const PreapprovalBar = props => {
	const { width, menu } = props;

	const history = useHistory();

	const {
		state: { languageCode }
	} = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	return (
		<div>
			<div className="preapproval-bar base">
				<div className="base-container">
					<div className="preapproval-bar-container">
						<div className="preapproval-bar-left">
							{!menu && (
								<>
									<MobileMenu menu="home" />
									<img alt="Logo" className="logo" onClick={() => history.push('/home')} src={Logo} />
								</>
							)}
							{menu === 'rentback' && <div className="title">{translation.rentback}</div>}
							{menu === 'rentown' && <div className="title">{translation.rentToOwn}</div>}
						</div>
					</div>
				</div>
			</div>
			<div className="offset progress-indicator" style={{ width: `${width}%` }}></div>
		</div>
	);
};

PreapprovalBar.propTypes = propTypes;

export default PreapprovalBar;
