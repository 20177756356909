export default {
	en: {
		rumah: 'House',
		apartemen: 'Apartment',
		kantor: 'Office',
		ruko: 'Shop',
		yes: 'Yes',
		no: 'No',
		approved: 'Approved',
		denied: 'Denied',
		'social media': 'Social Media',
		agent: 'Agent',
		friend: 'Friend',
		other: 'Other',
		'unqualified bi checking': 'Unqualified BI Checking',
		'inadequate down-payment': 'Inadequate down-payment',
		'contract worker': 'Contract Worker',
		'self-employment': 'Self-Employment',
		employee: 'Employee',
		unemployed: 'Unemployed',
		'as soon as possible': 'As soon as possible',
		carport: 'Carport',
		garage: 'Garage',
		'garage and carport': 'Garage and Carport',
		anything: 'Anything',
		government: 'Government',
		company: 'Company',
		'collectibility 1': 'Collectibility 1 (Good)',
		'collectibility 2': 'Collectibility 2 (Under scrutiny)',
		'collectibility 3': 'Collectibility 3 (Not good)',
		'collectibility 4': 'Collectibility 4 (Poor)',
		'collectibility 5': 'Collectibility 5 (Very poor)',
		unknown: 'Unknown'
	},
	id: {
		rumah: 'Rumah',
		apartemen: 'Apartemen',
		kantor: 'Kantor',
		ruko: 'Ruko',
		yes: 'Ya',
		no: 'Tidak',
		approved: 'Diterima',
		denied: 'Ditolak',
		'social media': 'Media Sosial',
		agent: 'Agen',
		friend: 'Teman',
		other: 'Lainnya',
		'unqualified bi checking': 'Terkendala BI Checking',
		'inadequate down-payment': 'Down-payment tidak cukup',
		'contract worker': 'Pekerja Kontrak',
		'self-employment': 'Wiraswasta',
		employee: 'Karyawan',
		unemployed: 'Sedang tidak bekerja',
		'as soon as possible': 'Secepatnya',
		carport: 'Carport',
		garage: 'Garasi',
		'garage and carport': 'Garasi dan Carport',
		anything: 'Apa saja',
		government: 'PNS',
		company: 'Pekerja Swasta',
		'collectibility 1': 'Kolektibilitas 1 (Lancar)',
		'collectibility 2': 'Kolektibilitas 2 (Dalam perhatian khusus)',
		'collectibility 3': 'Kolektibilitas 3 (Kurang lancar)',
		'collectibility 4': 'Kolektibilitas 4 (Diragukan)',
		'collectibility 5': 'Kolektibilitas 5 (Macet)',
		unknown: 'Tidak diketahui'
	}
};
