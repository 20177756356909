import { APIV2 } from 'constants/Api';
import { callApi, parseCookies } from 'utils';

import {
	PERSONAL_DOCUMENTS_LOAD_FAIL,
	PERSONAL_DOCUMENTS_LOAD_RESET,
	PERSONAL_DOCUMENTS_LOAD_START,
	PERSONAL_DOCUMENTS_LOAD_SUCCESS,
	PROPERTY_IMAGES_LOAD_FAIL,
	PROPERTY_IMAGES_LOAD_RESET,
	PROPERTY_IMAGES_LOAD_START,
	PROPERTY_IMAGES_LOAD_SUCCESS,
	PROPERTY_IMAGES_SAVE_FAIL,
	PROPERTY_IMAGES_SAVE_RESET,
	PROPERTY_IMAGES_SAVE_START,
	PROPERTY_IMAGES_SAVE_SUCCESS,
	TASKS_SAVE_FAIL,
	TASKS_SAVE_RESET,
	TASKS_SAVE_START,
	TASKS_SAVE_SUCCESS,
	TASK_RESET
} from './types';


const saveTasks = async (dispatch, { tasks }) => {
	dispatch({ type: TASKS_SAVE_START });

	const cookies = parseCookies();
	const accessToken = 'Bearer ' + cookies.token;

	const filteredTask = tasks.filter(task => task.file);
	const calls = filteredTask.map(task => {
		const formData = new FormData();
		formData.append('file', task.file);
		formData.append('data', JSON.stringify({
			type: task.type
		}));

		let id = '',
			component = '';

		if (task.propertyId) {
			id = task.propertyId;
			component = 'properties';
		} else if (task.formId) {
			id = task.formId;
			component = 'forms';
		} else if (task.userId) {
			id = task.userId;
			component = 'users';
		}

		return callApi(`${APIV2}/${component}/${id}/documents`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Authorization: accessToken
			},
			body: formData
		});
	});

	Promise.all(calls).then(values => {
		const jsonValues = values.map(value => value.json);
		let documents = jsonValues.filter(value => !value.code);
		documents = documents.length ? documents.map(value => value.document) : undefined;
		const error = jsonValues.find(value => value.code);
		if (error) {
			dispatch({
				type: TASKS_SAVE_FAIL,
				payload: { documents: documents || [], error: error.code }
			});
		} else {
			dispatch({
				type: TASKS_SAVE_SUCCESS,
				payload: { documents: documents || [] }
			});
		}
	}).catch(err => {
		console.log(err);
	});
};

const resetTasksSave = dispatch => {
	return dispatch({ type: TASKS_SAVE_RESET });
};


const loadPersonalDocuments = async (dispatch, { userId }) => {
	dispatch({ type: PERSONAL_DOCUMENTS_LOAD_START });

	const cookies = parseCookies();
	const accessToken = 'Bearer ' + cookies.token;

	const { json } = await callApi(`${APIV2}/users/${userId}/documents/preapproval`, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: accessToken
		}
	});

	return json.success
		? dispatch({ type: PERSONAL_DOCUMENTS_LOAD_SUCCESS, payload: json })
		: dispatch({ type: PERSONAL_DOCUMENTS_LOAD_FAIL, payload: json.code });
};

const resetPersonalDocumentsLoad = dispatch => {
	return dispatch({ type: PERSONAL_DOCUMENTS_LOAD_RESET });
};


const savePropertyImages = async (dispatch, { propertyId, tasks }) => {
	dispatch({ type: PROPERTY_IMAGES_SAVE_START });

	const cookies = parseCookies();
	const accessToken = 'Bearer ' + cookies.token;

	const filteredTask = tasks.filter(task => task.files && task.files.length);
	const calls = filteredTask.map(task => {
		const formData = new FormData();
		task.files.forEach(file => {
			formData.append('files', file);
		});
		formData.append('data', JSON.stringify({
			title: task.type
		}));

		return callApi(`${APIV2}/properties/${propertyId}/images`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Authorization: accessToken
			},
			body: formData
		});
	});

	Promise.all(calls).then(values => {
		const jsonValues = values.map(value => value.json);

		let images = jsonValues.filter(value => !value.code);
		images = images.length ? images.map(value => value.images) : undefined;

		const convertedImages = [];

		images?.forEach(el => {
			el.forEach(image => {
				convertedImages.push(image);
			});
		});

		const error = jsonValues.find(value => value.code);

		if (error) {
			dispatch({
				type: PROPERTY_IMAGES_SAVE_FAIL,
				payload: { images: convertedImages || [], error: error.code }
			});
		} else {
			dispatch({
				type: PROPERTY_IMAGES_SAVE_SUCCESS,
				payload: { images: convertedImages || [] }
			});
		}
	}).catch(err => {
		console.log(err);
	});
};

const resetPropertyImagesSave = dispatch => {
	return dispatch({ type: PROPERTY_IMAGES_SAVE_RESET });
};


const loadPropertyImages = async (dispatch, { propertyId }) => {
	dispatch({ type: PROPERTY_IMAGES_LOAD_START });

	const cookies = parseCookies();
	const accessToken = 'Bearer ' + cookies.token;

	const { json } = await callApi(`${APIV2}/properties/${propertyId}/images`, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: accessToken
		}
	});

	return json.success
		? dispatch({ type: PROPERTY_IMAGES_LOAD_SUCCESS, payload: json })
		: dispatch({ type: PROPERTY_IMAGES_LOAD_FAIL, payload: json.code });
};

const resetPropertyImagesLoad = dispatch => {
	return dispatch({ type: PROPERTY_IMAGES_LOAD_RESET });
};

const resetTask = dispatch => {
	return dispatch({ type: TASK_RESET });
};

export default {
	saveTasks,
	resetTasksSave,
	loadPersonalDocuments,
	resetPersonalDocumentsLoad,
	savePropertyImages,
	resetPropertyImagesSave,
	loadPropertyImages,
	resetPropertyImagesLoad,
	resetTask
};
