import { parseCookies } from 'utils';

export const StepsOrder = [
	'begin',
	'process',
	'looking-timeline',
	'referral',
	'signup',
	'contact',
	'slik-status',
	'property-describe',
	'parking-describe',
	'marital',
	'employment',
	'employment-details',
	'monthly-income',
	'financial',
	'spend',
	'down-payment',
	'monthly-payment',
	'reason',
	'current-living'
];

export const RentownTemplate = [
	{
		page: 'referral',
		fields: ['referral']
	},
	{
		page: 'slik-status',
		fields: ['slikOjkStatus']
	},
	{
		page: 'property-describe',
		fields: [
			'landSize',
			'buildingSize',
			'beds',
			'baths',
			'floor',
			'certificate'
		]
	},
	{
		page: 'parking-describe',
		fields: [
			'parking'
		]
	},
	{
		page: 'marital',
		fields: ['maritalStatus', 'children']
	},
	{
		page: 'employment',
		fields: ['employmentStatus']
	},
	{
		page: 'employment-details',
		fields: [
			'companyName',
			'companyAddress',
			'companyPhone',
			'years'
		]
	},
	{
		page: 'monthly-income',
		fields: [
			'monthlyIncome'
		]
	},
	{
		page: 'financial',
		fields: [
			'financialCash',
			'financialPension',
			'financialInvestment',
			'financialBusiness',
			'financialOtherKey',
			'financialOther'
		]
	},
	{
		page: 'spend',
		fields: [
			'spend'
		]
	},
	{
		page: 'down-payment',
		fields: [
			'downpayment'
		]
	},
	{
		page: 'monthly-payment',
		fields: [
			'monthlyPayment'
		]
	},
	{
		page: 'reason',
		fields: [
			'reason'
		]
	},
	{
		page: 'current-living',
		fields: [
			'currentAddress',
			'currentProperty',
			'propertyStatus'
		]
	}
];

export const getRentownTemplate = fields => {
	const steps = [...RentownTemplate];

	if (!fields) {
		return steps;
	}

	return steps;
};

export const getRentownStepsOrder = state => {
	let steps = [...StepsOrder];

	const cookies = parseCookies();
	const auth = cookies.token;

	if (auth) {
		const valuesToRemove = ['signup'];
		steps = steps.filter(step => !valuesToRemove.includes(step));
	}

	if (state.process === 'signed') {
		const valuesToAdd = ['search', 'address', 'property-type', 'property-complete'];
		const index = steps.findIndex(step => step === 'process') + 1;
		steps.splice(index, 0, ...valuesToAdd);
	} else {
		const valuesToAdd = ['looking', 'looking-location'];
		const index = steps.findIndex(step => step === 'process') + 1;
		steps.splice(index, 0, ...valuesToAdd);
	}

	if (!state.fields) {
		return steps;
	}

	return steps;
};
