import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { CSSTransition } from 'react-transition-group';

const propTypes = {
	message: PropTypes.string,
	offset: PropTypes.number,
	isShow: PropTypes.bool,
	variant: PropTypes.string
};

const Toast = props => {
	const { message, offset, isShow, variant } = props;

	return (
		<div className="toast" style={{ top: `${offset}px` }}>
			<CSSTransition
				className={classNames('toast-item', { success: variant === 'success' })}
				classNames="animate"
				in={isShow}
				timeout={500}>
				<div>{parse(message)}</div>
			</CSSTransition>
		</div>
	);
};

Toast.propTypes = propTypes;

export default Toast;
