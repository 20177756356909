import {
	IDENTIFIER_SET,
	SERVICE_SET,
	ADDRESS_AUTOCOMPLETE_SET,
	ADDRESS_DETAILS_SET,
	IS_PARKING_AVAILABLE_SET,
	PARKING_CAPACITY_SET,
	PROPERTY_SET,
	PROPERTY_DETAILS_SET,
	IS_OWNER_SET,
	REFERRAL_SET,
	CITY_REQUEST_START,
	CITY_REQUEST_SUCCESS,
	CITY_REQUEST_FAIL,
	CITY_REQUEST_RESET,
	LATEST_FORM_DATA_LOAD_START,
	LATEST_FORM_DATA_LOAD_SUCCESS,
	LATEST_FORM_DATA_LOAD_FAIL,
	LATEST_FORM_DATA_LOAD_RESET,
	USER_PROFILE_UPDATE_RESET,
	USER_PROFILE_UPDATE_START,
	USER_PROFILE_UPDATE_SUCCESS,
	USER_PROFILE_UPDATE_FAIL,
	FIELDS_UPSERT_START,
	FIELDS_UPSERT_SUCCESS,
	FIELDS_UPSERT_FAIL,
	FIELDS_UPSERT_RESET,
	FORM_SUBMIT_START,
	FORM_SUBMIT_SUCCESS,
	FORM_SUBMIT_FAIL,
	FORM_SUBMIT_RESET,
	PREAPPROVAL_RESET,
	FORM_STATUS_UPDATE_START,
	FORM_STATUS_UPDATE_SUCCESS,
	FORM_STATUS_UPDATE_FAIL,
	FORM_STATUS_UPDATE_RESET,
	PROCESS_SET,
	LOOKING_TIMELINE_SET,
	LOOKING_SET,
	LOOKING_LOCATION_SET,
	PROPERTY_TYPE_SET,
	IS_PROPERTY_COMPLETE_SET
} from './types';

import { APIV2 } from 'constants/Api';
import { callApi, generateIdentifier, parseCookies, splitFullName } from 'utils';

const setIdentifier = (dispatch, identifier) => {
	return dispatch({ type: IDENTIFIER_SET, payload: identifier });
};

const setService = (dispatch, service) => {
	return dispatch({ type: SERVICE_SET, payload: service });
};

const setAddressAutocompleteData = (dispatch, addressData) => {
	return dispatch({ type: ADDRESS_AUTOCOMPLETE_SET, payload: addressData });
};

const setAddressDetails = (dispatch, addressDetailsData) => {
	return dispatch({ type: ADDRESS_DETAILS_SET, payload: addressDetailsData });
};

const setIsParkingAvailable = (dispatch, isParkingAvailable) => {
	return dispatch({ type: IS_PARKING_AVAILABLE_SET, payload: isParkingAvailable });
};

const setParkingCapacity = (dispatch, { garageCapacity, carportCapacity }) => {
	return dispatch({ type: PARKING_CAPACITY_SET, payload: { garageCapacity, carportCapacity } });
};

const setProperty = (dispatch, propertyData) => {
	return dispatch({ type: PROPERTY_SET, payload: propertyData });
};

const setPropertyDetails = (dispatch, propertyDetailsData) => {
	return dispatch({ type: PROPERTY_DETAILS_SET, payload: propertyDetailsData });
};

const setIsOwner = (dispatch, isOwner) => {
	return dispatch({ type: IS_OWNER_SET, payload: isOwner });
};

const setReferral = (dispatch, referralData) => {
	return dispatch({ type: REFERRAL_SET, payload: referralData });
};

const requestCity = async (dispatch, { email, city, address }) => {
	const body = {
		email,
		city,
		address
	};

	dispatch({ type: CITY_REQUEST_START });

	const { json } = await callApi(`${APIV2}/services/request_coverage`, {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	});

	return json.success
		? dispatch({ type: CITY_REQUEST_SUCCESS, payload: email })
		: dispatch({ type: CITY_REQUEST_FAIL, payload: json.code });
};

const resetCityRequest = dispatch => {
	dispatch({ type: CITY_REQUEST_RESET });
};

const loadLatestFormData = async (dispatch, { userId }) => {
	dispatch({ type: LATEST_FORM_DATA_LOAD_START });

	const cookies = parseCookies();
	const accessToken = cookies.token ? `Bearer ${cookies.token}` : undefined;

	if (!accessToken || !userId) {
		return dispatch({
			type: LATEST_FORM_DATA_LOAD_SUCCESS,
			payload: { form: { identifier: generateIdentifier() } }
		});
	}

	const { json: jsonForms } = await callApi(`${APIV2}/users/${userId}/forms`, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: accessToken
		}
	});

	if (!jsonForms.success) return dispatch({ type: LATEST_FORM_DATA_LOAD_FAIL, payload: jsonForms.code });

	const form = jsonForms.forms.find(form => {
		return form.status === 'draft'
			|| form.status === 'submitted'
			|| form.status === 'completed'
			|| form.status === 'processing'
			|| form.status === 'verified'
			|| form.status === 'approved'
			|| form.status === 'denied';
	});

	if (!form) {
		return dispatch({
			type: LATEST_FORM_DATA_LOAD_SUCCESS,
			payload: { form: { identifier: generateIdentifier() } }
		});
	}

	const { json: jsonFormData } = await callApi(`${APIV2}/forms/${form.id}`, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: accessToken
		}
	});

	return jsonFormData.success
		? dispatch({ type: LATEST_FORM_DATA_LOAD_SUCCESS, payload: jsonFormData })
		: dispatch({ type: LATEST_FORM_DATA_LOAD_FAIL, payload: jsonFormData.code });
};

const resetLatestFormDataLoad = dispatch => {
	dispatch({ type: LATEST_FORM_DATA_LOAD_RESET });
};

const updateUserProfile = async (dispatch, { userId, fullName, phone }) => {
	dispatch({ type: USER_PROFILE_UPDATE_START });

	const cookies = parseCookies();
	const accessToken = 'Bearer ' + cookies.token;

	const { firstName, lastName } = splitFullName(fullName);

	const { json } = await callApi(`${APIV2}/users/${userId}`, {
		method: 'PUT',
		mode: 'cors',
		headers: {
			Authorization: accessToken,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ firstName, lastName, phone })
	});

	return json.success
		? dispatch({ type: USER_PROFILE_UPDATE_SUCCESS, payload: { fullName, phone } })
		: dispatch({ type: USER_PROFILE_UPDATE_FAIL, payload: json.code });
};

const resetUserProfileUpdate = dispatch => {
	return dispatch({ type: USER_PROFILE_UPDATE_RESET });
};

const upsertFields = async (dispatch, { formId, fields }) => {
	dispatch({ type: FIELDS_UPSERT_START });

	const cookies = parseCookies();
	const accessToken = 'Bearer ' + cookies.token;

	const { json } = await callApi(`${APIV2}/forms/${formId}/fields`, {
		method: 'POST',
		mode: 'cors',
		headers: {
			Authorization: accessToken,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(fields)
	});

	return json.success
		? dispatch({ type: FIELDS_UPSERT_SUCCESS, payload: json })
		: dispatch({ type: FIELDS_UPSERT_FAIL, payload: json.code });
};

const resetFieldsUpsert = dispatch => {
	return dispatch({ type: FIELDS_UPSERT_RESET });
};

const submitForm = async (dispatch, { formId }) => {
	dispatch({ type: FORM_SUBMIT_START });

	const cookies = parseCookies();
	const accessToken = 'Bearer ' + cookies.token;

	const { json } = await callApi(`${APIV2}/forms/${formId}`, {
		method: 'PUT',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: accessToken
		},
		body: JSON.stringify({
			status: 'submitted',
			languageCode: cookies.languageCode
		})
	});

	return json.success
		? dispatch({ type: FORM_SUBMIT_SUCCESS, payload: json })
		: dispatch({ type: FORM_SUBMIT_FAIL, payload: json.code });
};

const resetFormSubmit = dispatch => {
	return dispatch({ type: FORM_SUBMIT_RESET });
};

const updateFormStatus = async (dispatch, { formId, status }) => {
	dispatch({ type: FORM_STATUS_UPDATE_START });

	const cookies = parseCookies();
	const accessToken = 'Bearer ' + cookies.token;

	const { json } = await callApi(`${APIV2}/forms/${formId}`, {
		method: 'PUT',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Authorization: accessToken
		},
		body: JSON.stringify({
			status: status,
			languageCode: cookies.languageCode || 'en'
		})
	});

	return json.success
		? dispatch({ type: FORM_STATUS_UPDATE_SUCCESS, payload: status })
		: dispatch({ type: FORM_STATUS_UPDATE_FAIL, payload: json.code });
};

const resetFormStatusUpdate = dispatch => {
	return dispatch({ type: FORM_STATUS_UPDATE_RESET });
};

const setProcess = (dispatch, process) => {
	return dispatch({ type: PROCESS_SET, payload: process });
};

const setLooking = (dispatch, looking) => {
	return dispatch({ type: LOOKING_SET, payload: looking });
};

const setLookingTimeline = (dispatch, lookingTimeline) => {
	return dispatch({ type: LOOKING_TIMELINE_SET, payload: lookingTimeline });
};

const setLookingLocation = (dispatch, lookingLocation) => {
	return dispatch({ type: LOOKING_LOCATION_SET, payload: lookingLocation });
};

const setPropertyType = (dispatch, propertyType) => {
	return dispatch({ type: PROPERTY_TYPE_SET, payload: propertyType });
};

const setIsPropertyComplete = (dispatch, isPropertyComplete) => {
	return dispatch({ type: IS_PROPERTY_COMPLETE_SET, payload: isPropertyComplete });
};

const resetPreapproval = dispatch => {
	return dispatch({ type: PREAPPROVAL_RESET });
};

export default {
	setIdentifier,
	setService,
	setAddressAutocompleteData,
	setAddressDetails,
	setIsParkingAvailable,
	setParkingCapacity,
	setProperty,
	setPropertyDetails,
	setIsOwner,
	setReferral,
	requestCity,
	resetCityRequest,
	loadLatestFormData,
	resetLatestFormDataLoad,
	updateUserProfile,
	resetUserProfileUpdate,
	upsertFields,
	resetFieldsUpsert,
	submitForm,
	resetFormSubmit,
	updateFormStatus,
	resetFormStatusUpdate,
	setProcess,
	setLooking,
	setLookingTimeline,
	setLookingLocation,
	setPropertyType,
	setIsPropertyComplete,
	resetPreapproval
};
