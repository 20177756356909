import React from 'react';
import Div100vh from 'react-div-100vh';
import { useHistory, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import Button from 'components/buttons/Button';
import Logo from 'assets/images/Logo.svg';
import HillHouses from 'assets/images/HillHouses.svg';
import Preference from 'containers/Preference';

const content = {
	en: {
		title:
			'<span>You are pre-approved!</span> Our property specialist will contact you shortly.',
		description:
			'We will further evaluate and review your application. Help us speed things up by giving us more information and verifying relevant details.',
		button: 'Complete Application Now'
	},
	id: {
		title:
			'<span>Anda mendapatkan pra-persetujuan!</span> Spesialis kami akan segera menghubungi Anda.',
		description:
			'Kami akan melakukan evaluasi lanjutan dan meninjau pengajuan Anda. Bantu kami percepat prosesnya dengan memberikan dan memverifikasi informasi yang diperlukan.',
		button: 'Lengkapi Pengajuan'
	}
};

const PreapprovalSuccess = props => {
	const {
		state: { languageCode }
	} = Preference.useContainer();
	const history = useHistory();
	const location = useLocation();
	const translation = content[languageCode];

	if (!location.state?.from === 'Homepage' || !location.state) window.location.href='/home';
	return (
		<div className="preapproval-success">
			<Div100vh className="preapproval-success-container">
				<div className="content">
					<img alt="Logo" className="logo" src={Logo} />
					<div className="title">{parse(translation.title)}</div>
					<div className="description">{translation.description}</div>
					<Button onClick={() => history.push('/overview')} variant="contained">
						{translation.button}
					</Button>
				</div>
				<img alt="HillHouses" className="hill-houses" src={HillHouses} />
			</Div100vh>
		</div>
	);
};

export default PreapprovalSuccess;
