import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import TextField from 'components/CustomInputs/TextField';

import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';
import { findFieldByFieldKey } from 'utils';
import SelectField from 'components/CustomInputs/SelectField';
import { CircularProgress } from '@material-ui/core';

const content = {
	en: {
		title: 'Please specify your property requirements',
		landSizeFieldLabel: 'Land Size (m²)',
		buildingSizeFieldLabel: 'Building Size (m²)',
		bedroomFieldLabel: 'Bedroom',
		bathroomFieldLabel: 'Bathroom',
		floorFieldLabel: 'Floor',
		certificateFieldLabel: 'Certificate',
		previous: 'Previous',
		next: 'Next',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Properti seperti apa yang Anda cari?',
		landSizeFieldLabel: 'Luas Tanah (m²)',
		buildingSizeFieldLabel: 'Luas Bangunan (m²)',
		bedroomFieldLabel: 'Kamar Tidur',
		bathroomFieldLabel: 'Kamar Mandi',
		floorFieldLabel: 'Lantai',
		certificateFieldLabel: 'Sertifikat',
		previous: 'Kembali',
		next: 'Lanjut',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const options = [
	{ value: 'SHM', label: 'Sertifikat Hak Milik (SHM)' },
	{ value: 'SHGB', label: 'Sertifikat Hak Guna Bangunan (SHGB)' },
	{ value: 'Girik', label: 'Girik' },
	{ value: 'PPJB', label: 'Perjanjian Pengikatan Jual Beli (PPJB)' },
	{ value: 'Pra-PPJB', label: 'Pra Perjanian Pengikatan Jual Beli (Pra-PPJB)' },
	{ value: 'SHMSRS', label: 'Sertifikat Hak Milik Atas Satuan Rumah Susun (SHMSRS)' }
];

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const PropertyDescribe = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { register, handleSubmit, setValue, formState, watch, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const {
		state: { form, fieldsUpsert, fields },
		upsertFields,
		resetFieldsUpsert
	} = Preapproval.useContainer();

	useEffect(() => {
		const certificate = findFieldByFieldKey(fields, 'certificate')?.fieldValue;
		register({ name: 'certificate' });
		setValue('certificate', certificate);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'landSize',
				fieldValue: data.land_size
			},
			{
				fieldKey: 'buildingSize',
				fieldValue: data.building_size
			},
			{
				fieldKey: 'beds',
				fieldValue: data.beds
			},
			{
				fieldKey: 'baths',
				fieldValue: data.baths
			},
			{
				fieldKey: 'floor',
				fieldValue: data.floor
			},
			{
				fieldKey: 'certificate',
				fieldValue: data.certificate
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const { errors } = formState;

	const certificate = watch('certificate');

	return (
		<div className="property-describe-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="fields-container">
					<div className="input-container col-2">
						<TextField
							defaultValue={findFieldByFieldKey(fields, 'landSize')?.fieldValue}
							error={errors.land_size ? true : false}
							label={translation.landSizeFieldLabel}
							message={validationTranslation[errors.land_size?.message]}
							name='land_size'
							ref={register}
							type="number"
						/>
						<TextField
							defaultValue={findFieldByFieldKey(fields, 'buildingSize')?.fieldValue}
							error={errors.building_size ? true : false}
							label={translation.buildingSizeFieldLabel}
							message={validationTranslation[errors.building_size?.message]}
							name='building_size'
							ref={register}
							type="number"
						/>
					</div>
					<div className="input-container col-2">
						<TextField
							defaultValue={findFieldByFieldKey(fields, 'beds')?.fieldValue}
							error={errors.beds ? true : false}
							label={translation.bedroomFieldLabel}
							message={validationTranslation[errors.beds?.message]}
							name='beds'
							ref={register}
							type="number"
						/>
						<TextField
							defaultValue={findFieldByFieldKey(fields, 'baths')?.fieldValue}
							error={errors.baths ? true : false}
							label={translation.bathroomFieldLabel}
							message={validationTranslation[errors.baths?.message]}
							name='baths'
							ref={register}
							type="number"
						/>
					</div>
					<div className="input-container">
						<TextField
							defaultValue={findFieldByFieldKey(fields, 'floor')?.fieldValue}
							error={errors.floor ? true : false}
							label={translation.floorFieldLabel}
							message={validationTranslation[errors.floor?.message]}
							name='floor'
							ref={register}
							type="number"
						/>
					</div>
					<div className="input-container">
						<SelectField
							error={errors.certificate ? true : false}
							label={translation.certificateFieldLabel}
							message={validationTranslation[errors.certificate?.message]}
							onChange={val => {
								setValue('certificate', val);
								if (val) clearErrors('certificate');
							}}
							options={options}
							value={certificate}
						/>
					</div>
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

PropertyDescribe.propTypes = propTypes;

export default StepContainer(PropertyDescribe);
