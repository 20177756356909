import { useReducer } from 'react';
import { createContainer } from 'unstated-next';
import preapprovalReducers, { initialPreapprovalState } from './reducers';
import preapprovalActions from './actions';

const usePreapproval = () => {
	const [state, dispatch] = useReducer(preapprovalReducers, initialPreapprovalState);

	const setIdentifier = identifier => {
		preapprovalActions.setIdentifier(dispatch, identifier);
	};

	const setService = service => {
		preapprovalActions.setService(dispatch, service);
	};

	const setAddressAutocompleteData = addressData => {
		preapprovalActions.setAddressAutocompleteData(dispatch, addressData);
	};

	const setAddressDetails = addressDetailsData => {
		preapprovalActions.setAddressDetails(dispatch, addressDetailsData);
	};

	const setIsParkingAvailable = isParkingAvailable => {
		preapprovalActions.setIsParkingAvailable(dispatch, isParkingAvailable);
	};

	const setParkingCapacity = (garageCapacity, carportCapacity) => {
		preapprovalActions.setParkingCapacity(dispatch, { garageCapacity, carportCapacity });
	};

	const setProperty = propertyData => {
		preapprovalActions.setProperty(dispatch, propertyData);
	};

	const setPropertyDetails = propertyDetailsData => {
		preapprovalActions.setPropertyDetails(dispatch, propertyDetailsData);
	};

	const setIsOwner = isOwner => {
		preapprovalActions.setIsOwner(dispatch, isOwner);
	};

	const setReferral = referralData => {
		preapprovalActions.setReferral(dispatch, referralData);
	};

	const requestCity = async (email, city, address) => {
		preapprovalActions.requestCity(dispatch, { email, city, address });
	};

	const resetCityRequest = () => {
		preapprovalActions.resetCityRequest(dispatch);
	};

	const loadLatestFormData = async userId => {
		preapprovalActions.loadLatestFormData(dispatch, { userId });
	};

	const resetLatestFormDataLoad = () => {
		preapprovalActions.resetLatestFormDataLoad(dispatch);
	};

	const updateUserProfile = async (userId, fullName, phone) => {
		preapprovalActions.updateUserProfile(dispatch, { userId, fullName, phone });
	};

	const resetUserProfileUpdate = () => {
		preapprovalActions.resetUserProfileUpdate(dispatch);
	};

	const upsertFields = async (formId, fields) => {
		preapprovalActions.upsertFields(dispatch, { formId, fields });
	};

	const resetFieldsUpsert = () => {
		preapprovalActions.resetFieldsUpsert(dispatch);
	};

	const resetPropertiesLoad = () => {
		preapprovalActions.resetPropertiesLoad(dispatch);
	};

	const submitForm = async () => {
		const formId = state.form.id;
		if (formId) preapprovalActions.submitForm(dispatch, { formId });
	};

	const resetFormSubmit = () => {
		preapprovalActions.resetFormSubmit(dispatch);
	};

	const updateFormStatus = async status => {
		const formId = state.form.id;
		if (formId) preapprovalActions.updateFormStatus(dispatch, { formId, status });
	};

	const resetFormStatusUpdate = () => {
		preapprovalActions.resetFormStatusUpdate(dispatch);
	};

	const setProcess = process => {
		preapprovalActions.setProcess(dispatch, process);
	};

	const setLooking = looking => {
		preapprovalActions.setLooking(dispatch, looking);
	};

	const setLookingTimeline = lookingTimeline => {
		preapprovalActions.setLookingTimeline(dispatch, lookingTimeline);
	};

	const setLookingLocation = lookingLocation => {
		preapprovalActions.setLookingLocation(dispatch, lookingLocation);
	};

	const setPropertyType = propertyType => {
		preapprovalActions.setPropertyType(dispatch, propertyType);
	};

	const setIsPropertyComplete = isPropertyComplete => {
		preapprovalActions.setIsPropertyComplete(dispatch, isPropertyComplete);
	};

	const resetPreapproval = () => {
		preapprovalActions.resetPreapproval(dispatch);
	};

	return {
		state,
		setIdentifier,
		setService,
		setAddressAutocompleteData,
		setAddressDetails,
		setIsParkingAvailable,
		setParkingCapacity,
		setProperty,
		setPropertyDetails,
		setIsOwner,
		setReferral,
		requestCity,
		resetCityRequest,
		loadLatestFormData,
		resetLatestFormDataLoad,
		updateUserProfile,
		resetUserProfileUpdate,
		upsertFields,
		resetFieldsUpsert,
		resetPropertiesLoad,
		submitForm,
		resetFormSubmit,
		updateFormStatus,
		resetFormStatusUpdate,
		setProcess,
		setLooking,
		setLookingTimeline,
		setLookingLocation,
		setPropertyType,
		setIsPropertyComplete,
		resetPreapproval
	};
};

export default createContainer(usePreapproval);
