import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const propTypes = {
	className: PropTypes.string,
	path: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	spa: PropTypes.bool
};

const Navigation = props => {
	const location = useLocation();
	const { className, path, name, spa } = props;

	const externalUrl = path.substring(0, 4) === 'http' ? true : false;

	if (externalUrl) {
		return <a href={path}>{name}</a>;
	} else if (spa) {
		return (
			<NavLink
				className={className}
				onClick={() => {
					location.pathname === path && window.location.reload();
				}}
				to={path}
			>
				{name}
			</NavLink>
		);
	} else {
		return (
			<a
				className={className}
				href={path}
			>
				{name}
			</a>
		);
	}
};

Navigation.propTypes = propTypes;

export default Navigation;
