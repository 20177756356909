import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioSwitch from 'components/CustomInputs/RadioSwitch';
import PriceField from 'components/CustomInputs/PriceField';
import { findFieldByFieldKey } from 'utils';
import Preapproval from 'containers/Preapproval';
import { CircularProgress } from '@material-ui/core';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'Are you currently making monthly payments on the property?',
		yesLabel: 'Yes',
		noLabel: 'No',
		howMuchTitle: 'How much?',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Apakah Anda sedang membayar angsuran bulanan properti tersebut?',
		yesLabel: 'Ya',
		noLabel: 'Tidak',
		howMuchTitle: 'Berapa besarannya?',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Monthly = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const monthlyPayments = watch('monthly_payments');
	const answer = watch('answer');

	useEffect(() => {
		const monthlyPayments = findFieldByFieldKey(fields, 'monthlyPayments')?.fieldValue;
		const monthlyPaymentsFee = findFieldByFieldKey(fields, 'monthlyPaymentsFee')?.fieldValue;

		register({ name: 'answer' });

		setValue('answer', undefined);
		if (monthlyPayments) {
			if (monthlyPayments !== 'no') {
				setValue('monthly_payments', 'yes');
				setValue('answer', monthlyPaymentsFee);
			} else {
				setValue('monthly_payments', monthlyPayments);
			}
		} else {
			setValue('monthly_payments', 'no');
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [{
			fieldKey: 'monthlyPayments',
			fieldValue: data.monthly_payments
		}, {
			fieldKey: 'monthlyPaymentsFee',
			fieldValue: data.monthly_payments === 'yes' ? data.answer : 'N/A'
		}]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="monthly-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<RadioSwitch
					name="monthly_payments"
					options={[{ value: 'no', label: translation.noLabel }, { value: 'yes', label: translation.yesLabel }]}
					ref={register}
				/>
				{monthlyPayments === 'yes' ? (
					<div className="payment-container">
						<div className="title">{translation.howMuchTitle}</div>
						<PriceField
							decimalSeparator={false}
							error={errors.answer ? true : false}
							message={validationTranslation[errors.answer?.message]}
							onValueChange={value => {
								setValue('answer', value.value);
								if (value) clearErrors('answer');
								else setValue('answer', undefined);
							}}
							thousandSeparator={'.'}
							value={answer}
						/>
					</div>
				) : ''}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || (monthlyPayments === 'yes' && (!answer || answer < 1))}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Monthly.propTypes = propTypes;
export default StepContainer(Monthly);
