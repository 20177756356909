import React from 'react';
import LogoWhite from 'assets/images/LogoWhite.svg';
import SideMenu from 'components/SideMenu';
import LanguageSwitcher from 'components/LanguageSwitcher';
import Preference from 'containers/Preference';
import Auth from 'containers/Auth';
import { useHistory } from 'react-router-dom';

const SideBar = props => {
	const {
		state: { languageCode }
	} = Preference.useContainer();

	const {
		state: { auth }
	} = Auth.useContainer();

	const history = useHistory();

	const { name, email } = auth;

	return (
		<div className="side-bar">
			<div className="logo-container">
				<img alt="Logo" className="logo" onClick={() => history.push('/')} src={LogoWhite} />
			</div>
			<div className="profile-container">
				<div className="user-details-container">
					<div className="full-name">{name}</div>
					<div className="email">{email}</div>
				</div>
			</div>
			<div className="menu">
				<SideMenu language={languageCode} menu="dashboard" />
				<div className="switch-wrapper">
					<LanguageSwitcher />
				</div>
			</div>
		</div>
	);
};

export default SideBar;
