import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import TextareaCustom from 'components/CustomInputs/TextareaCustom';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import { CircularProgress } from '@material-ui/core';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'tell us about your employment status',
		selfEmploymentLabel: 'Self-Employment',
		employeeLabel: 'Employee',
		notCurrentlyLabel: 'Unemployed',
		otherLabel: 'Other',
		answerLabel: 'Answer',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'jelaskan tentang status pekerjaan Anda',
		selfEmploymentLabel: 'Wiraswasta',
		employeeLabel: 'Karyawan',
		notCurrentlyLabel: 'Sedang tidak bekerja',
		otherLabel: 'Lainnya',
		answerLabel: 'Jawaban',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Income = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const {
		state: { form, user, fieldsUpsert, fields },
		upsertFields,
		resetFieldsUpsert
	} = Preapproval.useContainer();

	const incomeSources = watch('income_sources');
	const answer = watch('answer');

	useEffect(() => {
		const incomeSources = findFieldByFieldKey(fields, 'income')?.fieldValue;

		register({ name: 'answer' });

		setValue('answer', '');
		if (incomeSources) {
			if (incomeSources !== 'Self-Employment' && incomeSources !== 'Employee' && incomeSources !== 'Unemployed') {
				setValue('income_sources', 'Other');
				setValue('answer', incomeSources);
			} else {
				setValue('income_sources', incomeSources);
			}
		} else {
			setValue('income_sources', 'Self-Employment');
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		let answer = '';

		if (data.income_sources !== 'Other') {
			answer = data.income_sources;
		} else {
			answer = data.answer;
		}

		upsertFields(form.id, [{
			fieldKey: 'income',
			fieldValue: answer
		}]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="income-preapproval">
			<div className="title">{user.name?.split(' ')[0]}, {translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton id="self" label={translation.selfEmploymentLabel} name="income_sources" ref={register} type="radio" value="Self-Employment" />
					<RadioButton id="employee" label={translation.employeeLabel} name="income_sources" ref={register} type="radio" value="Employee" />
					<RadioButton id="not" label={translation.notCurrentlyLabel} name="income_sources" ref={register} type="radio" value="Unemployed" />
					<RadioButton id="other" label={translation.otherLabel} name="income_sources" ref={register} type="radio" value="Other" />
				</div>
				{incomeSources === 'Other' ? (
					<div className="input-container">
						<TextareaCustom
							error={errors.answer ? true : false}
							message={validationTranslation[errors.answer?.message]}
							name='answer'
							onChange={e => {
								setValue('answer', e.target.value);
								if (e.target.value) clearErrors('answer');
							}}
							placeholder={translation.answerLabel}
							rows="1"
							value={answer}
						>
						</TextareaCustom>
					</div>
				) : ''}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || (incomeSources === 'Other' && !answer)}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Income.propTypes = propTypes;
export default StepContainer(Income);
