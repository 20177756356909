import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Navigation from 'components/Navigation';
import MenuListItems from 'constants/MenuListItems';
import Auth from 'containers/Auth';
import Preapproval from 'containers/Preapproval';
import Task from 'containers/Task';
import { ReactComponent as ChevronDown } from 'assets/images/ChevronDown.svg';

const propTypes = {
	menu: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired
};

const dashboardPaths = [
	'/overview',
	'/summary',
	'/tasks'
];

const SideMenu = props => {
	const [active, setActive] = useState('');
	const { menu, language } = props;
	const location = useLocation();
	const { pathname } = location;

	const { state: { auth }, resetAuth } = Auth.useContainer();
	const { resetPreapproval } = Preapproval.useContainer();
	const { resetTask } = Task.useContainer();

	const isDashboard = dashboardPaths.find(path => path === pathname) ? true : false;


	useEffect(() => {
		MenuListItems[menu][language].map((menuItem, index) => {
			if (menuItem.sub) {
				menuItem.sub.map(sub => {
					if (sub.path === pathname) setActive(menuItem.name);
				});
			} else {
				if (menuItem.path === pathname) setActive(menuItem.name);
			}
		});
	}, []);

	const menuList = (menuItem, index) => {
		const [viewList, setViewList] = useState(false);
		const toggleViewList = () => {
			setViewList(!viewList);
		};
		return (
			<li className={classNames({ active: menuItem.name === active })} key={index}>
				<div className="mobile-menu-list" onClick={toggleViewList}>
					<div>{menuItem.name}</div>
					<ChevronDown className={classNames('menu-list-arrow', { open: viewList })} stroke="#FFF"/>
				</div>
				{viewList && (
					<div className="sub-menu">
						<ul>
							{menuItem.sub.map((sub, index) => {
								return (
									<li key={index}>
										<Navigation name={sub.name} path={sub.path} spa={isDashboard} />
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</li>
		);
	};

	const MenuPath = (name, path, index) => {
		return (
			<li className={classNames({ active: name === active })} key={index}>
				<div className="mobile-menu-path">
					<Navigation name={name} path={path} spa={isDashboard} />
				</div>
			</li>
		);
	};

	return (
		<div className="side-menu">
			<ul>
				{MenuListItems[menu][language].map((menuItem, index) => {
					return menuItem.sub
						? menuList(menuItem, index)
						: MenuPath(menuItem.name, menuItem.path, index);
				})}
			</ul>
			<div>
				<div className="hr"></div>
				<div className="auth">
					{!auth.token ? (
						<div onClick={() => window.location.href = `/login`}>
							{language === 'en' ? 'Login' : 'Masuk'}
						</div>
					) : (
						<div onClick={() => {
							resetAuth();
							resetPreapproval();
							resetTask();
						}}>
							{language === 'en' ? 'Logout' : 'Keluar'}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

SideMenu.propTypes = propTypes;

export default SideMenu;
