import camelize from 'camelize';
import hexgen from 'hex-generator';
import cloneDeep from 'lodash/cloneDeep';
import validator from 'email-validator';
import CommonTranslations from 'constants/CommonTranslations';
import MonthTranslations from 'constants/MonthTranslations';
import cookie from 'cookie';

export const callApi = (url, options) =>
	fetch(url, options)
		.then(response => {
			if (response.ok || (!response.ok && response.statusText !== 'Unauthorized')) {
				return response.json();
			} else {
				return { error: response.statusText };
			}
		})
		.then(json => ({ json: camelize(json) }))
		.catch(error => {
			return { json: { success: false, code: 'NetworkError' } };
		});

export const makePreapprovalBody = preapproval => {
	const body = {
		rentback: {
			property: {
				DistrictId: preapproval.addressDetails?.district_id || null,
				Owner: preapproval.isOwner === 'yes' ? true : null,
				address1: preapproval.addressDetails?.address || null,
				address2: preapproval.addressDetails?.building_name || null,
				unitNumber: preapproval.addressDetails?.unit_number || null,
				type: preapproval.property?.property_type,
				certificate: preapproval.property?.certificate,
				buildingSize: Number(preapproval.propertyDetails?.building_size),
				landSize:
					preapproval.property?.property_type === 'rumah'
						? Number(preapproval.propertyDetails?.land_size)
						: null,
				beds: Number(preapproval.propertyDetails?.bedroom || 0) || 0,
				baths: Number(preapproval.propertyDetails?.bathroom || 0) || 0,
				latitude: preapproval.addressAutocompleteData?.lat || null,
				longitude: preapproval.addressAutocompleteData?.lng || null
			},
			form: {
				version: '1.0.0',
				identifier: preapproval.identifier || null
			},
			field: [
				{
					fieldKey: 'referral',
					fieldValue: preapproval.referral || 'N/A'
				}
			],
			detail: [
				{
					metaKey: 'postalCode',
					metaValue: preapproval.addressDetails?.postal_code || 'N/A'
				},
				{
					metaKey: 'yearBuilt',
					metaValue: preapproval.property?.year_built || 'N/A'
				},
				{
					metaKey: 'floor',
					metaValue: preapproval.propertyDetails?.floor || 'N/A'
				},
				{
					metaKey: 'park',
					metaValue: preapproval.isParkingAvailable
				},
				{
					metaKey: 'garageCapacity',
					metaValue:
						preapproval.isParkingAvailable === 'yes'
						&& preapproval.parkingCapacity?.garageCapacity !== 0
							? String(preapproval.parkingCapacity?.garageCapacity)
							: 'N/A'
				},
				{
					metaKey: 'carportCapacity',
					metaValue:
						preapproval.isParkingAvailable === 'yes'
						&& preapproval.parkingCapacity?.carportCapacity !== 0
							? String(preapproval.parkingCapacity?.carportCapacity)
							: 'N/A'
				},
				{
					metaKey: 'parkOther',
					metaValue:
						preapproval.isParkingAvailable === 'yes'
						&& preapproval.parkingCapacity?.carportCapacity === 0
						&& preapproval.parkingCapacity?.garageCapacity === 0
							? 'yes'
							: 'N/A'
				}
			]
		},
		rentown: {
			form: {
				version: '1.0.0',
				identifier: preapproval.identifier || null
			},
			field: [
				{
					fieldKey: 'process',
					fieldValue: preapproval.process || 'N/A'
				},
				{
					fieldKey: 'targetProperty',
					fieldValue: preapproval.process !== 'signed'
						? preapproval.looking
						: 'N/A'
				},
				{
					fieldKey: 'targetLocation',
					fieldValue: preapproval.process !== 'signed'
						? preapproval.lookingLocation
						: 'N/A'
				},
				{
					fieldKey: 'signedAddress',
					fieldValue: preapproval.process === 'signed'
						? (
							`${preapproval.addressDetails.address}`
							+ (preapproval.addressDetails?.building_name ? `, ${preapproval.addressDetails.building_name}` : '')
							+ (preapproval.addressDetails?.unit_number ? ` ${preapproval.addressDetails.unit_number}, ` : `, `)
							+ (`${preapproval.addressDetails.district}, ${preapproval.addressDetails.city}, ${preapproval.addressDetails.province}`)
							+ (preapproval.addressDetails?.postal_code ? `, ${preapproval.addressDetails.postal_code}` : '')
						)
						: 'N/A'
				},
				{
					fieldKey: 'signedType',
					fieldValue: preapproval.process === 'signed'
						? preapproval.propertyType
						: 'N/A'
				},
				{
					fieldKey: 'signedComplete',
					fieldValue: preapproval.process === 'signed'
						? preapproval.isPropertyComplete
						: 'N/A'
				},
				{
					fieldKey: 'moveTime',
					fieldValue: preapproval.lookingTimeline || 'N/A'
				},
				{
					fieldKey: 'referral',
					fieldValue: preapproval.referral || 'N/A'
				}
			]
		}
	};
	return body[preapproval.service];
};

export const splitFullName = fullName => {
	const fullNameArray = fullName.replace(/^\s+|\s+$/g, '').split(' ');
	return {
		firstName: fullNameArray.shift() || null,
		lastName: fullNameArray.join(' ') || null
	};
};

export const joinFullName = (firstName, lastName) => {
	return !firstName ? null : !lastName ? firstName : firstName + ' ' + lastName;
};

export const generateIdentifier = () => {
	return hexgen(64).match(/.{4}/g).join('-');
};

export const getLastPage = (fields, template) => {
	const foundedPage = template.find(page => {
		if (!page.fields) return false;
		let foundedFields = fields.filter(el => page.fields.includes(el.fieldKey));

		if (!foundedFields.length) return true;

		foundedFields = foundedFields.filter(el => el.fieldValue);

		if (!foundedFields.length) return true;
	});

	if (!foundedPage) return template[template.length - 1].page;

	return foundedPage.page;
};

export const combineFields = (fields, fieldsToInsert) => {
	const result = cloneDeep(fields);

	fieldsToInsert.forEach(field => {
		const indexFound = fields.findIndex(el => el.fieldKey === field.fieldKey);
		if (indexFound !== -1) {
			result[indexFound] = field;
		} else {
			result.unshift(field);
		}
	});

	return result;
};

export const findFieldByFieldKey = (fields, fieldKey) => {
	return fields?.find(el => el.fieldKey === fieldKey);
};

export const validateEmail = email => {
	return validator.validate(email);
};

export const setCurr = value => {
	const options = { minimumFractionDigits: 0, currency: 'IDR', style: 'currency' };
	return value.match(/^[0-9]+$/) ? new Intl.NumberFormat('id-ID', options).format(value) : value;
};

export const translate = (key, languageCode) => {
	return CommonTranslations[languageCode][key.toLowerCase()] || key;
};

export const formatDate = (date, languageCode = 'en') => {
	if (!date || date === '-') return '-';
	const splittedDate = date.split('-');
	return `${MonthTranslations[languageCode][splittedDate[1]]}, ${splittedDate[0]}`;
};

export const getPropertyDetailFromKey = (property, key) => {
	return property.Details?.find(detail => detail.metaKey === key);
};

export function parseCookies(req) {
	return cookie.parse(req ? req.headers.cookie || '' : document.cookie);
}
