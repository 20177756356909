import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import { CircularProgress } from '@material-ui/core';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'Please describe your current employment status',
		governmentLabel: 'Government',
		companyLabel: 'Company',
		selfLabel: 'Self-employment',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Silakan jelaskan status pekerjaan Anda',
		governmentLabel: 'PNS',
		companyLabel: 'Pekerja Swasta',
		selfLabel: 'Wiraswasta',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Employment = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const { register, handleSubmit, setValue } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const {
		state: { form, fieldsUpsert, fields },
		upsertFields,
		resetFieldsUpsert
	} = Preapproval.useContainer();

	useEffect(() => {
		const employmentStatus = findFieldByFieldKey(fields, 'employmentStatus')?.fieldValue || 'Government';
		setValue('employment_status', employmentStatus);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [{
			fieldKey: 'employmentStatus',
			fieldValue: data.employment_status
		}]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="employment-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton id="government" label={translation.governmentLabel} name="employment_status" ref={register} type="radio" value="Government" />
					<RadioButton id="company" label={translation.companyLabel} name="employment_status" ref={register} type="radio" value="Company" />
					<RadioButton id="self" label={translation.selfLabel} name="employment_status" ref={register} type="radio" value="Self-employment" />
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Employment.propTypes = propTypes;
export default StepContainer(Employment);
