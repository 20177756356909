import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';
import TextField from 'components/CustomInputs/TextField';

import FormValidationContent from 'constants/FormValidationContent';

const content = {
	en: {
		title: 'Where are you looking for a home?',
		locationFieldLabel: 'Location or Address',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Di area mana Anda mau tinggal?',
		locationFieldLabel: 'Lokasi atau Alamat',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const LookingTimeline = props => {
	const { toNextPage, toPrevPage, validationSchema } = props;

	const [isFinished, setIsFinished] = useState(false);

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const { state: { lookingLocation }, setLookingLocation } = Preapproval.useContainer();

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	useEffect(() => {
		if (isFinished) {
			toNextPage();
		}
	}, [isFinished]);

	const onSubmit = data => {
		setLookingLocation(data.looking_location);
		setIsFinished(true);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="looking-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="input-container">
					<TextField
						defaultValue={lookingLocation}
						error={errors.looking_location ? true : false}
						label={translation.locationFieldLabel}
						message={validationTranslation[errors.looking_location?.message]}
						name='looking_location'
						ref={register}
					/>
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

LookingTimeline.propTypes = propTypes;
export default StepContainer(LookingTimeline);
