import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import RentbackHouse from 'assets/images/RentbackHouse.svg';
import Button from 'components/buttons/Button';

import Preference from 'containers/Preference';
import Preapproval from 'containers/Preapproval';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';

const content = {
	en: {
		title: 'I want to',
		rentToOwn: 'Rent-to-Own',
		rentback: 'Rentback'
	},
	id: {
		title: 'Saya ingin',
		rentToOwn: 'Rent-to-Own',
		rentback: 'Rentback'
	}
};

const Begin = props => {
	const { state: { service, form }, setService } = Preapproval.useContainer();
	const [isUpdated, setIsUpdated] = useState(false);

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const {
		setAddressAutocompleteData,
		setAddressDetails,
		setIsParkingAvailable,
		setParkingCapacity,
		setProperty,
		setPropertyDetails,
		setIsOwner,
		setReferral,
		setProcess,
		setLooking,
		setLookingTimeline,
		setLookingLocation,
		setPropertyType,
		setIsPropertyComplete
	} = Preapproval.useContainer();

	const match = useRouteMatch();
	const history = useHistory();

	useDidUpdateEffect(() => {
		if (service === 'rentown') {
			history.push(`/preapproval/${match.params.identifier}/process`);
		} else if (service === 'rentback') {
			history.push(`/preapproval/${match.params.identifier}/search`);
		}
	}, [isUpdated]);

	useEffect(() => {
		if (form && form.id) return;
		setAddressAutocompleteData(null);
		setAddressDetails(null);
		setIsParkingAvailable(null);
		setAddressDetails(null);
		setIsParkingAvailable(null);
		setParkingCapacity(0, 0);
		setProperty(null);
		setPropertyDetails(null);
		setIsOwner(null);
		setReferral(null);
		setProcess(null);
		setLooking(null);
		setLookingTimeline(null);
		setLookingLocation(null);
		setPropertyType(null);
		setIsPropertyComplete(null);
		setService('');
	}, []);

	return (
		<div className="begin-preapproval base">
			<div className="base-container">
				<div className="begin-container">
					<div className="content">
						<img alt="" className="image" src={RentbackHouse} />
						<div className="title">{translation.title}</div>
						<div className="buttons">
							<Button
								onClick={() => {
									setService('rentown');
									setIsUpdated(true);
								}}
								variant="outlined"
							>
								{translation.rentToOwn}
							</Button>
							<Button
								onClick={() => {
									setService('rentback');
									setIsUpdated(true);
								}}
								variant="outlined"
							>
								{translation.rentback}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Begin;
