import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory, useRouteMatch } from 'react-router-dom';

import { getStepsOrder } from '../Templates/Template';
import Schemas from './Schemas';
import camelCase from 'lodash/camelCase';
import Toast from 'components/Toast';
import Preapproval from 'containers/Preapproval';

const propTypes = {
	width: PropTypes.number
};

export default StepComponent => {
	const StepContainer = props => {
		const { width, ...otherProps } = props;
		const [toastOffset, setToastOffset] = useState(0);
		const [isShowToast, setIsShowToast] = useState(false);
		const [toastMessage, setToastMessage] = useState('');
		const [toastVariant, setToastVariant] = useState('error');

		const { state } = Preapproval.useContainer();

		const stepsOrder = getStepsOrder(state);

		useEffect(() => {
			if (width > 1279) setToastOffset(72);
			else if (width > 767) setToastOffset(64);
			else setToastOffset(48);
		}, [width]);

		useEffect(() => {
			if (!isShowToast) return;
			setTimeout(() => {
				resetToast();
			}, 3000);
		}, [isShowToast]);

		const history = useHistory();
		const match = useRouteMatch();
		const currentStep = location.pathname.split('/').reverse()[0];
		const validationSchema = Schemas[camelCase(currentStep)];
		const nextStep = stepsOrder[stepsOrder.indexOf(currentStep) + 1];
		const prevStep = stepsOrder[stepsOrder.indexOf(currentStep) - 1];

		const showToast = (message, variant = 'error') => {
			setIsShowToast(true);
			setToastMessage(message);
			setToastVariant(variant);
		};

		const resetToast = () => {
			setIsShowToast(false);
		};

		const toNextPage = () => {
			history.push(`/preapproval/${match.params.identifier}/${nextStep}`);
		};

		const toPrevPage = () => {
			history.push(`/preapproval/${match.params.identifier}/${prevStep}`);
		};

		return (
			<div className="step-container base">
				<div className="content">
					<StepComponent
						showToast={showToast}
						toNextPage={toNextPage}
						toPrevPage={toPrevPage}
						validationSchema={validationSchema}
						width={width} {...otherProps}
					/>
				</div>
				<Toast
					isShow={isShowToast}
					message={toastMessage}
					offset={toastOffset}
					variant={toastVariant}
				/>
			</div>
		);
	};
	StepContainer.propTypes = propTypes;

	return StepContainer;
};
