import React, { forwardRef, useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
	label: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	error: PropTypes.bool,
	message: PropTypes.string,
	defaultValue: PropTypes.string,
	value: PropTypes.string,
	options: PropTypes.array
};

const SelectField = forwardRef((props, ref) => {
	const { label, disabled, error, message, options, defaultValue, onChange, value, ...otherProps } = props;

	const [isFocused, setIsFocused] = useState(false);
	const [isFilled, setIsFilled] = useState(false);

	useEffect(() => {
		if (defaultValue) {
			setIsFilled(true);
			onChange(defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (value) setIsFilled(true);
		else setIsFilled(false);
	}, [value]);

	const focusHandler = () => {
		setIsFocused(true);
	};

	const blurHandler = () => {
		setIsFocused(false);
	};

	const changeHandler = val => {
		if (onChange) onChange(val.value);
		if (val) setIsFilled(true);
		// else setIsFilled(false);
	};

	return (
		<div className={classNames('select-field', {
			disabled: disabled,
			error: error
		})}
		>
			<div className="select-field-container">
				<Select
					classNamePrefix="react-select"
					isDisabled={disabled}
					onBlur={blurHandler}
					onChange={changeHandler}
					onFocus={focusHandler}
					options={options}
					{...(value !== undefined ? { value: options.filter(option => option.value === value) } : {})}
					{...(defaultValue ? { defaultValue: options.filter(option => option.value === defaultValue) } : {})}
					{...(ref ? { ref: ref } : {})}
					{...otherProps}
				/>
				{label ? (
					<label
						className={classNames('form-input-label', {
							focus: isFocused && !disabled,
							shrink: isFilled || (isFocused && !disabled)
						})}
					>
						{label}
					</label>
				) : null}
			</div>
			{message ? (
				<label className="message-label">
					{message}
				</label>
			) : null}
		</div>
	);
});

SelectField.propTypes = propTypes;
SelectField.displayName = 'SelectField';

export default SelectField;
