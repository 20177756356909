import React, { useEffect } from 'react';
import { MAIN_WEBSITE_BASE_URL } from 'constants/Api';

const index = () => {
	useEffect(() => {
		window.location.replace(`${MAIN_WEBSITE_BASE_URL}/home`);
	});
	return (
		<div></div>
	);
};

export default index;
