import produce from 'immer';
import { joinFullName, parseCookies } from 'utils';
import Cookie from 'js-cookie';

import {
	USER_LOGIN_START,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGIN_RESET,
	OTP_VERIFY_START,
	OTP_VERIFY_SUCCESS,
	OTP_VERIFY_FAIL,
	OTP_VERIFY_RESET,
	USER_AUTH_START,
	USER_AUTH_SUCCESS,
	AUTH_RESET
} from './types';

export const initialAuthState = {
	userLogin: {
		user: null,
		loading: false,
		success: false,
		error: null
	},
	auth: {
		token: parseCookies().token,
		name: null,
		role: null,
		id: null,
		email: null,
		phone: null,
		verified: null,
		loading: null
	},
	newPasswordRequest: {
		success: false,
		loading: false,
		error: null
	},
	otpVerify: {
		success: false,
		loading: false,
		error: null
	}
};

const authReducers = (state, action) => {
	switch (action.type) {
	case USER_LOGIN_START:
		return produce(state, draft => {
			draft.userLogin.user = null;
			draft.userLogin.error = null;
			draft.userLogin.loading = true;
			draft.userLogin.success = false;
		});
	case USER_LOGIN_SUCCESS:
		const token = action.payload.token.split(' ')[1];
		Cookie.set('token', token, { expires: 1, sameSite: 'strict' });
		return produce(state, draft => {
			draft.userLogin.error = null;
			draft.userLogin.loading = false;
			draft.userLogin.success = true;
			draft.auth.token = token;
			draft.userLogin.user = action.payload.user;
			draft.auth.role = action.payload.user.role;
			draft.auth.id = action.payload.user.id;
			draft.auth.email = action.payload.user.email;
			draft.auth.name = !action.payload.user.firstName
				? null
				: !action.payload.user.lastName
					? action.payload.user.firstName
					: action.payload.user.firstName + ' ' + action.payload.user.lastName;
		});
	case USER_LOGIN_FAIL:
		return produce(state, draft => {
			draft.userLogin.error = action.payload;
			draft.userLogin.loading = false;
			draft.userLogin.success = false;
		});
	case USER_LOGIN_RESET:
		return produce(state, draft => {
			draft.userLogin.error = null;
			draft.userLogin.loading = false;
			draft.userLogin.success = false;
		});
	case OTP_VERIFY_START:
		return produce(state, draft => {
			draft.otpVerify.error = null;
			draft.otpVerify.loading = true;
			draft.otpVerify.success = false;
		});
	case OTP_VERIFY_SUCCESS:
		return produce(state, draft => {
			draft.otpVerify.error = null;
			draft.otpVerify.loading = false;
			draft.otpVerify.success = true;
			draft.auth.verified = true;
		});
	case OTP_VERIFY_FAIL:
		return produce(state, draft => {
			draft.otpVerify.error = action.payload;
			draft.otpVerify.loading = false;
			draft.otpVerify.success = false;
		});
	case OTP_VERIFY_RESET:
		return produce(state, draft => {
			draft.otpVerify.error = null;
			draft.otpVerify.loading = false;
			draft.otpVerify.success = false;
		});
	case USER_AUTH_START:
		return produce(state, draft => {
			draft.auth.loading = true;
		});
	case USER_AUTH_SUCCESS:
		return produce(state, draft => {
			draft.auth.role = action.payload.user.role;
			draft.auth.id = action.payload.user.id;
			draft.auth.email = action.payload.user.email;
			draft.auth.phone = action.payload.user.phone;
			draft.auth.name = joinFullName(action.payload.user?.firstName, action.payload.user?.lastName);
			draft.auth.verified = action.payload.user.verified;
			draft.auth.loading = false;
		});
	case AUTH_RESET:
		Cookie.remove('token', { path: '/' });
		return produce(state, draft => {
			draft.auth.role = null;
			draft.auth.token = null;
			draft.auth.id = null;
			draft.auth.email = null;
			draft.auth.name = null;
			draft.auth.phone = null,
			draft.auth.verified = null;
			draft.auth.loading = false;
		});
	default:
		return state;
	}
};

export default authReducers;
