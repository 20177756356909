import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string
};

const Checkbox = forwardRef((props, ref) => {
	const { id, label, ...otherProps } = props;
	return (
		<div className="checkbox">
			<input id={id} type="checkbox" {...otherProps} ref={ref} />
			<label className="checkmark" htmlFor={id}></label>
			<label className="checkbox-label" htmlFor={id}>{label}</label>
		</div>
	);
});

Checkbox.propTypes = propTypes;
Checkbox.displayName = 'Checkbox';
export default Checkbox;
