export const USER_LOGIN_START = 'auth/USER_LOGIN_START';
export const USER_LOGIN_SUCCESS = 'auth/USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'auth/USER_LOGIN_FAIL';
export const USER_LOGIN_RESET = 'auth/USER_LOGIN_RESET';

export const OTP_VERIFY_START = 'auth/OTP_VERIFY_START';
export const OTP_VERIFY_SUCCESS = 'auth/OTP_VERIFY_SUCCESS';
export const OTP_VERIFY_FAIL = 'auth/OTP_VERIFY_FAIL';
export const OTP_VERIFY_RESET = 'auth/OTP_VERIFY_RESET';

export const USER_AUTH_START = 'auth/USER_AUTH_START';
export const USER_AUTH_SUCCESS = 'auth/USER_AUTH_SUCCESS';
export const AUTH_RESET = 'auth/AUTH_RESET';
