import { useReducer } from 'react';
import { createContainer } from 'unstated-next';
import authReducers, { initialAuthState } from './reducers';
import authActions from './actions';

const useAuth = () => {
	const [state, dispatch] = useReducer(authReducers, initialAuthState);

	const signupUserPreapproval = async (email, password, preapproval) => {
		await authActions.signupUserPreapproval(dispatch, { email, password, preapproval });
	};

	const signupGooglePreapproval = async (idToken, preapproval) => {
		await authActions.signupGooglePreapproval(dispatch, { idToken, preapproval });
	};

	const resetUserLogin = () => {
		authActions.resetUserLogin(dispatch);
	};

	const verifyOtp = (email, otp) => {
		authActions.verifyOtp(dispatch, { email, otp });
	};

	const resetOtpVerify = () => {
		authActions.resetOtpVerify(dispatch);
	};

	const authUser = token => {
		authActions.authUser(dispatch, token);
	};

	const resetAuth = () => {
		authActions.resetAuth(dispatch);
	};

	const getOtp = token => {
		authActions.getOtp(token);
	};

	return {
		state,
		signupUserPreapproval,
		signupGooglePreapproval,
		resetUserLogin,
		verifyOtp,
		resetOtpVerify,
		authUser,
		resetAuth,
		getOtp
	};
};

export default createContainer(useAuth);
