import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SideBar from 'components/SideBar';
import AppBar from 'components/AppBar';
import AuthContainer from 'containers/Auth';

const propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};

const Dashboard = props => {
	const { children, className } = props;

	const {
		state: { auth },
		authUser
	} = AuthContainer.useContainer();

	useEffect(() => {
		if (auth.id) return;
		authUser();
	}, []);

	return (
		<div className={`dashboard ${className && className}`}>
			<div className="nav-section">
				<AppBar menu="dashboard" />
				<SideBar />
			</div>
			<div className="main-section">{children}</div>
		</div>
	);
};

Dashboard.propTypes = propTypes;

export default Dashboard;
