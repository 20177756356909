import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Menu from 'assets/images/Menu.svg';
import LogoWhite from 'assets/images/LogoWhite.svg';
import Preference from 'containers/Preference';
import SideMenu from 'components/SideMenu';
import LanguageSwitcher from 'components/LanguageSwitcher';

const propTypes = {
	menu: PropTypes.string.isRequired
};

const MobileMenu = props => {
	const [left, setLeft] = useState(false);
	const { menu } = props;

	const {
		state: { languageCode }
	} = Preference.useContainer();

	const toggleDrawer = open => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setLeft(open);
	};

	return (
		<div className="mobile-menu">
			<img alt="Menu" className="mobile-menu-icon" onClick={toggleDrawer(true)} src={Menu} />
			<Drawer
				anchor="left"
				className="drawer-menu"
				onClose={toggleDrawer(false)}
				open={left}
				variant="temporary">
				<div>
					<img
						alt="Logo-White"
						className="logo-white"
						onClick={() => {
							window.location.href='/home';
						}}
						src={LogoWhite}
					/>
					<div className="mobile-menu-wrapper">
						<SideMenu language={languageCode} menu={menu} />
						<div className="switch-wrapper">
							<LanguageSwitcher />
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	);
};

MobileMenu.propTypes = propTypes;

export default MobileMenu;
