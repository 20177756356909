import PropTypes from 'prop-types';
import React from 'react';
import Auth from 'containers/Auth';
import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';
import Task from 'containers/Task';

const propTypes = {
	children: PropTypes.object.isRequired
};

const Store = ({ children }) => {
	return (
		<Preference.Provider>
			<Task.Provider>
				<Preapproval.Provider>
					<Auth.Provider>
						{children}
					</Auth.Provider>
				</Preapproval.Provider>
			</Task.Provider>
		</Preference.Provider>
	);
};

Store.propTypes = propTypes;

export default Store;
