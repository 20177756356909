import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';

import Button from 'components/buttons/Button';
import RadioSwitch from 'components/CustomInputs/RadioSwitch';
import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'Do you own this property?',
		yesLabel: 'Yes',
		noLabel: 'No',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Apakah Anda pemilik properti ini?',
		yesLabel: 'Ya',
		noLabel: 'Tidak',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Owner = props => {
	const { toNextPage, toPrevPage } = props;
	const { register, handleSubmit, setValue } = useForm();
	const { state: { isOwner }, setIsOwner } = Preapproval.useContainer();

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	useEffect(() => {
		if (isOwner) setValue('is_owner', isOwner);
		else setValue('is_owner', 'no');
	}, []);

	const onSubmit = data => {
		setIsOwner(data.is_owner);
		toNextPage();
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="owner-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<RadioSwitch
					name="is_owner"
					options={[{ value: 'no', label: translation.noLabel }, { value: 'yes', label: translation.yesLabel }]}
					ref={register}
				/>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

Owner.propTypes = propTypes;
export default StepContainer(Owner);
