export const TASKS_SAVE_START = 'task/TASKS_SAVE_START';
export const TASKS_SAVE_SUCCESS = 'task/TASKS_SAVE_SUCCESS';
export const TASKS_SAVE_FAIL = 'task/TASKS_SAVE_FAIL';
export const TASKS_SAVE_RESET = 'task/TASKS_SAVE_RESET';

export const PERSONAL_DOCUMENTS_LOAD_START = 'task/PERSONAL_DOCUMENTS_LOAD_START';
export const PERSONAL_DOCUMENTS_LOAD_SUCCESS = 'task/PERSONAL_DOCUMENTS_LOAD_SUCCESS';
export const PERSONAL_DOCUMENTS_LOAD_FAIL = 'task/PERSONAL_DOCUMENTS_LOAD_FAIL';
export const PERSONAL_DOCUMENTS_LOAD_RESET = 'task/PERSONAL_DOCUMENTS_LOAD_RESET';

export const PROPERTY_IMAGES_SAVE_START = 'task/PROPERTY_IMAGES_SAVE_START';
export const PROPERTY_IMAGES_SAVE_SUCCESS = 'task/PROPERTY_IMAGES_SAVE_SUCCESS';
export const PROPERTY_IMAGES_SAVE_FAIL = 'task/PROPERTY_IMAGES_SAVE_FAIL';
export const PROPERTY_IMAGES_SAVE_RESET = 'task/PROPERTY_IMAGES_SAVE_RESET';

export const PROPERTY_IMAGES_LOAD_START = 'task/PROPERTY_IMAGES_LOAD_START';
export const PROPERTY_IMAGES_LOAD_SUCCESS = 'task/PROPERTY_IMAGES_LOAD_SUCCESS';
export const PROPERTY_IMAGES_LOAD_FAIL = 'task/PROPERTY_IMAGES_LOAD_FAIL';
export const PROPERTY_IMAGES_LOAD_RESET = 'task/PROPERTY_IMAGES_LOAD_RESET';

export const TASK_RESET = 'task/TASK_RESET';
