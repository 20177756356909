import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Preapproval from 'containers/Preapproval';
import GrayCross from 'assets/images/GrayCross.svg';

import StepContainer from './StepContainer';
import TextField from 'components/CustomInputs/TextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/buttons/Button';
import SelectField from 'components/CustomInputs/SelectField';
import axios from 'axios';
import { APIV2 } from 'constants/Api';
import Modal from 'react-modal';

import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';
import { validateEmail } from 'utils';
import { transliterate as tr } from 'transliteration';

const availableCities = [
	'Kota Jakarta Utara',
	'Kota Jakarta Selatan',
	'Kota Jakarta Timur',
	'Kota Jakarta Barat',
	'Kota Jakarta Pusat',
	'Kota Bogor',
	'Kabupaten Bogor',
	'Kota Depok',
	'Kota Tangerang',
	'Kabupaten Tangerang',
	'Kota Tangerang Selatan',
	'Kota Bekasi',
	'Kabupaten Bekasi'
];

const content = {
	en: {
		title: 'Please confirm your address',
		addressFieldLabel: 'Address',
		buildingNameFieldLabel: 'Cluster / Residence / Building Name',
		provinceFieldLabel: 'Province',
		cityFieldLabel: 'Regency or City',
		districtFieldLabel: 'District',
		unitNumberLabel: 'Unit Number',
		postalCodeLabel: 'Postal Code',
		previous: 'Previous',
		next: 'Next',
		sellAndStayNotAvailableTitle: 'This program is not available in your area yet',
		sellAndStayNotAvailableSubTitle: 'Be the first one to hear about the updated expansion of TapHomes!',
		send: 'Send',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Silakan periksa kembali alamat Anda',
		addressFieldLabel: 'Alamat',
		buildingNameFieldLabel: 'Klaster / Perumahan / Komplek',
		provinceFieldLabel: 'Provinsi',
		cityFieldLabel: 'Kabupaten atau Kota',
		districtFieldLabel: 'Kecamatan',
		unitNumberLabel: 'Nomor Unit',
		postalCodeLabel: 'Kode Pos',
		previous: 'Kembali',
		next: 'Lanjut',
		sellAndStayNotAvailableTitle: 'Program ini belum tersedia di area Anda',
		sellAndStayNotAvailableSubTitle: 'Jadilah orang pertama yang tahu kehadiran TapHomes di kota Anda!',
		send: 'Kirim',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object,
	width: PropTypes.number
};

const Address = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const [provinceOptions, setProvinceOptions] = useState([]);
	const [cityOptions, setCityOptions] = useState([]);
	const [districtOptions, setDistrictOptions] = useState([]);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [userEmail, setUserEmail] = useState('');

	const {
		state,
		requestCity,
		resetCityRequest,
		setAddressDetails
	} = Preapproval.useContainer();

	const { register, errors, handleSubmit, setValue, clearErrors, watch } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const provinceIdWatch = watch('province_id');
	const cityIdWatch = watch('city_id');
	const cityLabelWatch = watch('city_label');
	const districtIdWatch = watch('district_id');
	const addressWatch = watch('address');

	const parseField = (data, typeName) => {
		return data?.address_components?.find(element => {
			if (element.types.includes(typeName)) return true;
			else return false;
		})?.long_name || '';
	};

	const onSubmit = data => {
		if (!availableCities.includes(cityLabelWatch)) {
			setIsOpenModal(true);
			return;
		}

		setAddressDetails({
			...data,
			province: provinceOptions.find(provinceTemp => provinceTemp.id == data.province_id).label,
			city: cityOptions.find(cityTemp => cityTemp.id == data.city_id).label,
			district: districtOptions.find(districtTemp => districtTemp.id == data.district_id).label
		});

		toNextPage();
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const closeModal = () => {
		if (!state.cityRequest.loading) {
			setIsOpenModal(false);
		}
	};

	const submitRequestCity = () => {
		const city = cityOptions.find(option => cityIdWatch == option.id).label;
		requestCity(userEmail, city, addressWatch);
	};

	useEffect(() => {
		if (state.cityRequest.error) {
			closeModal();
			resetCityRequest();
			showToast(translation.somethingWentWrong);
		} else if (state.cityRequest.success) {
			const redirect = {
				rentown: '/rent-to-own?success=true',
				rentback: '/rentback?success=true'
			};
			resetCityRequest();
			window.location.href = redirect[state.service];
		}
	}, [state.cityRequest]);

	useEffect(() => {
		const { addressDetails } = state;

		register({ name: 'province_id' });
		register({ name: 'city_id' });
		register({ name: 'district_id' });
		register({ name: 'city_label' });

		if (addressDetails) {
			setValue('province_id', addressDetails.province_id);
			setValue('city_id', addressDetails.city_id);
			setValue('district_id', addressDetails.district_id);
		}

		const getProvinces = async () => {
			const { data: { regions } } = await axios.get(`${APIV2}/regions`);
			const provincesTemp = regions.map(region => {
				return {
					id: region.id,
					value: `${region.id}`,
					label: region.name
				};
			});
			setProvinceOptions(provincesTemp);
		};

		getProvinces();
	}, []);

	useEffect(() => {
		const { addressDetails, addressAutocompleteData } = state;

		if (!addressDetails && provinceOptions.length) {
			const provinceFromGooglePlace = parseField(addressAutocompleteData, 'administrative_area_level_1');
			const provinceId = provinceOptions.find(region => region.label === provinceFromGooglePlace)?.id;
			if (provinceId) setValue('province_id', `${provinceId}`);
			else setValue('province_id', '');
		}
	}, [provinceOptions]);

	useEffect(() => {
		const getCities = async () => {
			if (!provinceIdWatch) return;
			const { data: { cities } } = await axios.get(`${APIV2}/regions/${provinceIdWatch}/cities`);
			const citiesTemp = cities.map(city => {
				return {
					id: city.id,
					value: `${city.id}`,
					label: city.name
				};
			});
			setCityOptions(citiesTemp);
		};

		getCities();
	}, [provinceIdWatch]);

	useEffect(() => {
		const { addressDetails, addressAutocompleteData } = state;

		if (!cityOptions.length) return;

		let cityId, cityLabel;

		if (!addressDetails) {
			let cityFromGooglePlace = parseField(addressAutocompleteData, 'administrative_area_level_2');
			if (cityFromGooglePlace && !cityFromGooglePlace.toLowerCase().includes('kota')) {
				cityFromGooglePlace = 'Kabupaten ' + cityFromGooglePlace;
			}

			const city = cityOptions.find(city => city.label === cityFromGooglePlace);

			if (city) {
				cityLabel = city.label;
				cityId = city.id;
			}
		} else {
			const city = cityOptions.find(city => city.id == cityIdWatch);

			if (city) {
				cityLabel = city.label;
				cityId = city.id;
			}
		}

		if (cityId) {
			setValue('city_id', `${cityId}`);
			setValue('city_label', cityLabel);
		} else {
			setValue('city_id', '');
			setValue('district_id', '');
		};
	}, [cityOptions]);

	useEffect(() => {
		const getDistricts = async () => {
			if (!cityIdWatch) return;
			const { data: { districts } } = await axios.get(`${APIV2}/cities/${cityIdWatch}/districts`);
			const districtsTemp = districts.map(district => {
				return {
					id: district.id,
					value: `${district.id}`,
					label: district.name
				};
			});
			setDistrictOptions(districtsTemp);
		};

		getDistricts();
	}, [cityIdWatch]);

	useEffect(() => {
		const { addressDetails, addressAutocompleteData } = state;

		if (!districtOptions.length) return;

		let districtId;

		if (!addressDetails) {
			const districtFromGooglePlace = parseField(addressAutocompleteData, 'administrative_area_level_3')?.replace('Kecamatan ', '');
			districtId = districtOptions.find(district => district.label === districtFromGooglePlace)?.id;
		} else {
			districtId = districtOptions.find(district => district.id == districtIdWatch)?.id;
		}

		if (districtId) setValue('district_id', `${districtId}`);
		else setValue('district_id', '');
	}, [districtOptions]);

	useEffect(() => {
		if (!cityIdWatch) return;
		const city = cityOptions.find(city => city.id == cityIdWatch);
		if (!city) return;

		setValue('city_label', city.label);
	}, [cityIdWatch]);

	const addressField = () => {
		const { addressDetails, addressAutocompleteData } = state;

		let address = tr(
				parseField(
					addressAutocompleteData, 'route') + (parseField(addressAutocompleteData, 'street_number')
					? ` ${parseField(addressAutocompleteData, 'street_number')?.includes('No.') ? '' : 'No.'}${parseField(addressAutocompleteData, 'street_number')}`
					: ''
				)
			),
			postalCode = parseField(addressAutocompleteData, 'postal_code'),
			buildingName = addressAutocompleteData?.description || '',
			unitNumber = '';
		if (addressDetails) {
			address = addressDetails.address;
			postalCode = addressDetails.postal_code;
			buildingName = addressDetails.building_name;
			unitNumber = addressDetails.unit_number;
		}

		return (
			<div className="form-container">
				<div className="input-container">
					<TextField
						defaultValue={address}
						error={errors.address ? true : false}
						label={translation.addressFieldLabel}
						message={validationTranslation[errors.address?.message]}
						name='address'
						ref={register}
					/>
				</div>
				<div className="input-container">
					<TextField
						defaultValue={buildingName}
						error={errors.building_name ? true : false}
						label={translation.buildingNameFieldLabel}
						message={validationTranslation[errors.building_name?.message]}
						name='building_name'
						ref={register}
					/>
				</div>
				<div className="input-container">
					<SelectField
						error={errors.province_id ? true : false}
						label={translation.provinceFieldLabel}
						message={validationTranslation[errors.province_id?.message]}
						onChange={val => {
							setValue('province_id', val);
							if (val) clearErrors('province_id');
						}}
						options={provinceOptions}
						value={provinceIdWatch}
					/>
				</div>
				<div className="city-district-container">
					<div className="input-container">
						<SelectField
							disabled={!provinceIdWatch}
							error={errors.city_id ? true : false}
							label={translation.cityFieldLabel}
							message={validationTranslation[errors.city_id?.message]}
							onChange={val => {
								setValue('city_id', val);
								if (val) clearErrors('city_id');
							}}
							options={cityOptions}
							value={cityIdWatch}
						/>
					</div>
					<div className="input-container">
						<SelectField
							disabled={!provinceIdWatch || !cityIdWatch}
							error={errors.district_id ? true : false}
							label={translation.districtFieldLabel}
							message={validationTranslation[errors.district_id?.message]}
							onChange={val => {
								setValue('district_id', val);
								if (val) clearErrors('district_id');
							}}
							options={districtOptions}
							value={districtIdWatch}
						/>
					</div>
				</div>
				<div className="input-container col-2">
					<TextField
						defaultValue={unitNumber}
						label={translation.unitNumberLabel}
						name='unit_number'
						ref={register}
					/>
					<TextField
						defaultValue={postalCode}
						error={errors.postal_code ? true : false}
						label={translation.postalCodeLabel}
						message={validationTranslation[errors.postal_code?.message]}
						name='postal_code'
						ref={register}
						type="number"
					/>
				</div>
			</div>
		);
	};

	return (
		<div className="address-preapproval" id="address-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				{addressField()}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
			<Modal
				className="modal-content"
				contentLabel="Forgot Password Modal"
				isOpen={isOpenModal}
				onRequestClose={closeModal}
				overlayClassName="modal-overlay"
			>
				<div className="modal-form-container">
					<img className="modal-exit-button" onClick={closeModal} src={GrayCross} />
					<div className="modal-title">{translation.sellAndStayNotAvailableTitle}</div>
					<div className="modal-subtitle">{translation.sellAndStayNotAvailableSubTitle}</div>
					<div className="modal-input-container">
						<TextField
							error={userEmail && !validateEmail(userEmail) ? true : false}
							label='Email'
							name='email'
							onChange={e => setUserEmail(e.target.value)}
							value={userEmail}
						/>
					</div>
					<Button
						className="modal-submit-button"
						disabled={!userEmail || !validateEmail(userEmail) || state.cityRequest.loading}
						onClick={submitRequestCity}
						variant="contained"
					>
						{translation.send}
					</Button>
				</div>
			</Modal>
		</div>
	);
};

Address.propTypes = propTypes;

export default StepContainer(Address);
