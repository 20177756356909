export const IDENTIFIER_SET = 'preapproval/IDENTIFIER_SET';
export const SERVICE_SET = 'preapproval/SERVICE_SET';
export const ADDRESS_AUTOCOMPLETE_SET = 'preapproval/ADDRESS_AUTOCOMPLETE_SET';
export const ADDRESS_DETAILS_SET = 'preapproval/ADDRESS_DETAILS_SET';
export const IS_PARKING_AVAILABLE_SET= 'preapproval/IS_PARKING_AVAILABLE_SET';
export const PARKING_CAPACITY_SET = 'preapproval/PARKING_CAPACITY_SET';
export const PROPERTY_SET = 'preapproval/PROPERTY_SET';
export const PROPERTY_DETAILS_SET = 'preapproval/PROPERTY_DETAILS_SET';
export const IS_OWNER_SET = 'preapproval/IS_OWNER_SET';
export const REFERRAL_SET = 'preapproval/REFERRAL_SET';

export const CITY_REQUEST_START = 'preapproval/CITY_REQUEST_START';
export const CITY_REQUEST_SUCCESS = 'preapproval/CITY_REQUEST_SUCCESS';
export const CITY_REQUEST_FAIL = 'preapproval/CITY_REQUEST_FAIL';
export const CITY_REQUEST_RESET = 'preapproval/CITY_REQUEST_RESET';

export const LATEST_FORM_DATA_LOAD_START = 'preapproval/LATEST_FORM_DATA_LOAD_START';
export const LATEST_FORM_DATA_LOAD_SUCCESS = 'preapproval/LATEST_FORM_DATA_LOAD_SUCCESS';
export const LATEST_FORM_DATA_LOAD_FAIL = 'preapproval/LATEST_FORM_DATA_LOAD_FAIL';
export const LATEST_FORM_DATA_LOAD_RESET = 'preapproval/LATEST_FORM_DATA_LOAD_RESET';

export const USER_PROFILE_UPDATE_START = 'preapproval/USER_PROFILE_UPDATE_START';
export const USER_PROFILE_UPDATE_SUCCESS = 'preapproval/USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAIL = 'preapproval/USER_PROFILE_UPDATE_FAIL';
export const USER_PROFILE_UPDATE_RESET = 'preapproval/USER_PROFILE_UPDATE_RESET';

export const FIELDS_UPSERT_START = 'preapproval/FIELDS_UPSERT_START';
export const FIELDS_UPSERT_SUCCESS = 'preapproval/FIELDS_UPSERT_SUCCESS';
export const FIELDS_UPSERT_FAIL = 'preapproval/FIELDS_UPSERT_FAIL';
export const FIELDS_UPSERT_RESET = 'preapproval/FIELDS_UPSERT_RESET';

export const PROPERTIES_LOAD_START = 'preapproval/PROPERTIES_LOAD_START';
export const PROPERTIES_LOAD_SUCCESS = 'preapproval/PROPERTIES_LOAD_SUCCESS';
export const PROPERTIES_LOAD_FAIL = 'preapproval/PROPERTIES_LOAD_FAIL';
export const PROPERTIES_LOAD_RESET = 'preapproval/PROPERTIES_LOAD_RESET';

export const FORM_SUBMIT_START = 'preapproval/FORM_SUBMIT_START';
export const FORM_SUBMIT_SUCCESS = 'preapproval/FORM_SUBMIT_SUCCESS';
export const FORM_SUBMIT_FAIL = 'preapproval/FORM_SUBMIT_FAIL';
export const FORM_SUBMIT_RESET = 'preapproval/FORM_SUBMIT_RESET';

export const RENT_TO_OWN_REQUEST_START = 'preapproval/RENT_TO_OWN_REQUEST_START';
export const RENT_TO_OWN_REQUEST_SUCCESS = 'preapproval/RENT_TO_OWN_REQUEST_SUCCESS';
export const RENT_TO_OWN_REQUEST_FAIL = 'preapproval/RENT_TO_OWN_REQUEST_FAIL';
export const RENT_TO_OWN_REQUEST_RESET = 'preapproval/RENT_TO_OWN_REQUEST_RESET';

export const FORM_STATUS_UPDATE_START = 'preapproval/FORM_STATUS_UPDATE_START';
export const FORM_STATUS_UPDATE_SUCCESS = 'preapproval/FORM_STATUS_UPDATE_SUCCESS';
export const FORM_STATUS_UPDATE_FAIL = 'preapproval/FORM_STATUS_UPDATE_FAIL';
export const FORM_STATUS_UPDATE_RESET = 'preapproval/FORM_STATUS_UPDATE_RESET';

export const PROCESS_SET = 'preapproval/PROCESS_SET';
export const LOOKING_SET = 'preapproval/LOOKING_SET';
export const LOOKING_TIMELINE_SET = 'preapproval/LOOKING_TIMELINE_SET';
export const LOOKING_LOCATION_SET = 'preapproval/LOOKING_LOCATION_SET';
export const PROPERTY_TYPE_SET = 'preapproval/PROPERTY_TYPE_SET';
export const IS_PROPERTY_COMPLETE_SET = 'preapproval/IS_PROPERTY_COMPLETE_SET';

export const PREAPPROVAL_RESET = 'preapproval/PREAPPROVAL_RESET';
