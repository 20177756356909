import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import PriceField from 'components/CustomInputs/PriceField';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';
import { CircularProgress } from '@material-ui/core';
import { findFieldByFieldKey } from 'utils';
import Preapproval from 'containers/Preapproval';

const content = {
	en: {
		title: 'How much monthly payment can you afford?',
		monthlyInfo: 'Minimum monthly payment is Rp1.200.000,-.',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Berapa nilai angsuran bulanan yang sanggup Anda bayar?',
		monthlyInfo: 'Angsuran bulanan min. Rp1.200.000,-.',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const MonthlyPayment = props => {
	const { toNextPage, toPrevPage, validationSchema, showToast } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const {
		state: {
			form, fields, fieldsUpsert
		},
		upsertFields,
		resetFieldsUpsert
	} = Preapproval.useContainer();

	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const monthlyPayment = watch('monthly_payment');

	useEffect(() => {
		const monthlyPayment = findFieldByFieldKey(fields, 'monthlyPayment')?.fieldValue;
		register({ name: 'monthly_payment' });
		setValue('monthly_payment', monthlyPayment);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [{
			fieldKey: 'monthlyPayment',
			fieldValue: String(data.monthly_payment)
		}]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="monthly-payment-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<PriceField
					decimalSeparator={false}
					error={errors.monthly_payment ? true : false}
					message={validationTranslation[errors.monthly_payment?.message] || translation.monthlyInfo}
					onValueChange={value => {
						setValue('monthly_payment', value.value);
						if (value.value) clearErrors('monthly_payment');
						else setValue('monthly_payment', undefined);
					}}
					thousandSeparator={'.'}
					value={monthlyPayment}
				/>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || !monthlyPayment || monthlyPayment < 1200000}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

MonthlyPayment.propTypes = propTypes;
export default StepContainer(MonthlyPayment);
