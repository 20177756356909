import Preference from 'containers/Preference';
import React from 'react';
import classNames from 'classnames';

const LanguageSwitcher = () => {
	const {
		state: { languageCode },
		setLanguage
	} = Preference.useContainer();

	const onChangeHandler = e => {
		setLanguage(e);
	};

	return (
		<div className="language-switcher">
			<div className="language-switcher-container">
				<label
					className={classNames({ active: languageCode === 'en' })}
					onClick={() => onChangeHandler('en')}>
					EN
				</label>
			</div>
			<div className="language-switcher-container">
				<label
					className={classNames({ active: languageCode === 'id' })}
					onClick={() => onChangeHandler('id')}>
					ID
				</label>
			</div>
		</div>
	);
};

export default LanguageSwitcher;
