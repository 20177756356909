import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import Routes from './router';
import Store from './store';

import './styles/index.css';

Modal.setAppElement(document.getElementById('root'));
ReactDOM.render(
	<div className="app">
		<Store>
			<Routes />
		</Store>
	</div>,
	document.getElementById('root')
);
