import Begin from './Begin';
import Search from './Search';
import Address from './Address';
import Property from './Property';
import PropertyDetails from './PropertyDetails';
import Parking from './Parking';
import ParkingDetails from './ParkingDetails';
import Owner from './Owner';
import Referral from './Referral';
import Signup from './Signup';
import Contact from './Contact';
import SlikStatus from './SlikStatus';
import Income from './Income';
import Monthly from './Monthly';
import Reason from './Reason';
import Cash from './Cash';
import Financial from './Financial';
import Timeline from './Timeline';
import Estimate from './Estimate';
import Mortgage from './Mortgage';
import Result from './Result';
import ResultReason from './ResultReason';
import Listing from './Listing';
import Purchase from './Purchase';
import Price from './Price';
import Buyback from './Buyback';
import Process from './Process';
import Looking from './Looking';
import LookingTimeline from './LookingTimeline';
import LookingLocation from './LookingLocation';
import PropertyType from './PropertyType';
import PropertyComplete from './PropertyComplete';
import Marital from './Marital';
import Employment from './Employment';
import EmploymentDetails from './EmploymentDetails';
import MonthlyIncome from './MonthlyIncome';
import Spend from './Spend';
import DownPayment from './DownPayment';
import MonthlyPayment from './MonthlyPayment';
import CurrentLiving from './CurrentLiving';
import PropertyDescribe from './PropertyDescribe';
import ParkingDescribe from './ParkingDescribe';

export default {
	Begin,
	Search,
	Address,
	Property,
	PropertyDetails,
	Parking,
	ParkingDetails,
	Owner,
	Referral,
	Signup,
	Contact,
	SlikStatus,
	Income,
	Monthly,
	Reason,
	Cash,
	Financial,
	Timeline,
	Estimate,
	Mortgage,
	Result,
	ResultReason,
	Listing,
	Purchase,
	Price,
	Buyback,
	Process,
	Looking,
	LookingTimeline,
	LookingLocation,
	PropertyType,
	PropertyComplete,
	Marital,
	Employment,
	EmploymentDetails,
	MonthlyIncome,
	Spend,
	DownPayment,
	MonthlyPayment,
	CurrentLiving,
	PropertyDescribe,
	ParkingDescribe
};
