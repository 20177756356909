import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
	error: PropTypes.bool,
	message: PropTypes.string
};

const TextareaCustom = forwardRef((props, ref) => {
	const { error, message, ...otherProps } = props;
	return (
		<div className={classNames('textarea-custom', { error: error })} >
			<textarea
				{...otherProps}
				ref={ref}
			>
			</textarea>
			{message && (
				<label className="message">{message}</label>
			)}
		</div>
	);
});

TextareaCustom.propTypes = propTypes;
TextareaCustom.displayName = 'TextareaCustom';

export default TextareaCustom;
