import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/buttons/Button';
import classNames from 'classnames';
import { ReactComponent as PlusThick } from 'assets/images/PlusThick.svg';
import { ReactComponent as MinusThick } from 'assets/images/MinusThick.svg';

const propTypes = {
	title: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string
	]),
	label: PropTypes.string,
	capacity: PropTypes.number,
	itemName: PropTypes.string,
	onChangeCapacity: PropTypes.func,
	onChangeCheckbox: PropTypes.func,
	checked: PropTypes.bool,
	id: PropTypes.string,
	image: PropTypes.string
};

const SimpleParkingTypeItem = props => {
	const {
		title,
		label,
		capacity,
		itemName,
		onChangeCapacity,
		id,
		checked,
		onChangeCheckbox,
		image
	} = props;

	const onButtonClick = (value => {
		onChangeCapacity(value);
	});

	const onCheckboxClick = (e => {
		if (onChangeCheckbox) onChangeCheckbox(!checked);
	});

	return (
		<div className="simple-parking-type-item">
			<input checked={checked} id={id} onChange={onCheckboxClick} type="checkbox" />
			<label className="checkmark" htmlFor={id}></label>
			<div className="details-container">
				<img alt="park image" className="image" src={image} />
				<div className="details">
					<div className="parking-type-title">{title}</div>
					<div className="parking-type-sub-title">{label}</div>
					<div className="buttons-container">
						<Button className="button" disabled={!checked || capacity === 1} onClick={() => onButtonClick(capacity - 1)} variant="outlined">
							<MinusThick className={classNames('button-icon', { checked: checked })} />
						</Button>
						<div className={classNames('capacity', { disabled: !checked })}>
							{capacity} {itemName}
						</div>
						<Button className="button" disabled={!checked} onClick={() => onButtonClick(capacity + 1)} variant="outlined">
							<PlusThick className={classNames('button-icon', { checked: checked })} />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

SimpleParkingTypeItem.propTypes = propTypes;

export default SimpleParkingTypeItem;
