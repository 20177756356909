import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import SelectField from 'components/CustomInputs/SelectField';
import Preapproval from 'containers/Preapproval';
import { CircularProgress } from '@material-ui/core';
import { findFieldByFieldKey } from 'utils';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'How is your credit collectability status based on your SLIK OJK?',
		statusLabel: 'Status',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Bagaimana status kolektibilitas SLIK OJK kamu?',
		statusLabel: 'Status',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silahkan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const slikOptions = {
	en: [
		{ value: 'collectibility 1', label: 'Collectibility 1 (Good)' },
		{ value: 'collectibility 2', label: 'Collectibility 2 (Under scrutiny)' },
		{ value: 'collectibility 3', label: 'Collectibility 3 (Not good)' },
		{ value: 'collectibility 4', label: 'Collectibility 4 (Poor)' },
		{ value: 'collectibility 5', label: 'Collectibility 5 (Very poor)' },
		{ value: 'unknown', label: `I don't know` }
	],
	id: [
		{ value: 'collectibility 1', label: 'Kolektibilitas 1 (Lancar)' },
		{ value: 'collectibility 2', label: 'Kolektibilitas 2 (Dalam perhatian khusus)' },
		{ value: 'collectibility 3', label: 'Kolektibilitas 3 (Kurang lancar)' },
		{ value: 'collectibility 4', label: 'Kolektibilitas 4 (Diragukan)' },
		{ value: 'collectibility 5', label: 'Kolektibilitas 5 (Macet)' },
		{ value: 'unknown', label: `Tidak tahu` }
	]
};

const SlikStatus = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;
	const slikOptionsTranslation = languageCode === 'en' ? slikOptions.en : slikOptions.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const slikStatus = watch('slik_status');

	useEffect(() => {
		const slikOjkStatus = findFieldByFieldKey(fields, 'slikOjkStatus')?.fieldValue;

		register({ name: 'slik_status' });

		if (slikOjkStatus) {
			setValue('slik_status', slikOjkStatus);
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'slikOjkStatus',
				fieldValue: data.slik_status
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="purchase-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<SelectField
						error={errors.slik_status ? true : false}
						label={translation.statusLabel}
						message={validationTranslation[errors.slik_status?.message]}
						onChange={val => {
							setValue('slik_status', val);
							if (val) clearErrors('slik_status');
						}}
						options={slikOptionsTranslation}
						value={slikStatus}
					/>
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || !slikStatus}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

SlikStatus.propTypes = propTypes;
export default StepContainer(SlikStatus);
