import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import TextareaCustom from 'components/CustomInputs/TextareaCustom';
import { CircularProgress } from '@material-ui/core';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import parse from 'html-react-parser';

const content = {
	en: {
		title: 'Why do you want to use our program?',
		reasonLabel: 'Type your text here',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Mengapa Anda ingin menggunakan program kami?',
		reasonLabel: 'Uraikan di sini',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const fieldKey = {
	rentown: 'reason',
	rentback: 'refinanceReason'
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Reason = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const {
		state: { form, fields, fieldsUpsert, service },
		upsertFields,
		resetFieldsUpsert
	} = Preapproval.useContainer();
	const { register, handleSubmit, formState, setValue, watch } = useForm({
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		const reason = findFieldByFieldKey(fields, fieldKey[service])?.fieldValue;
		setValue('reason', reason);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [{
			fieldKey: fieldKey[service],
			fieldValue: data.reason
		}]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const { errors } = formState;
	const reason = watch('reason');

	return (
		<div className="reason-preapproval">
			<div className="title">{parse(translation.title)}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextareaCustom
					error={errors.reason ? true : false}
					message={validationTranslation[errors.reason?.message]}
					name="reason"
					placeholder={translation.reasonLabel}
					ref={register}
					rows="3"
				>
				</TextareaCustom>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || !reason}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Reason.propTypes = propTypes;

export default StepContainer(Reason);
