import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AppBarMenuList from './AppBarMenuList';
import MobileMenu from 'components/MobileMenu';
import Navigation from 'components/Navigation';

import Logo from 'assets/images/Logo.svg';
import MenuListItems from 'constants/MenuListItems';
import LanguageSwitcher from 'components/LanguageSwitcher';
import Preference from 'containers/Preference';
import Auth from 'containers/Auth';

const propTypes = {
	menu: PropTypes.string.isRequired
};

const AppBar = props => {
	const { menu } = props;
	const location = useLocation();
	const history = useHistory();

	const { state: { auth } } = Auth.useContainer();

	const {
		state: { languageCode }
	} = Preference.useContainer();

	return (
		<div>
			<div className="app-bar base">
				<div className="base-container">
					<div className="app-bar-container">
						<div className="app-bar-left">
							<MobileMenu menu={menu} />
							<img alt="Logo" className="logo" onClick={() => history.push('/')} src={Logo} />
							<div className="menu">
								{MenuListItems[menu][languageCode].map((menuItem, index) => {
									return (
										<div className="menu-nav" key={index}>
											{menuItem.sub ? (
												<AppBarMenuList location={location} menuItem={menuItem} />
											) : (
												<Navigation name={menuItem.name} path={menuItem.path} />
											)}
										</div>
									);
								})}
							</div>
						</div>
						<div className="app-bar-right">
							<div className="switch">
								<LanguageSwitcher />
							</div>
							<div className="auth">
								{!auth.token ? (
									<div onClick={() => history.push('/login', { from: location.pathname })}>
										{languageCode === 'en' ? 'Login' : 'Masuk'}
									</div>
								): (
									<div onClick={() => history.push('/preapproval')}>
										{languageCode === 'en' ? 'My Application' : 'Ke Pengajuan'}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="offset"></div>
		</div>
	);
};

AppBar.defaultProps = {
	menu: 'home'
};

AppBar.propTypes = propTypes;

export default AppBar;
