import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string
};

const RadioButton = forwardRef((props, ref) => {
	const { id, name, label, value, ...otherProps } = props;
	return (
		<div className="radio-button">
			<input id={id} name={name} ref={ref} type="radio" value={value} {...otherProps}/>
			<label htmlFor={id}>{label}</label>
		</div>
	);
});

RadioButton.propTypes = propTypes;
RadioButton.displayName = 'RadioButton';
export default RadioButton;
