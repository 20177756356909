import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';

import Preference from 'containers/Preference';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import { CircularProgress } from '@material-ui/core';

const content = {
	en: {
		title: 'What is your sale timeline?',
		firstOptionLabel: 'As soon as possible',
		secondOptionLabel: '2 - 4 Weeks',
		thirdOptionLabel: '4 - 6 Weeks',
		fourthOptionlabel: '6+ Weeks',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Kapan Anda ingin penjualan dilakukan?',
		firstOptionLabel: 'Secepatnya',
		secondOptionLabel: '2 - 4 Minggu',
		thirdOptionLabel: '4 - 6 Minggu',
		fourthOptionlabel: '6+ Minggu',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Timeline = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue } = useForm({
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		const saleTimelineWeeks = findFieldByFieldKey(fields, 'saleTimelineWeeks')?.fieldValue;
		setValue('timeline', saleTimelineWeeks || 'As soon as possible');
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [{
			fieldKey: 'saleTimelineWeeks',
			fieldValue: data.timeline
		}]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="timeline-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton
						id="one"
						label={translation.firstOptionLabel}
						name="timeline"
						ref={register}
						type="radio"
						value="As soon as possible"
					/>
					<RadioButton
						id="two"
						label={translation.secondOptionLabel}
						name="timeline" ref={register}
						type="radio"
						value="2 - 4"
					/>
					<RadioButton
						id="three"
						label={translation.thirdOptionLabel}
						name="timeline"
						ref={register}
						type="radio"
						value="4 - 6"
					/>
					<RadioButton
						id="four"
						label={translation.fourthOptionlabel}
						name="timeline" ref={register}
						type="radio"
						value="6+"
					/>
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Timeline.propTypes = propTypes;
export default StepContainer(Timeline);
