import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioSwitch from 'components/CustomInputs/RadioSwitch';
import PriceField from 'components/CustomInputs/PriceField';
import Preapproval from 'containers/Preapproval';
import { CircularProgress } from '@material-ui/core';
import { findFieldByFieldKey } from 'utils';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';

const content = {
	en: {
		title: 'Have you listed your property in the last two years?',
		yesLabel: 'Yes',
		noLabel: 'No',
		priceTitle: 'At what price did you list the property for?',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Apakah properti pernah dipasarkan dalam kurun waktu dua tahun terakhir?',
		yesLabel: 'Ya',
		noLabel: 'Tidak',
		priceTitle: 'Berapa harga yang dipasang?',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Listing = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const listing = watch('listing');
	const answer = watch('answer');

	useEffect(() => {
		const listedProperty = findFieldByFieldKey(fields, 'listedProperty')?.fieldValue;
		const listedPropertyPrice = findFieldByFieldKey(fields, 'listedPropertyPrice')?.fieldValue;

		setValue('listing', listedProperty || 'no');
		register({ name: 'answer' });

		if (listedProperty === 'yes') {
			setValue('answer', listedPropertyPrice);
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'listedProperty',
				fieldValue: data.listing
			},
			{
				fieldKey: 'listedPropertyPrice',
				fieldValue: data.listing === 'yes' ? String(data.answer) : 'N/A'
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="listing-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<RadioSwitch name="listing"
					options={[{ value: 'no', label: translation.noLabel }, { value: 'yes', label: translation.yesLabel }]}
					ref={register}
				/>
				{listing === 'yes' ? (
					<div className="price-container">
						<div className="title">{translation.priceTitle}</div>
						<PriceField
							decimalSeparator={false}
							error={errors.answer ? true : false}
							message={validationTranslation[errors.answer?.message]}
							onValueChange={value => {
								setValue('answer', value.value);
								if (value.value) clearErrors('answer');
								else setValue('answer', undefined);
							}}
							thousandSeparator={'.'}
							value={answer}
						/>
					</div>
				) : ''}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || (listing === 'yes' && !answer)}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Listing.propTypes = propTypes;
export default StepContainer(Listing);
