import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import VerificationHeader from './VerificationHeader';
import VerificationInput from './VerificationInput';
import VerificationModal from './VerificationModal';
import Preference from 'containers/Preference';
import Auth from 'containers/Auth';

const Verification = props => {
	const [loading, setLoading] = useState(true);
	const [ready, setReady] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [otp, setOtp] = useState('');
	const history = useHistory();
	const {
		state: { languageCode }
	} = Preference.useContainer();

	const {
		state: { otpVerify, auth },
		verifyOtp,
		getOtp,
		resetOtpVerify
	} = Auth.useContainer();

	useEffect(async () => {
		const load = () => {
			if (!auth.verified && auth.verified !== null) {
				getOtp(auth.token);
			} else {
				history.push('/overview');
			}
			setLoading(false);
		};
		load();
	}, []);

	if (loading) return null;
	return (
		<Div100vh className="verification">
			<div className="verification-container">
				<VerificationHeader auth={auth} languageCode={languageCode} />
				<VerificationInput
					auth={auth}
					getOtp={getOtp}
					languageCode={languageCode}
					otp={otp}
					otpVerify={otpVerify}
					ready={ready}
					resetOtpVerify={resetOtpVerify}
					setIsOpen={setIsOpen}
					setOtp={setOtp}
					setReady={setReady}
					verifyOtp={verifyOtp}
				/>
				<VerificationModal isOpen={isOpen} languageCode={languageCode} />
			</div>
		</Div100vh>
	);
};

export default Verification;
