import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import TextareaCustom from 'components/CustomInputs/TextareaCustom';
import { CircularProgress } from '@material-ui/core';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'Reason?',
		unqualifiedBILabel: 'Unqualified BI Checking',
		inadequateDownPaymentLabel: 'Inadequate down-payment',
		contractWorkerLabel: 'Contract worker',
		otherLabel: 'Other',
		answerLabel: 'Type your text here',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Alasan pengajuan ditolak',
		unqualifiedBILabel: 'Terkendala BI Checking',
		inadequateDownPaymentLabel: 'Down-payment tidak cukup',
		contractWorkerLabel: 'Pekerja Kontrak',
		otherLabel: 'Lainnya',
		answerLabel: 'Uraikan di sini',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const ResultReason = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const [isFinished, setIsFinished] = useState(false);

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	useEffect(() => {
		const mortgageReason = findFieldByFieldKey(fields, 'mortgageReason')?.fieldValue;

		register({ name: 'answer' });
		setValue('answer', '');

		if (mortgageReason) {
			if (
				mortgageReason !== 'Unqualified BI Checking'
				&& mortgageReason !== 'Inadequate down-payment'
				&& mortgageReason !== 'Contract worker'
			) {
				setValue('reason', 'Other');
				setValue('answer', mortgageReason);
			} else {
				setValue('reason', mortgageReason);
			}
		} else {
			setValue('reason', 'Unqualified BI Checking');
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			setIsFinished(true);
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast('Something went wrong. Please try again later');
		}
	}, [fieldsUpsert]);

	useEffect(() => {
		if (isFinished) toNextPage();
	}, [isFinished]);

	const onSubmit = data => {
		let answer = '';
		if (data.reason !== 'Other') {
			answer = data.reason;
		} else {
			answer = data.answer;
		}
		upsertFields(form.id, [
			{
				fieldKey: 'mortgageReason',
				fieldValue: answer
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const reason = watch('reason');
	const answer = watch('answer');

	return (
		<div className="result-reason-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton
						id="one"
						label={translation.unqualifiedBILabel}
						name="reason"
						ref={register}
						type="radio"
						value="Unqualified BI Checking"
					/>
					<RadioButton
						id="two"
						label={translation.inadequateDownPaymentLabel}
						name="reason"
						ref={register}
						type="radio"
						value="Inadequate down-payment"
					/>
					<RadioButton
						id="three"
						label={translation.contractWorkerLabel}
						name="reason"
						ref={register}
						type="radio"
						value="Contract worker"
					/>
					<RadioButton
						id="four"
						label={translation.otherLabel}
						name="reason"
						ref={register}
						type="radio"
						value="Other"
					/>
				</div>
				{reason === 'Other' ? (
					<div className="input-container">
						<TextareaCustom
							error={errors.answer ? true : false}
							label={translation.answerLabel}
							message={validationTranslation[errors.answer?.message]}
							name="answer"
							onChange={e => {
								setValue('answer', e.target.value);
								if (e.target.value) clearErrors('answer');
							}}
							placeholder={translation.answerLabel}
							rows="3"
							value={answer}
						/>
					</div>
				) : (
					''
				)}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || (reason === 'Other' && !answer)}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

ResultReason.propTypes = propTypes;
export default StepContainer(ResultReason);
