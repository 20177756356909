import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import Preapproval from 'containers/Preapproval';
import Preference from 'containers/Preference';

const content = {
	en: {
		title: 'Is the property complete?',
		underConstructionLabel: 'No, it’s still under construction',
		completeLabel: 'Yes, it’s already complete',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Apakah propertinya sudah siap huni?',
		underConstructionLabel: 'Belum, properti masih dibangun',
		completeLabel: 'Sudah, properti sudah siap huni',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const PropertyComplete = props => {
	const { toNextPage, toPrevPage, validationSchema } = props;

	const [isFinished, setIsFinished] = useState(false);

	const { register, handleSubmit, setValue } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const { state: { isPropertyComplete }, setIsPropertyComplete } = Preapproval.useContainer();

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	useEffect(() => {
		if (isPropertyComplete) {
			setValue('is_property_complete', isPropertyComplete);
		} else {
			setValue('is_property_complete', 'underconstruction');
		}
	}, []);

	useEffect(() => {
		if (isFinished) {
			toNextPage();
		}
	}, [isFinished]);

	const onSubmit = data => {
		setIsPropertyComplete(data.is_property_complete);
		setIsFinished(true);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="property-complete-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container">
					<RadioButton id="one" label={translation.underConstructionLabel} name="is_property_complete" ref={register} type="radio" value="underconstruction" />
					<RadioButton id="two" label={translation.completeLabel} name="is_property_complete" ref={register} type="radio" value="complete" />
				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

PropertyComplete.propTypes = propTypes;
export default StepContainer(PropertyComplete);
