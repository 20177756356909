import React, { useEffect, lazy, Suspense } from 'react';
import Dashboard from 'layouts/Dashboard';
import Auth from 'containers/Auth';
import Preapproval from 'containers/Preapproval';

const OverviewHeader = lazy(() => import('pages/Overview/OverviewHeader'));
const OverviewProgress = lazy(() => import('pages/Overview/OverviewProgress'));
const OverviewTasks = lazy(() => import('pages/Overview/OverviewTasks'));
const OverviewProperty = lazy(() => import('pages/Overview/OverviewProperty'));
const OverviewHelp = lazy(() => import('pages/Overview/OverviewHelp'));
const FormStatusNotice = lazy(() => import('components/Alerts/FormStatusNotice'));
const EmailReminder = lazy(() => import('components/Alerts/EmailReminder'));

const Overview = props => {
	const {
		state: { auth }
	} = Auth.useContainer();
	const {
		state: { identifier, form, service },
		loadLatestFormData
	} = Preapproval.useContainer();
	const { verified } = auth;

	useEffect(() => {
		if (auth.loading || !auth.id) return;
		loadLatestFormData(auth.id);
	}, [auth]);

	if (!identifier) return null;
	return (
		<Dashboard className="overview">
			<Suspense fallback={<div></div>}>
				<div className="overview-container">
					<OverviewHeader />
					<OverviewProgress />
					{<FormStatusNotice />}
					{!verified && <EmailReminder />}
					{form.status === 'submitted' && <OverviewTasks />}
					<OverviewHelp />
					{service === 'rentback' && <OverviewProperty />}
				</div>
			</Suspense>
		</Dashboard>
	);
};

export default Overview;
