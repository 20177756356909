import {
	LANGUAGE_SET,
	IS_GOOGLE_MAP_SCRIPT_LOADED_SET
} from './types';

const setLanguage = (dispatch, languageCode) => {
	return dispatch({ type: LANGUAGE_SET, payload: languageCode });
};

const setIsGoogleMapScriptLoaded = (dispatch, toggle) => {
	return dispatch({ type: IS_GOOGLE_MAP_SCRIPT_LOADED_SET, payload: toggle });
};

export default {
	setLanguage,
	setIsGoogleMapScriptLoaded
};
