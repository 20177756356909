import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import RadioButton from 'components/CustomInputs/RadioButton';
import Preapproval from 'containers/Preapproval';
import TextareaCustom from 'components/CustomInputs/TextareaCustom';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';

const content = {
	en: {
		title: 'How did you know about us?',
		socialMediaLabel: 'Social Media',
		agentLabel: 'Agent',
		friendLabel: 'Friend',
		otherLabel: 'Other',
		answerFieldLabel: 'Type your text here',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Bagaimana Anda tahu tentang TapHomes?',
		socialMediaLabel: 'Media Sosial',
		agentLabel: 'Agen',
		friendLabel: 'Teman',
		otherLabel: 'Lainnya',
		answerFieldLabel: 'Uraikan di sini',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Referral = props => {
	const { toNextPage, toPrevPage, validationSchema } = props;
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const { state, setReferral } = Preapproval.useContainer();

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const referral = watch('referral');
	const answer = watch('answer');

	useEffect(() => {
		const { referral } = state;

		register({ name: 'answer' });

		setValue('answer', '');
		if (referral) {
			if (referral !== 'Social Media' && referral !== 'Agent' && referral !== 'Friend') {
				setValue('referral', 'Other');
				setValue('answer', referral);
			} else {
				setValue('referral', referral);
			}
		} else {
			setValue('referral', 'Social Media');
		}
	}, []);

	const onSubmit = data => {
		let answer = '';
		if (data.referral !== 'Other') {
			answer = data.referral;
		} else {
			answer = data.answer;
		}
		setReferral(answer);
		toNextPage();
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="referral-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="custom-radio-container col-2">
					<RadioButton id="social_media" label={translation.socialMediaLabel} name="referral" ref={register} type="radio" value="Social Media" />
					<RadioButton id="agent" label={translation.agentLabel} name="referral" ref={register} type="radio" value="Agent" />
					<RadioButton id="friend" label={translation.friendLabel} name="referral" ref={register} type="radio" value="Friend" />
					<RadioButton id="other" label={translation.otherLabel} name="referral" ref={register} type="radio" value="Other" />
				</div>
				{referral === 'Other' ? (
					<div className="input-container">
						<TextareaCustom
							error={errors.answer ? true: false}
							message={validationTranslation[errors.answer?.message]}
							onChange={e => {
								setValue('answer', e.target.value);
								if (e.target.value) clearErrors('answer');
							}}
							placeholder={translation.answerFieldLabel}
							rows={1}
							value={answer}
						/>
					</div>
				) : ''}
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

Referral.propTypes = propTypes;
export default StepContainer(Referral);
