import produce from 'immer';
import Cookie from 'js-cookie';
import { parseCookies } from 'utils';

import {
	LANGUAGE_SET,
	IS_GOOGLE_MAP_SCRIPT_LOADED_SET
} from './types';

export const initialPreferenceState = {
	languageCode: parseCookies().languageCode || 'id',
	isGoogleMapScriptLoaded: false
};

const preferenceReducers = (state, action) => {
	switch (action.type) {
	case LANGUAGE_SET:
		Cookie.set('languageCode', action.payload, { expires: 365 });
		return produce(state, draft => {
			draft.languageCode = action.payload;
		});
	case IS_GOOGLE_MAP_SCRIPT_LOADED_SET:
		return produce(state, draft => {
			draft.isGoogleMapScriptLoaded = action.payload;
		});
	default:
		return state;
	}
};

export default preferenceReducers;
