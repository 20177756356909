const home = {
	en: [
		{
			name: 'Our Services',
			sub: [
				{ name: 'Rent-to-Own', path: '/rent-to-own' },
				{ name: 'Rentback', path: '/rentback' }
			]
		},
		{ name: 'About Us', path: '/about' },
		{ name: 'Learn', path: '/learn/articles' },
		{ name: 'FAQ', path: '/faq' },
		{ name: 'Careers', path: '/careers' }
	],
	id: [
		{
			name: 'Layanan',
			sub: [
				{ name: 'Rent-to-Own', path: '/rent-to-own' },
				{ name: 'Rentback', path: '/rentback' }
			]
		},
		{ name: 'Tentang Kami', path: '/about' },
		{ name: 'Pelajari', path: '/learn/articles' },
		{ name: 'FAQ', path: '/faq' },
		{ name: 'Karir', path: '/careers' }
	]
};

const preapproval = {
	en: [],
	id: []
};

const dashboard = {
	en: [
		{ name: 'Overview', path: '/overview' },
		{ name: 'Tasks', path: '/tasks' },
		{ name: 'Application Summary', path: '/summary' }
	],
	id: [
		{ name: 'Tinjauan', path: '/overview' },
		{ name: 'Formulir', path: '/tasks' },
		{ name: 'Ringkasan Pengajuan', path: '/summary' }
	]
};

export default { home, preapproval, dashboard };
