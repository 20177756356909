import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from 'components/ScrollToTop';
import withAuth from 'components/Auth/withAuth';
import { Helmet } from 'react-helmet';

import Preapproval from 'pages/Preapproval';
import Verification from 'pages/Verification';
import PreapprovalSuccess from 'pages/Preapproval/PreapprovalSuccess';
import Overview from 'pages/Overview';
import Summary from 'pages/Summary';
import Tasks from 'pages/Tasks';
import NotFound from 'pages/NotFound';
import RegisterSuccess from 'pages/RegisterSuccess';

const Routes = () => (
	<Router>
		<Helmet>
			<title>TapHomes Menyediakan Berbagai Solusi Cerdas Untuk Kepemilikan Rumah</title>
			<meta
				content="TapHomes membantu kamu untuk memiliki rumah idaman dengan layanan rent to own. Mulai miliki rumah impian kamu dengan mudah, cepat, aman dan terpercaya"
				name="description"
			/>
			<meta
				content="Rent to Own, Rumah Idaman, Cicil Rumah, Jual Beli Rumah, KPR, Rumah Milenial, Kepemilikan Rumah, Teknologi Properti, Rumah DP Murah"
				name="keywords"
			/>
		</Helmet>
		<ScrollToTop />
		<Switch>
			<Route component={Preapproval} path="/preapproval" />
			<Route component={PreapprovalSuccess} path="/preapproval-success" />
			<Route component={withAuth(RegisterSuccess)} path="/register-success" />
			<Route component={withAuth(Overview)} path="/overview" />
			<Route component={withAuth(Summary)} path="/summary" />
			<Route component={withAuth(Tasks)} path="/tasks" />
			<Route component={withAuth(Verification)} path="/verification" />
			<Route component={NotFound} path="/" />
		</Switch>
	</Router>
);

export default Routes;
