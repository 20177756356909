import { useReducer } from 'react';
import { createContainer } from 'unstated-next';
import preferenceReducers, { initialPreferenceState } from './reducers';
import preferenceActions from './actions';

const usePreference = () => {
	const [state, dispatch] = useReducer(preferenceReducers, initialPreferenceState);

	const setLanguage = language => {
		preferenceActions.setLanguage(dispatch, language);
	};

	const setIsGoogleMapScriptLoaded = toggle => {
		preferenceActions.setIsGoogleMapScriptLoaded(dispatch, toggle);
	};

	return {
		state,
		setLanguage,
		setIsGoogleMapScriptLoaded
	};
};

export default createContainer(usePreference);
