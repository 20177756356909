import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import PriceField from 'components/CustomInputs/PriceField';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';
import { CircularProgress } from '@material-ui/core';

const content = {
	en: {
		title: 'At what price did you purchase this property for?',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Berapa harga beli properti kala itu?',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi Kesalahan. Silakan coba lagi nanti.'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Price = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state: { form, fields, fieldsUpsert }, upsertFields, resetFieldsUpsert } = Preapproval.useContainer();
	const { register, handleSubmit, setValue, watch, errors, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const price = watch('price');

	useEffect(() => {
		const purchasePropertyPrice = findFieldByFieldKey(fields, 'purchasePropertyPrice')?.fieldValue;

		register({ name: 'price' });

		if (purchasePropertyPrice) setValue('price', purchasePropertyPrice);
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			toNextPage();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'purchasePropertyPrice',
				fieldValue: `${data.price}`
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="price-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<PriceField
					decimalSeparator={false}
					error={errors.price ? true : false}
					message={validationTranslation[errors.price?.message]}
					onValueChange={value => {
						setValue('price', value.value);
						if (value.value) clearErrors('price');
						else setValue('price', undefined);
					}}
					thousandSeparator={'.'}
					value={price}
				/>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={fieldsUpsert.loading || !price}
						type="submit"
						variant="contained"
					>
						{fieldsUpsert.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Price.propTypes = propTypes;
export default StepContainer(Price);
