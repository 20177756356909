import { getRentbackTemplate, getRentbackStepsOrder } from 'pages/Preapproval/Templates/RentbackTemplate';
import { getRentownTemplate, getRentownStepsOrder } from 'pages/Preapproval/Templates/RentownTemplate';


export const getTemplate = state => {
	const template = {
		rentback: getRentbackTemplate,
		rentown: getRentownTemplate
	};

	return template[state.service]
		? template[state.service](state.fields)
		: template['rentown'](state.fields);
};

export const getStepsOrder = state => {
	const stepsOrder = {
		rentback: getRentbackStepsOrder,
		rentown: getRentownStepsOrder
	};

	return stepsOrder[state.service]
		? stepsOrder[state.service](state)
		: stepsOrder['rentown'](state);
};
