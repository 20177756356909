import React, { Suspense, useEffect, lazy } from 'react';
import Dashboard from 'layouts/Dashboard';
import { scroller } from 'react-scroll';
import { useHistory, useLocation } from 'react-router-dom';
import Preapproval from 'containers/Preapproval';
import Task from 'containers/Task';
import AuthContainer from 'containers/Auth';

const TasksHeader = lazy(() => import('pages/Tasks/TasksHeader'));
const TasksHelper = lazy(() => import('pages/Tasks/TasksHelper'));
const TasksApplicant = lazy(() => import('pages/Tasks/TasksApplicant'));
const TasksProperty = lazy(() => import('pages/Tasks/TasksProperty'));

const Tasks = props => {
	const {
		state: { auth }
	} = AuthContainer.useContainer();

	const {
		state: { form, identifier, formStatusUpdate, service },
		loadLatestFormData,
		updateFormStatus,
		resetFormStatusUpdate
	} = Preapproval.useContainer();

	const {
		state: { tasksSave, propertyImagesSave }
	} = Task.useContainer();

	const location = useLocation();
	const history = useHistory();
	const { goTo } = location.state || { goTo: null };

	useEffect(() => {
		if (goTo) {
			scrollTo('tasks-property');
		}
	}, []);

	useEffect(() => {
		if (!auth.id) return;
		loadLatestFormData(auth.id);
	}, [auth]);

	useEffect(() => {
		if (form.status === 'submitted' && (propertyImagesSave.success || tasksSave.success)) {
			updateFormStatus('completed');
		}
	}, [tasksSave, propertyImagesSave]);

	useEffect(() => {
		if (form.status === 'submitted') {
			updateFormStatus('completed');
		}
	}, [form]),

	useEffect(() => {
		if (formStatusUpdate.success) {
			resetFormStatusUpdate();
			history.push('/overview');
		} else if (formStatusUpdate.fail) {
			resetFormStatusUpdate();
		}
	}, [formStatusUpdate]);

	const scrollTo = key => {
		scroller.scrollTo(key, {
			offset: 0
		});
	};

	if (!identifier) return null;
	return (
		<Dashboard className="tasks">
			<Suspense fallback={<div></div>}>
				<div className="tasks-container">
					<TasksHeader />
					<TasksHelper />
					<TasksApplicant />
					{service === 'rentback' && <TasksProperty />}
				</div>
			</Suspense>
		</Dashboard>
	);
};


export default Tasks;
