import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import TextField from 'components/CustomInputs/TextField';

import Preference from 'containers/Preference';
import FormValidationContent from 'constants/FormValidationContent';
import Preapproval from 'containers/Preapproval';
import { findFieldByFieldKey } from 'utils';
import RadioButton from 'components/CustomInputs/RadioButton';
import SelectField from 'components/CustomInputs/SelectField';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const content = {
	en: {
		title: 'Where do you live now?',
		addressLabel: 'Address',
		propertyTypeFieldTitle: 'Type of Property',
		dormLabel: 'Dorm',
		houseLabel: 'House',
		apartmentLabel: 'Apartment',
		statusFieldTitle: 'What is the ownership status of the property?',
		statusLabel: 'Status',
		previous: 'Previous',
		next: 'Next',
		somethingWentWrong: 'Something went wrong. Please try again later.'
	},
	id: {
		title: 'Dimana tempat tinggal Anda sekarang?',
		addressLabel: 'Alamat',
		propertyTypeFieldTitle: 'Tipe Properti',
		dormLabel: 'Kos',
		houseLabel: 'Rumah',
		apartmentLabel: 'Apartemen',
		statusFieldTitle: 'Apa status kepemilikannya?',
		statusLabel: 'Status',
		previous: 'Kembali',
		next: 'Lanjut',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.'
	}
};

const options = {
	en: [
		{ value: 'Mine', label: 'Mine' },
		{ value: 'Rent', label: 'Rent' },
		{ value: `Parents'`, label: `Parents'` }
	],
	id: [
		{ value: 'Mine', label: 'Milik saya' },
		{ value: 'Rent', label: 'Sewa' },
		{ value: `Parents'`, label: 'Milik orang tua' }
	]
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const CurrentLiving = props => {
	const { showToast, toPrevPage, validationSchema } = props;

	const history = useHistory();

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { register, handleSubmit, setValue, formState, watch, clearErrors } = useForm({
		resolver: yupResolver(validationSchema)
	});

	const {
		state: { form, fieldsUpsert, fields, formSubmit },
		upsertFields,
		resetFieldsUpsert,
		submitForm,
		resetFormSubmit
	} = Preapproval.useContainer();

	const currentAddress = watch('current_address');
	const currentProperty = watch('current_property');
	const propertyStatus = watch('property_status');

	useEffect(() => {
		const currentAddress = findFieldByFieldKey(fields, 'currentAddress')?.fieldValue;
		const currentProperty = findFieldByFieldKey(fields, 'currentProperty')?.fieldValue || 'kos';
		const propertyStatus = findFieldByFieldKey(fields, 'propertyStatus')?.fieldValue;

		register({ name: 'property_status' });

		setValue('current_address', currentAddress);
		setValue('current_property', currentProperty);

		if (currentProperty !== 'kos') {
			setValue('property_status', propertyStatus);
		}
	}, []);

	useEffect(() => {
		if (fieldsUpsert.success) {
			resetFieldsUpsert();
			submitForm();
		} else if (fieldsUpsert.error) {
			resetFieldsUpsert();
			showToast(translation.somethingWentWrong);
		}
	}, [fieldsUpsert]);

	useEffect(() => {
		if (formSubmit.success) {
			resetFormSubmit();
			history.push('/preapproval-success', { from: 'preapproval' });
		} else if (formSubmit.error) {
			resetFormSubmit();
			showToast(translation.somethingWentWrong);
		}
	}, [formSubmit]);

	const onSubmit = data => {
		upsertFields(form.id, [
			{
				fieldKey: 'currentAddress',
				fieldValue: data.current_address
			},
			{
				fieldKey: 'currentProperty',
				fieldValue: data.current_property
			},
			{
				fieldKey: 'propertyStatus',
				fieldValue: data.current_property !== 'kos' ? data.property_status : 'N/A'
			}
		]);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const { errors } = formState;

	return (
		<div className="current-living-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="input-container">
					<TextField
						defaultValue={currentAddress}
						error={errors.current_address ? true : false}
						label={translation.addressLabel}
						message={validationTranslation[errors.current_address?.message]}
						name='current_address'
						ref={register}
						type="text"
					/>
				</div>
				<div className="field-title">{translation.propertyTypeFieldTitle}</div>
				<div className="custom-radio-container">
					<RadioButton id="dorm" label={translation.dormLabel} name="current_property" ref={register} type="radio" value="kos" />
					<RadioButton id="house" label={translation.houseLabel} name="current_property" ref={register} type="radio" value="rumah" />
					<RadioButton id="apartment" label={translation.apartmentLabel} name="current_property" ref={register} type="radio" value="apartemen" />
				</div>
				{currentProperty !== 'kos' && (
					<div className="status-field-container">
						<div className="title">{translation.statusFieldTitle}</div>
						<SelectField
							error={errors.property_status ? true : false}
							label={translation.statusLabel}
							message={validationTranslation[errors.property_status?.message]}
							onChange={val => {
								setValue('property_status', val);
								if (val) clearErrors('property_status');
							}}
							options={options[languageCode]}
							value={propertyStatus}
						/>
					</div>
				)}
				<div className="input-container">

				</div>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button
						disabled={fieldsUpsert.loading || formSubmit.loading}
						type="submit" variant="contained"
					>
						{fieldsUpsert.loading || formSubmit.loading ? <CircularProgress /> : translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

CurrentLiving.propTypes = propTypes;

export default StepContainer(CurrentLiving);
