import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm } from 'react-hook-form';

import Button from 'components/buttons/Button';
import RadioSwitch from 'components/CustomInputs/RadioSwitch';
import Preapproval from 'containers/Preapproval';

import Preference from 'containers/Preference';
import { useDidUpdateEffect } from 'hooks/useDidUpdateEffect';

const content = {
	en: {
		title: 'Do you have a car park?',
		yesLabel: 'Yes',
		noLabel: 'No',
		previous: 'Previous',
		next: 'Next'
	},
	id: {
		title: 'Apakah Anda memiliki tempat parkir?',
		yesLabel: 'Ya',
		noLabel: 'Tidak',
		previous: 'Kembali',
		next: 'Lanjut'
	}
};

const propTypes = {
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object
};

const Parking = props => {
	const { toNextPage, toPrevPage } = props;

	const [isUpdated, setIsUpdated] = useState(false);

	const { register, handleSubmit, setValue } = useForm();

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;

	const { state: { isParkingAvailable }, setIsParkingAvailable } = Preapproval.useContainer();

	useEffect(() => {
		if (isParkingAvailable) setValue('is_parking_available', isParkingAvailable);
		else setValue('is_parking_available', 'no');
	}, []);

	useDidUpdateEffect(() => {
		toNextPage();
	}, [isUpdated]);

	const onSubmit = data => {
		setIsParkingAvailable(data.is_parking_available);
		setIsUpdated(true);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	return (
		<div className="parking-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<RadioSwitch
					name="is_parking_available"
					options={[{ value: 'no', label: translation.noLabel }, { value: 'yes', label: translation.yesLabel }]}
					ref={register}
				/>
				<div className="buttons">
					<Button onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button type="submit" variant="contained">
						{translation.next}
					</Button>
				</div>
			</form>
		</div>
	);
};

Parking.propTypes = propTypes;
export default StepContainer(Parking);
