import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepContainer from './StepContainer';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/buttons/Button';
import TextField from 'components/CustomInputs/TextField';

import FormValidationContent from 'constants/FormValidationContent';
import Preference from 'containers/Preference';
import { CircularProgress } from '@material-ui/core';
import Preapproval from 'containers/Preapproval';
import AuthContainer from 'containers/Auth';

const content = {
	en: {
		title: 'Tell us about yourself',
		fullNameFieldLabel: 'Full Name',
		phoneFieldLabel: 'Phone Number',
		next: 'Next',
		previous: 'Previous',
		somethingWentWrong: 'Something went wrong. Please try again later',
		duplicateNumber: 'User already exists with that phone number',
		phoneExample: 'Example: 08123456789'
	},
	id: {
		title: 'Perkenalkan diri Anda',
		fullNameFieldLabel: 'Nama Lengkap',
		phoneFieldLabel: 'Nomor Telepon',
		next: 'Lanjut',
		previous: 'Kembali',
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.',
		duplicateNumber: 'Nomor telepon sudah terpakai',
		phoneExample: 'Contoh: 08123456789'
	}
};

const propTypes = {
	showToast: PropTypes.func,
	toNextPage: PropTypes.func,
	toPrevPage: PropTypes.func,
	validationSchema: PropTypes.object,
	width: PropTypes.number
};

const Contact = props => {
	const { showToast, toNextPage, toPrevPage, validationSchema } = props;

	const { state: { languageCode } } = Preference.useContainer();
	const translation = languageCode === 'en' ? content.en : content.id;
	const validationTranslation = languageCode === 'en' ? FormValidationContent.en : FormValidationContent.id;

	const { state, updateUserProfile, resetUserProfileUpdate } = Preapproval.useContainer();

	const { state: { auth } } = AuthContainer.useContainer();

	const { handleSubmit, formState, control, watch, reset } = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			fullname: '',
			phone: ''
		}
	});

	useEffect(() => {
		reset({
			fullname: state.user.name || '',
			phone: state.user.phone || ''
		});
	}, [state.latestFormDataLoad]);

	useEffect(() => {
		if (state.userProfileUpdate.success) {
			toNextPage();
			resetUserProfileUpdate();
		} else if (state.userProfileUpdate.error) {
			resetUserProfileUpdate();
			if (state.userProfileUpdate.error === 'DuplicatePhone') {
				showToast(translation.duplicateNumber);
			} else {
				showToast(translation.somethingWentWrong);
			}
		}
	}, [state.userProfileUpdate]);

	const onSubmit = data => {
		updateUserProfile(auth.id, data.fullname, data.phone);
	};

	const handlePrev = () => {
		toPrevPage();
	};

	const { errors } = formState;
	const fullnameWatch = watch('fullname');
	const phoneWatch = watch('phone');

	return (
		<div className="contact-preapproval">
			<div className="title">{translation.title}</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="fields-container">
					<div className="input-container">
						<Controller
							control={control}
							name="fullname"
							render={controllerProps => (
								<TextField
									error={errors.fullname ? true : false}
									label={translation.fullNameFieldLabel}
									message={validationTranslation[errors.fullname?.message]}
									onChange={controllerProps.onChange}
									value={controllerProps.value}
								/>
							)}
						/>
					</div>
					<div className="input-container">
						<Controller
							control={control}
							name="phone"
							render={controllerProps => (
								<TextField
									error={errors.phone ? true : false}
									label={translation.phoneFieldLabel}
									message={validationTranslation[errors.phone?.message] || translation.phoneExample}
									onChange={controllerProps.onChange}
									type="number"
									value={controllerProps.value}
								/>
							)}
						/>
					</div>
				</div>
				<div className="buttons">
					<Button disabled={true} onClick={handlePrev} type="button" variant="outlined">
						{translation.previous}
					</Button>
					<Button disabled={state.userProfileUpdate.loading || !fullnameWatch || !phoneWatch}
						type="submit"
						variant="contained"
					>
						{state.userProfileUpdate.loading? (
							<CircularProgress />
						) : (
							translation.next
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

Contact.propTypes = propTypes;

export default StepContainer(Contact);
